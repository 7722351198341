import { useState } from 'react';
import { AccountsService } from '@Services/accounts/accountsService';
import isEmployee from '@Utils/converters/isEmployee';
import { setIsUserEmployee } from '@Redux/modules/api/authentication/actions';
import { dispatch } from '@Redux/store';

interface IUseContactPhoneNumber {
	contactPhoneNumber?: string;
	getContactPhoneNumber?: (telesalesPhoneNumber: string) => void;
}

const useContactPhoneNumber = (): IUseContactPhoneNumber => {
	const [contactPhoneNumber, setContactPhoneNumber] = useState<string>();

	const getContactPhoneNumber = (telesalesPhoneNumber: string) => {
		AccountsService.getCustomerAccountOperation({
			doNotGetPinPukDetails: 'true',
			forceNumber: telesalesPhoneNumber,
		}).subscribe(
			(res) => {
				const contactData = res?.customerAccount?.customer?.relatedParty?.individual?.contactMedium;
				const customerAccountData = res?.customerAccount?.customer?.customerAccount;
				const productSpecification = res?.customerAccount?.product?.productSpecification;
				const isConsumer = Array.isArray(customerAccountData)
					? !!customerAccountData[0]?.customer?.characteristic?.find((item) => item.value === 'CONSUMER')
					: !!customerAccountData?.customer.characteristic?.find((item) => item.value === 'CONSUMER');
				const isAnEmployee = isEmployee(customerAccountData || {}) || false;
				dispatch(setIsUserEmployee(isAnEmployee));

				const isBaqatiNumber =
					productSpecification?.productCategory?.type === 'MOBILE' &&
					productSpecification?.productSpecCharacteristic.productSpecCharacteristicValue?.value ===
						'POSTPAID' &&
					(isConsumer || isAnEmployee);
				if (isBaqatiNumber) {
					setContactPhoneNumber(telesalesPhoneNumber);
				} else if (!!contactData) {
					const findedPhoneNumber = contactData?.find((item) => item.type === 'PHONE_NUMBER');
					setContactPhoneNumber(findedPhoneNumber?.medium?.number ?? telesalesPhoneNumber);
				} else {
					setContactPhoneNumber(telesalesPhoneNumber);
				}
			},
			() => {
				setContactPhoneNumber(telesalesPhoneNumber);
			}
		);
	};

	return { contactPhoneNumber, getContactPhoneNumber };
};

export default useContactPhoneNumber;
