import { PrimaryMediumButtonFlex, PrimaryLargeButtonFlex } from '@Components/controls/Button/Button';
import Icon from '@Components/Icon/Icon';
import { BodyMBold, BodySBold, Info, InfoBold } from '@Components/Typography/Typography.styled';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { dispatch } from '@Redux/store';
import { useCart } from '@Utils/context/CartContext';
import useTranslate from '@Utils/hooks/useTranslate';
import { getLang } from '@Utils/language/language';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Col } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
	ContinueToCheckoutCardContainer,
	HighlightedText,
	SecureTransactionText,
	SecureTransactionWrapper,
	PriceBlockContainer,
	PriceAccordion,
	PriceHeaderWrapper,
	SmallOMRWrapper,
	VatWrapper,
	VatAmountContainer,
	StrikethroughWrapper,
} from './ContinueToCheckout.styled';
import useScreen from '@Utils/hooks/useScreen';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { setPersistentCheckoutDetails } from '@Redux/modules/persistentCheckoutData/actions';
import { updateCart } from '@Redux/modules/cart/actions';
import { telesalesSaveInProgress } from '@Redux/modules/telesales/actions';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { MAKASIB_POINTS_PRODUCT_ID, MAKASIB_VOUCHER_PRODUCT_ID, POSTPAID_PLAN } from '@Config/app.config';
import { useDeviceInsurance } from '@Utils/context/DeviceInsuranceContext';
import TagManager from 'react-gtm-module';
import { roundMoney } from '@Utils/converters/roundMoney';
import { getUserId } from '@Redux/modules/api/authentication/selectors';

export const MOVE_ORDER_BI = 'MOVE_ORDER_BI';
export const TAG_MANAGER_CONTINUE_CHECKOUT = 'Continue checkout';
import useMicrosoftAutomationProcess from '@Utils/hooks/useMicrosoftAutomationProcess';
import { PriceTagWrapper } from '@Components/PriceTag/PriceTag.styled';
import Divider from '@Components/Divider/Divider';
import { ICartItem } from '@ApiModels/viewCart';
import { useBuyNowPayLater } from '@Utils/context/BuyNowPayLaterContext';
import { isGreenZeroPayment } from '@Config/features.config';
import { remoteConfigSelector } from '@Redux/modules/settings/remoteConfig/selectors';
import { bnplCal } from '@Utils/constants/dataLayer';

interface ITagManagerContinueToCheckout {
	Logged_in: 'Yes' | 'No';
	Count_of_Items_in_the_cart: number;
	eSim_selected: string;
	event: string;
	Total_amount: number;
	Protection_plan: 'Yes' | 'No';
	userId: string | undefined;
}

const ICON_SIZE = 24;
const GIFTCARD = 'GIFTCARD';

const ContinueToCheckout: FunctionComponent<types.cards.IContinueToCheckoutCardProps> = ({
	params: { disabled, discoundablePrice, isGreenRatingUser = false },
}) => {
	const isUserSignedIn = useSelector((state: types.redux.IState) => state.api?.authentication.signedIn);
	const { translate } = useTranslate();
	const { screen } = useScreen();
	const { isMicrosoftAutomationProcess } = useMicrosoftAutomationProcess();
	const makasibData = useSelector((state: types.redux.IState) => state.makasibPoints);
	const telemarketer = useSelector((state: types.redux.IState) => state.api.authentication.telemarketer);
	const [ebuItem, setEbuItem] = useState<ICartItem>();
	const telesalesInformation = useSelector(
		(state: types.redux.IState) => state.persistentCheckout.telesalesInformation
	);
	const { tierName, billingType } = useSelector((state: types.redux.IState) => state.api.personalData);
	const [valueOfDiscount, setValueOfDiscount] = useState<number>(0);
	const [totalWithVat, setTotalWithVat] = useState(0);
	const [totalWithoutVat, setTotalWithoutVat] = useState(0);

	const history = useHistory();
	const {
		cart,
		cartItemsDeviceInstallmentSum,
		cartItemsPlanPriceSum,
		vatPercentHighest,
		twoYearInstallmentValue,
		cartItemsVatValue,
		cartItemsDevicePlanVatSum,
		cartHbbWfbbWithoutOtherItem,
		cartItemsLength,
		isEbuCart,
	} = useCart();
	const { activeInsuranceInCart } = useDeviceInsurance();
	const isSm = screen(['sm', 'xs']);
	const isMd = screen('md');
	const { isBuyNowPayLaterInCart } = useBuyNowPayLater();
	const remoteFeatureConfig = useSelector(remoteConfigSelector);
	const DISABLE_GREEN_ZERO_PAYMENT = isGreenZeroPayment(remoteFeatureConfig);

	const makasibItem = useMemo(() => {
		return cart.items.find((item) => item.productId === MAKASIB_POINTS_PRODUCT_ID);
	}, [cart.items]);

	const periodPrice = cartItemsPlanPriceSum + cartItemsDeviceInstallmentSum;

	const isPrepaidOnlyProduct = cart.items.some((item) => item.PA === POSTPAID_PLAN) && cart.items.length === 1;

	const vouchersValues = useMemo(() => {
		let usedVouchersValueInOMR = 0;
		let totalVouchersValueInOMR = 0;
		cart.items
			.filter((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID)
			.forEach((item) => {
				usedVouchersValueInOMR = usedVouchersValueInOMR + Number(item.usedVoucherValueInOMR ?? 0);
				totalVouchersValueInOMR = totalVouchersValueInOMR + Number(item.totalVoucherValueInOMR ?? 0);
			});
		return { usedVouchersValueInOMR, totalVouchersValueInOMR };
	}, [cart.items]);

	const handleContinue = () => {
		const totalCartValue = cart.items?.reduce((sum, item) => {
			if (!!item?.BNPLFEP?.length) {
				return bnplCal(item);
			}
			const unitPrice = parseFloat(item?.taxablePrice || '0');
			const quantity = item?.quantity || 1;
			const priceToUse = unitPrice * quantity;
			return sum + priceToUse;
		}, 0);

		const payload = cart.items?.map((product, index) => ({
			item_variant: product?.productId,
			item_id: product?.productParentId,
			quantity: product?.quantity,
			item_name: product?.name,
			price: !!product?.BNPLFEP?.length ? roundMoney(bnplCal(product)) : roundMoney(product?.taxablePrice),
			upfront_price: !!product?.BNPLFEP?.length
				? null
				: product?.installmentDuration
				? roundMoney(Number(product?.price) - Number(product?.vatValue))
				: null,
			index,
			item_category: null,
			item_category2: null,
			is_preorder: Boolean(product?.plan?.preorderDate?.length),
			coupon: null,
			discount: null,
			payment_period: product?.plan?.installmentDuration?.length ? product?.plan?.installmentDuration : 0,
			price_plan_part1: roundMoney(product?.plan?.IV?.length ? product?.plan?.IV ?? 0 : 0),
			price_plan_part2: roundMoney(product?.plan?.BNPLFEP?.length ? product?.plan?.BNPLFEP ?? 0 : 0),
		}));

		const planName = cart?.items?.find((item) => item.plan && item.plan.planName);

		const tagManagerPayload = {
			event: 'begin_checkout',
			ecommerce: {
				currency: 'OMR',
				value: roundMoney(totalCartValue),
				items: payload,
				account_0_ConnectionType: billingType || cart?.items[0]?.mobilePhoneType,
				LoyaltyStatus: planName ? planName.plan?.planName : null,
				Logged_in: isUserSignedIn ? 'Yes' : 'No',
				userId: getUserId(),
			},
		};
		if (telemarketer) {
			if (
				telesalesInformation?.orderId &&
				telesalesInformation?.phoneNumber &&
				telesalesInformation?.email &&
				!!telesalesInformation?.contactNumber
			) {
				dispatch(telesalesSaveInProgress(true));
				dispatch(addBusyIndicator(MOVE_ORDER_BI));
				const { phoneNumber, contactNumber, email, orderId } = telesalesInformation;
				OrderFlowService.moveOrder({
					phoneNumber,
					contactNumber,
					email,
					orderId,
				}).subscribe(
					() => {
						TagManager.dataLayer({ dataLayer: { ecommerce: null } });
						TagManager.dataLayer({ dataLayer: tagManagerPayload });
						setTimeout(() => {
							dispatch(
								setPersistentCheckoutDetails({
									telesalesInformation: {
										orderId: undefined,
										email: '',
										phoneNumber: '',
										contactNumber: '',
									},
								})
							);
							dispatch(telesalesSaveInProgress(false));
							dispatch(removeBusyIndicator(MOVE_ORDER_BI));
							history.push(`/${getLang()}/store`);
							dispatch(updateCart(true));
						}, 2000);
					},
					(e) => {
						dispatch(
							setPersistentCheckoutDetails({
								telesalesInformation: {
									orderId: undefined,
									email: '',
									phoneNumber: '',
								},
							})
						);
						dispatch(telesalesSaveInProgress(false));
						dispatch(updateCart(true));
						dispatch(removeBusyIndicator(MOVE_ORDER_BI));
					}
				);
			}
		} else {
			if (isUserSignedIn || isPrepaidOnlyProduct) {
				if (isMicrosoftAutomationProcess) {
					history.push(`/${getLang()}/microsoft-automation-services`);
				} else {
					history.push(`/${getLang()}/checkout/1`);
				}
			} else {
				dispatch(
					setModal({
						data: {
							onContinue: () => {
								if (isMicrosoftAutomationProcess) {
									history.push(`/${getLang()}/microsoft-automation-services`);
								} else {
									history.push(`/${getLang()}/checkout/1`);
								}
							},
						},
						type: modalTypes.LOGIN,
					})
				);
			}
			TagManager.dataLayer({ dataLayer: { ecommerce: null } });
			TagManager.dataLayer({ dataLayer: tagManagerPayload });
		}
	};

	const handleMakasibModal = () => {
		const { totalVouchersValueInOMR } = vouchersValues;
		const excessDiscount =
			discoundablePrice - (makasibItem?.price ? -Number(makasibItem?.price) : 0) - totalVouchersValueInOMR;
		if (
			(makasibData.isTouched &&
				!cart.items.some((item) => item.productId === MAKASIB_POINTS_PRODUCT_ID) &&
				!cart.items.some((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID)) ||
			excessDiscount < 0
		) {
			dispatch(
				setModal({
					type: modalTypes.MAKASIB_WARNING,
					data: { excessDiscount, handleContinue },
					withoutContainer: true,
				})
			);
		} else {
			handleContinue();
		}
	};

	const undiscountableCartValue = useMemo(() => {
		const undiscoundableItems = cart.items.filter((item) => item.defType === GIFTCARD);
		if (undiscoundableItems.length > 0) {
			let value = 0;
			undiscoundableItems.forEach((item) => (value = +Number(item.taxablePrice ?? 0)));
			return value;
		} else return 0;
	}, [cart]);

	const calculateFirstBill = useMemo(() => {
		return cart.items.reduce((total, item) => {
			const upfrontCollection = item?.upfrontCollection as string;
			if (['I', 'F'].includes(upfrontCollection)) {
				total +=
					Number(item?.IV || 0) +
					Number(Number(item?.upfrontProductAmt || 0) / 1000) +
					Number(item?.plan?.planTaxablePrice || 0);
			} else {
				total += Number(item.price || 0);
			}

			return total;
		}, 0);
	}, [cart]);

	const calculateFirstBillVatAmount = useMemo(() => {
		return cart.items.reduce((total, item) => {
			const upfrontCollection = item?.upfrontCollection as string;
			const price = Number(item?.price || 0);
			const IV = Number(item?.IV || 0);
			const upfrontProductAmt = Number(item?.upfrontProductAmt || 0) / 1000;
			const vatValue = Number(item?.vatValue || 0);
			const planPrice = Number(item?.plan?.planTaxablePrice || 0);
			const planVatPrice = Number(item?.plan?.planVatValue || 0);

			if (['I', 'F'].includes(upfrontCollection)) {
				total += IV + upfrontProductAmt + vatValue + planPrice + planVatPrice;
			} else {
				total += price;
			}

			return total;
		}, 0);
	}, [cart]);

	const undiscountableTotalCartValue: number = useMemo(() => {
		const undiscoundableItems = cart.items.filter((item) => item.defType === GIFTCARD);
		if (undiscoundableItems.length > 0) {
			let value = 0;
			undiscoundableItems.forEach((item) => (value = +Number(item.unitPrice ?? 0)));
			return value;
		} else return 0;
	}, [cart]);

	useEffect(() => {
		const makasibPointsPrice = cart.items.find((item) => item.productId === MAKASIB_POINTS_PRODUCT_ID)?.price;
		const { usedVouchersValueInOMR } = vouchersValues;
		setValueOfDiscount(
			Number(cart.totalProductPrice) -
				(!!makasibPointsPrice ? Number(makasibPointsPrice) : 0) +
				usedVouchersValueInOMR
		);
		setEbuItem(cart?.items?.[0]);
	}, [cart, discoundablePrice]);

	useEffect(() => {
		let totalWithoutVat = 0;
		let totalWithVat = 0;
		cart.items.map((item) => {
			if (item.PA?.includes('BNPL')) {
				totalWithoutVat = totalWithoutVat + Number(item.BNPL_UPFRONT_MINUS_VAT || 0);
				totalWithVat = totalWithVat + Number(item.BNPL_UPFRONT_AMOUNT);
			} else {
				totalWithoutVat = totalWithoutVat + Number(item?.taxablePrice || 0);
				totalWithVat = totalWithVat + Number(item.price);
			}
		});
		setTotalWithVat(totalWithVat);
		setTotalWithoutVat(totalWithoutVat);
	}, [cart.items]);

	return (
		<ContinueToCheckoutCardContainer isSM={isSm} isMd={isMd}>
			<Col lg={12} sm={6} xs={12}>
				<ConditionalRender
					show={isBuyNowPayLaterInCart && !isEbuCart}
					onTrue={() => (
						<>
							<PriceBlockContainer isBottomMode={screen(['xs', 'sm', 'md'])}>
								<PriceAccordion>
									<PriceHeaderWrapper>
										<ConditionalRender
											show={screen(['xs', 'sm', 'md'])}
											onTrue={() => <BodySBold>{translate('payment.summary.upfront')}</BodySBold>}
											onFalse={() => (
												<BodyMBold>{translate('payment.summary.upfront')}</BodyMBold>
											)}
										/>
										<PriceTagWrapper>
											<SmallOMRWrapper>{translate('omr')}</SmallOMRWrapper>
											<BodyMBold>
												{isBuyNowPayLaterInCart
													? roundMoney(totalWithoutVat)
													: roundMoney(
															Number(cart.totalProductPrice) - cartItemsVatValue ?? 0
													  )}
											</BodyMBold>
										</PriceTagWrapper>
									</PriceHeaderWrapper>
									<VatWrapper>
										<Info color="black54">
											{translate(
												'product.cart-card.with-vat',
												isEbuCart ? ebuItem?.vatPercent || 0 : vatPercentHighest ?? 0
											)}
										</Info>
										<VatAmountContainer color="black54">
											{isBuyNowPayLaterInCart
												? `${translate('omr')} ${roundMoney(totalWithVat)}`
												: `${translate('omr')} ${roundMoney(cart.totalProductPrice)}`}
										</VatAmountContainer>
									</VatWrapper>
								</PriceAccordion>
							</PriceBlockContainer>

							<PriceBlockContainer isBottomMode={screen(['xs', 'sm', 'md'])}>
								<PriceAccordion>
									<PriceHeaderWrapper>
										<ConditionalRender
											show={screen(['xs', 'sm', 'md'])}
											onTrue={() => (
												<BodySBold>
													{isGreenRatingUser && DISABLE_GREEN_ZERO_PAYMENT
														? translate(
																'cart.checkout.remaining-months',
																twoYearInstallmentValue > 0 ? '23' : '11'
														  )
														: twoYearInstallmentValue > 0
														? translate('product.cart-card.23months')
														: translate('product.cart-card.11months')}
												</BodySBold>
											)}
											onFalse={() => (
												<BodyMBold>
													{isGreenRatingUser && DISABLE_GREEN_ZERO_PAYMENT
														? translate(
																'cart.checkout.remaining-mo',
																twoYearInstallmentValue > 0 ? '23' : '11'
														  )
														: twoYearInstallmentValue > 0
														? translate('product.cart-card.23months')
														: translate('product.cart-card.11months')}
												</BodyMBold>
											)}
										/>
										<PriceTagWrapper>
											<SmallOMRWrapper>{translate('omr')}</SmallOMRWrapper>
											<BodyMBold>{roundMoney(periodPrice - cartItemsDevicePlanVatSum)}</BodyMBold>
										</PriceTagWrapper>
									</PriceHeaderWrapper>
								</PriceAccordion>
							</PriceBlockContainer>
							<PriceBlockContainer isBottomMode={screen(['xs', 'sm', 'md'])}>
								<PriceAccordion>
									<PriceHeaderWrapper>
										<ConditionalRender
											show={screen(['xs', 'sm', 'md'])}
											onTrue={() => <BodySBold>{translate('product.cart-card.last')}</BodySBold>}
											onFalse={() => <BodyMBold>{translate('product.cart-card.last')}</BodyMBold>}
										/>
										<PriceTagWrapper>
											<SmallOMRWrapper>{translate('omr')}</SmallOMRWrapper>
											<BodyMBold>{roundMoney(cart.items[0].BNPLFEP)}</BodyMBold>
										</PriceTagWrapper>
									</PriceHeaderWrapper>
								</PriceAccordion>
							</PriceBlockContainer>
						</>
					)}
					onFalse={() => (
						<>
							<ConditionalRender
								show={!cartHbbWfbbWithoutOtherItem}
								onTrue={() => (
									<ConditionalRender
										show={!cartHbbWfbbWithoutOtherItem}
										onTrue={() => (
											<>
												<PriceBlockContainer isBottomMode={screen(['xs', 'sm', 'md'])}>
													<PriceAccordion>
														<PriceHeaderWrapper>
															<ConditionalRender
																show={screen(['xs', 'sm', 'md'])}
																onTrue={() => (
																	<BodySBold>
																		{isGreenRatingUser && DISABLE_GREEN_ZERO_PAYMENT
																			? translate('product.cart-card.for1month')
																			: isPrepaidOnlyProduct
																			? translate('payment.summary.price')
																			: translate('payment.summary.upfront')}
																	</BodySBold>
																)}
																onFalse={() => (
																	<BodyMBold>
																		{isGreenRatingUser && DISABLE_GREEN_ZERO_PAYMENT
																			? translate('product.cart-card.for1mo')
																			: isPrepaidOnlyProduct
																			? translate('payment.summary.price')
																			: translate('payment.summary.upfront')}
																	</BodyMBold>
																)}
															/>
															<PriceTagWrapper>
																<SmallOMRWrapper>{translate('omr')}</SmallOMRWrapper>
																<ConditionalRender
																	show={
																		isGreenRatingUser && DISABLE_GREEN_ZERO_PAYMENT
																	}
																	onTrue={() => (
																		<BodyMBold>
																			{roundMoney(calculateFirstBill)}{' '}
																		</BodyMBold>
																	)}
																	onFalse={() => (
																		<BodyMBold>
																			{roundMoney(
																				isEbuCart
																					? ebuItem?.taxablePrice
																					: Number(cart.totalProductPrice) -
																							cartItemsVatValue <
																					  0
																					? undiscountableCartValue
																					: (Number(cart.totalProductPrice) -
																							cartItemsVatValue ?? 0) <
																					  undiscountableCartValue
																					? undiscountableCartValue
																					: Number(cart.totalProductPrice) -
																							cartItemsVatValue ?? 0
																			)}
																		</BodyMBold>
																	)}
																/>
															</PriceTagWrapper>
														</PriceHeaderWrapper>
														<VatWrapper>
															<Info color="black54">
																{translate(
																	'product.cart-card.with-vat',
																	isEbuCart
																		? ebuItem?.vatPercent || 0
																		: vatPercentHighest ?? 0
																)}
															</Info>
															<ConditionalRender
																show={isGreenRatingUser && DISABLE_GREEN_ZERO_PAYMENT}
																onTrue={() => (
																	<VatAmountContainer>
																		{`${translate('omr')} ${roundMoney(
																			calculateFirstBillVatAmount
																		)}`}
																	</VatAmountContainer>
																)}
																onFalse={() => (
																	<VatAmountContainer
																		color="black54"
																		isMakasib={cart.items.some(
																			(item) =>
																				item.productId ===
																					MAKASIB_POINTS_PRODUCT_ID ||
																				item.productId ===
																					MAKASIB_VOUCHER_PRODUCT_ID
																		)}
																	>
																		{`${translate('omr')} ${roundMoney(
																			isEbuCart
																				? ebuItem?.price
																				: cart.items.some(
																						(item) =>
																							item.productId ===
																								MAKASIB_POINTS_PRODUCT_ID ||
																							item.productId ===
																								MAKASIB_VOUCHER_PRODUCT_ID
																				  )
																				? valueOfDiscount ?? 0
																				: cart.totalProductPrice
																		)}`}
																	</VatAmountContainer>
																)}
															/>
														</VatWrapper>
														<ConditionalRender
															show={cart.items.some(
																(item) =>
																	item.productId === MAKASIB_POINTS_PRODUCT_ID ||
																	item.productId === MAKASIB_VOUCHER_PRODUCT_ID
															)}
															onTrue={() => (
																<VatWrapper>
																	<Info color="primary">
																		{translate('product.cart-card.after-discount')}
																	</Info>
																	<VatAmountContainer color="primary">
																		{`${translate('omr')} ${roundMoney(
																			Number(cart.totalProductPrice) < 0
																				? undiscountableTotalCartValue
																				: Number(
																						Number(
																							cart.totalProductPrice
																						) ?? 0
																				  ) < undiscountableTotalCartValue
																				? undiscountableTotalCartValue
																				: Number(
																						Number(
																							cart.totalProductPrice
																						) ?? 0
																				  )
																		)}`}
																	</VatAmountContainer>
																</VatWrapper>
															)}
														/>
													</PriceAccordion>
												</PriceBlockContainer>
											</>
										)}
									/>
								)}
							/>
							<ConditionalRender
								show={periodPrice > 0}
								onTrue={() => (
									<PriceBlockContainer isBottomMode={screen(['xs', 'sm', 'md'])}>
										<PriceAccordion>
											<PriceHeaderWrapper>
												<ConditionalRender
													show={screen(['xs', 'sm', 'md'])}
													onTrue={() => (
														<BodySBold>
															{isEbuCart
																? translate('payment.summary.description.installments')
																: isGreenRatingUser && DISABLE_GREEN_ZERO_PAYMENT
																? translate(
																		'cart.checkout.remaining-months',
																		twoYearInstallmentValue > 0 ? '23' : '11'
																  )
																: twoYearInstallmentValue > 0
																? translate('product.cart-card.for24months')
																: translate('product.cart-card.for12months')}
														</BodySBold>
													)}
													onFalse={() => (
														<BodyMBold>
															{isEbuCart
																? translate('payment.summary.description.installments')
																: isGreenRatingUser && DISABLE_GREEN_ZERO_PAYMENT
																? translate(
																		'cart.checkout.remaining-mo',
																		twoYearInstallmentValue > 0 ? '23' : '11'
																  )
																: twoYearInstallmentValue > 0
																? translate('product.cart-card.24mo')
																: translate('product.cart-card.for12mo')}
														</BodyMBold>
													)}
												/>
												<PriceTagWrapper>
													<SmallOMRWrapper>{translate('omr')}</SmallOMRWrapper>
													<BodyMBold>
														<ConditionalRender
															show={isEbuCart}
															onTrue={() => (
																<>
																	<StrikethroughWrapper>
																		{roundMoney(
																			(Number(ebuItem?.standardPrice) || 0) /
																				(Number(ebuItem?.installmentDuration) ||
																					0)
																		)}
																	</StrikethroughWrapper>
																</>
															)}
															onFalse={() => (
																<>
																	{roundMoney(
																		periodPrice - cartItemsDevicePlanVatSum
																	)}
																</>
															)}
														/>
													</BodyMBold>
												</PriceTagWrapper>
											</PriceHeaderWrapper>
											<ConditionalRender
												show={!isEbuCart}
												onTrue={() => (
													<VatWrapper>
														<Info color="black54">
															{translate(
																'product.cart-card.with-vat',
																vatPercentHighest ?? 0
															)}
														</Info>
														<InfoBold color="black54">{`${translate('omr')} ${roundMoney(
															periodPrice
														)}`}</InfoBold>
													</VatWrapper>
												)}
												onFalse={() => (
													<VatWrapper>
														<Info color="primary">
															{translate('product.cart-card.after-discount')}
														</Info>
														<InfoBold color="primary">{`${translate('omr')} ${roundMoney(
															ebuItem?.IV
														)}`}</InfoBold>
													</VatWrapper>
												)}
											/>
										</PriceAccordion>
									</PriceBlockContainer>
								)}
							/>{' '}
						</>
					)}
				/>
				<Divider withoutLine marginBottom={8} />
			</Col>
			<Col lg={12} sm={6} xs={12} style={{ marginTop: isSm || isMd ? 0 : 16 }}>
				<ConditionalRender
					show={screen('xs')}
					onTrue={() => (
						<>
							<Divider withoutLine marginTop={8} />
							<PrimaryMediumButtonFlex
								uniqueId="continue.to.checkout"
								onClick={makasibData.isTouched ? handleMakasibModal : handleContinue}
								listener={['MOVE_ORDER_BI']}
								disabled={disabled}
								underlayLoadingColor={
									telemarketer && telesalesInformation?.orderId ? 'primaryLoading' : 'black12'
								}
							>
								{translate(
									telemarketer && telesalesInformation?.orderId
										? 'continue.save.and.send'
										: 'continue.to.checkout'
								)}
							</PrimaryMediumButtonFlex>
						</>
					)}
					onFalse={() => (
						<PrimaryLargeButtonFlex
							uniqueId="continue.to.checkout"
							onClick={makasibData.isTouched ? handleMakasibModal : handleContinue}
							listener={['MOVE_ORDER_BI']}
							disabled={disabled}
							underlayLoadingColor={
								telemarketer && telesalesInformation?.orderId ? 'primaryLoading' : 'black12'
							}
						>
							{translate(
								telemarketer && telesalesInformation?.orderId
									? 'continue.save.and.send'
									: 'continue.to.checkout'
							)}
						</PrimaryLargeButtonFlex>
					)}
				/>
				<ConditionalRender
					show={!screen('xs')}
					onTrue={() => (
						<SecureTransactionWrapper isSM={isSm} isSMMD={isSm || isMd}>
							<Icon name="lock" fill="black54" width={ICON_SIZE} height={ICON_SIZE} />
							<SecureTransactionText color="black54">
								{translate('product.cart-card.transaction.part-1')}
								<HighlightedText color="black54">
									{translate('product.cart-card.transaction.part-2')}
								</HighlightedText>
							</SecureTransactionText>
						</SecureTransactionWrapper>
					)}
				/>
			</Col>
		</ContinueToCheckoutCardContainer>
	);
};

export default ContinueToCheckout;
