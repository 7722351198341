import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import Card from '@Components/Card/Card';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PrimaryLargeButtonFlex } from '@Components/controls/Button/Button';
import Icon from '@Components/Icon/Icon';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { setPersistentCheckoutDetails } from '@Redux/modules/persistentCheckoutData/actions';
import { dispatch } from '@Redux/store';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { ItemType, ProductsService } from '@Services/productsService/productsService';
import { useCart } from '@Utils/context/CartContext';
import useTranslate from '@Utils/hooks/useTranslate';
import { getLang } from '@Utils/language/language';
import { BUY_HBB, BUY_WFBB } from '@Views/Map/Map';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Col, Container, Hidden, Row, useScreenClass, Visible } from 'react-grid-system';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { zip } from 'rxjs';
import {
	ButtonWrapper,
	CartViewTitle,
	EmptyStateDescription,
	EmptyStateTitle,
	FixedCheckoutCard,
	StickyWrapper,
	TitleWrapper,
	CardContainer,
} from './Cart.styled';
import { POSTPAID_PLAN } from '@Config/app.config';
import { IItem } from '@ApiModels/productsByCategoryId';
import { GET_RELATED_PRODUCTS_BI } from '@Views/ProductPage/ProductPage';
import { getOnlyAvailableItems } from '@Utils/converters/iItemInventoryAvailability';
import useAppleBrand from '@Utils/hooks/useAppleBrand';
import { IProductById } from '@ApiModels/productById';
import {
	CarouselLeftIconWrapper,
	CarouselRightIconWrapper,
	CarouselWrapper,
	EmptyCarouselObject,
} from '@Views/AppleBrandStore/AppleBrandStore.styled';
import ContentCarousel from '@Components/ContentCarousel/ContentCarousel';
import { TileSize } from '@Components/Card/cards/ProductCard/ProductCard.styled';
import useScreen from '@Utils/hooks/useScreen';
import TagManager from 'react-gtm-module';
import { DISABLE_MAKASIB_POINTS, DISABLE_MAKASIB_VOUCHER, ENABLE_ESHOP_LITE } from '@Config/features.config';
import { getUserId } from '@Redux/modules/api/authentication/selectors';
import { useBuyNowPayLater } from '@Utils/context/BuyNowPayLaterContext';
import { roundMoney } from '@Utils/converters/roundMoney';
import { bnplCal } from '@Utils/constants/dataLayer';

interface IProductDetails {
	productName?: string;
	productId?: string;
}

interface IAllProductsDetails {
	appleProductDetails: IProductDetails[];
	otherProductDetails: IProductDetails[];
}

export const VIEW_CART_ITEMS = 'viewCartItems';
const B2B = 'B2B';
const CHEVRON_ICON_SIZE = 24;
const CONTINUE_TO_CHECKOUT = 'continueToCheckout';
const MAKASIB_PAYMENT_VOUCHER = 'MAKASIB_PAYMENT_VOUCHER';

const CartView: FunctionComponent = () => {
	const [disableCheckoutButton, setDisableCheckoutButton] = useState(false);
	const { translate } = useTranslate();
	const screenClass = useScreenClass();
	const history = useHistory();
	const { cart, loading, isEbuCart } = useCart();
	const hbbWfbbProcessCheckout = useSelector(
		(state: types.redux.IState) => state.persistentCheckout.hbbWfbbProcessCheckout
	);
	const [relatedProducts, setRelatedProducts] = useState<IItem[]>([]);
	const [productDetails, setProductDetails] = useState<IAllProductsDetails>({
		appleProductDetails: [],
		otherProductDetails: [],
	});
	const [hasGiftCards, setHasGiftCards] = useState<boolean>(false);
	const [discoundablePrice, setDiscoundablePrice] = useState<number>(0);
	const [hasDigitalProduct, setHasDigitalProduct] = useState<boolean>(false);
	const products = cart.items.map((item) => item.productId);
	const [upselModalDisplayed, setUpsellModalDisplayed] = useState(false);
	const { screen } = useScreen();
	const otherDeviceIncart = cart.items.length > 1;
	const { WCTrustedToken } = useSelector((state: types.redux.IState) => state.api.headers);
	const [oldWCTrustedToken, setOldWCTrustedToken] = useState<string>('');
	const [nonLoggedVoucherAccordion, setNonLoggedVoucherAccordion] = useState<boolean>(false);
	const [nonLoggedMakasibAccordion, setNonLoggedMakasibAccordion] = useState<boolean>(false);
	const [isGreenRatingUser, setIsGreenRatingUser] = useState<boolean>(false);

	const getIconSize = () => (screenClass.includes('xs') ? 185 : 286);
	const { isBuyNowPayLaterInCart } = useBuyNowPayLater();
	const isLogged = !!useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const { tierName, billingType } = useSelector((state: types.redux.IState) => state.api.personalData);

	const isBuyHbbWfbbProcess = useMemo(() => {
		return !!cart.items.find((item) => [BUY_HBB, BUY_WFBB].includes(item.plan?.PA || ''));
	}, [cart]);

	const isBuyPrepaidProcess = useMemo(() => {
		return !!cart.items.find((item) => [POSTPAID_PLAN].includes(item.plan?.PA || ''));
	}, [cart]);

	const isEBUAnyPlanAction = useMemo(() => {
		return !!cart.items.some((item) => item.plan?.PA?.includes(B2B));
	}, [cart]);

	const getStyle = () => ({
		paddingLeft: screenClass.includes('xs') ? 0 : 15,
		paddingRight: screenClass.includes('xs') ? 0 : 15,
	});

	useEffect(() => {
		setIsGreenRatingUser(cart.isGreenXP);
	}, [cart.isGreenXP]);

	useEffect(() => {
		if (discoundablePrice > 0) {
			setDiscoundablePrice(0);
		}
		setHasGiftCards(false);
		setHasDigitalProduct(false);
		const products: IItem[] = [];
		let discountPrice: number = 0;
		if (cart.items.length) {
			cart.items
				.filter((item) => item.partNumber !== MAKASIB_PAYMENT_VOUCHER)
				.map((cartItem, index) => {
					ProductsService.getProductById({ productId: cartItem.productId }).subscribe(
						({ item }) => {
							products.push(item);
							if (item?.isGiftCard) {
								setHasGiftCards(true);
							}
							if (item?.isDigitalProduct) {
								setHasDigitalProduct(true);
							}

							if (!(item?.isDigitalProduct || item?.isGiftCard)) {
								discountPrice = discountPrice + item.price;
							}
							if (
								cart.items.filter((item) => item.partNumber !== MAKASIB_PAYMENT_VOUCHER)?.length ===
								index + 1
							) {
								setDiscoundablePrice(discountPrice);
							}
						},
						() => {}
					);
				});
		}
	}, [cart.items]);

	const { getAppleItemCategory } = useAppleBrand();

	const carouselRWDCapacities = () => {
		if (screen('md')) {
			return 3;
		} else if (screen(['sm', 'xs'])) {
			return 2;
		} else {
			return 4;
		}
	};

	const emptyElementsNeeded = (length: number) => {
		if (length < carouselRWDCapacities()) {
			if (carouselRWDCapacities() - length === 3) {
				return [1, 2, 3];
			} else if (carouselRWDCapacities() - length === 2) {
				return [1, 2];
			} else if (carouselRWDCapacities() - length === 1) {
				return [1];
			} else {
				return [];
			}
		} else {
			return [];
		}
	};

	useEffect(() => {
		document.body.style.overflowX = 'unset';
		return () => {
			document.body.style.overflowX = 'hidden';
		};
	}, []);

	useEffect(() => {
		if (!hbbWfbbProcessCheckout) {
			const item = cart.items.find((item) => [BUY_HBB, BUY_WFBB].includes(item.plan?.PA || ''));
			if (item?.orderItemId) {
				OrderFlowService.removeSingleCartItem(item?.orderItemId).subscribe(
					() => {
						dispatch(setPersistentCheckoutDetails({ hbbWfbbProcessCheckout: undefined }));
					},
					() => {}
				);
			}
		}
	}, [hbbWfbbProcessCheckout]);

	useEffect(() => {
		const payload = cart.items?.map((product, index) => ({
			item_variant: product.productId,
			item_id: product.productParentId,
			quantity: product.quantity,
			item_name: product.name,
			price: !!product?.BNPLFEP?.length ? roundMoney(bnplCal(product)) : roundMoney(product?.taxablePrice),
			upfront_price: !!product?.BNPLFEP?.length
				? null
				: product?.installmentDuration
				? roundMoney(Number(product?.price) - Number(product?.vatValue))
				: null,
			is_preorder: Boolean(product?.plan?.preorderDate?.length),
			index,
			item_category: null,
			item_category2: null,
			coupon: null,
			discount: null,
			payment_period: product?.plan?.installmentDuration?.length ? product?.plan?.installmentDuration : 0,
			price_plan_part1: roundMoney(product?.plan?.IV?.length ? product?.plan?.IV ?? 0 : 0),
			price_plan_part2: roundMoney(product?.plan?.BNPLFEP?.length ? product?.plan?.BNPLFEP ?? 0 : 0),
		}));
		if (cart.items.length) {
			TagManager.dataLayer({ dataLayer: { ecommerce: null } });

			const totalCartValue = cart.items?.reduce((sum, item) => {
				if (!!item?.BNPLFEP?.length) {
					return bnplCal(item);
				}
				const unitPrice = parseFloat(item?.taxablePrice || '0');
				const quantity = item?.quantity || 1;
				const priceToUse = unitPrice * quantity;
				return sum + priceToUse;
			}, 0);

			const planName = cart?.items?.find((item) => item.plan && item.plan.planName);

			TagManager.dataLayer({
				dataLayer: {
					event: 'view_cart',

					ecommerce: {
						currency: 'OMR',
						Logged_in: isLogged ? 'Yes' : 'No',
						account_0_ConnectionType: billingType || cart?.items[0]?.mobilePhoneType,
						LoyaltyStatus: planName ? planName.plan?.planName : null,
						value: roundMoney(totalCartValue),
						items: payload,
						userId: getUserId(),
					},
				},
			});
		}
	}, [cart?.items?.length, billingType]);

	const cartItems = useMemo(() => {
		return cart.items;
	}, [cart]);

	useEffect(() => {
		if (products) {
			dispatch(addBusyIndicator(CONTINUE_TO_CHECKOUT));
			const upsellProducts = products.map((productId) => ProductsService.getRelatedProductsById({ productId }));
			zip(...upsellProducts).subscribe(
				(res) => {
					const upsellItems = res
						.map((_products) => _products.relatedProducts)
						.flat()
						.filter((item) => item.isUpsell);
					if (upsellItems.length > 0) {
						if (!upselModalDisplayed) {
							dispatch(
								setModal({
									data: { products, upsellProducts: upsellItems },
									type: modalTypes.UPSELL,
									withoutContainer: true,
								})
							);
							setUpsellModalDisplayed(true);
						}
					}
					dispatch(removeBusyIndicator(CONTINUE_TO_CHECKOUT));
				},
				() => {
					setDisableCheckoutButton(false);
					dispatch(removeBusyIndicator(CONTINUE_TO_CHECKOUT));
				}
			);
		}
	}, [products]);

	useEffect(() => {
		if (cartItems.length > 0) {
			dispatch(addBusyIndicator(GET_RELATED_PRODUCTS_BI));
			setProductDetails({ appleProductDetails: [], otherProductDetails: [] });
			setRelatedProducts([]);
			const appleProductDetails: IProductDetails[] = [];
			const otherProductDetails: IProductDetails[] = [];
			let cartItemsIterator = 0;
			cartItems.forEach((item, index) => {
				ProductsService.getProductByIdSpecifiedSkus({
					productId: item.productParentId || '',
					itemType: ItemType.UNKNOWN,
					cacheOff: true,
				}).subscribe(
					(productById) => {
						const appleItemCategory = getAppleItemCategory(productById || ({} as IProductById));
						if (appleItemCategory.name) {
							if (
								productById?.item?.parentCatalogGroupID?.indexOf(appleItemCategory.id) !== -1 ||
								productById?.item?.parentCatalogGroupID?.[0].includes(appleItemCategory.id)
							) {
								appleProductDetails.push({
									productName: productById?.item?.manufacturer || '',
									productId: item.productParentId || '',
								});
							}
						} else {
							otherProductDetails.push({
								productName: productById?.item?.manufacturer || '',
								productId: item.productParentId || '',
							});
						}
						if (cartItemsIterator === cartItems.length - 1)
							setProductDetails({
								appleProductDetails,
								otherProductDetails,
							});
						cartItemsIterator++;
					},
					() => {
						dispatch(removeBusyIndicator(GET_RELATED_PRODUCTS_BI));
					}
				);
			});
		}
	}, [cartItems]);

	useEffect(() => {
		if (
			(!!productDetails.otherProductDetails[0]?.productId ||
				!!productDetails.appleProductDetails[0]?.productId) &&
			!ENABLE_ESHOP_LITE
		) {
			ProductsService.getRelatedProductsByIdWithInventory({
				productId: !!productDetails.appleProductDetails[0]?.productId
					? productDetails.appleProductDetails[0]?.productId
					: productDetails.otherProductDetails[0]?.productId || '',
				itemType: ItemType.UNKNOWN,
			}).subscribe(
				(r) => {
					setRelatedProducts(getOnlyAvailableItems(r.relatedProductsWithInventory, true));
					dispatch(removeBusyIndicator(GET_RELATED_PRODUCTS_BI));
				},
				() => {
					dispatch(removeBusyIndicator(GET_RELATED_PRODUCTS_BI));
				}
			);
		} else {
			dispatch(removeBusyIndicator(GET_RELATED_PRODUCTS_BI));
		}
	}, [productDetails]);

	return (
		<>
			<Container>
				<Row>
					<Col>
						<CartViewTitle>{translate('cart-view.title')}</CartViewTitle>
					</Col>
				</Row>
				<BusyIndicator listener={VIEW_CART_ITEMS} skeleton="cart">
					<ConditionalRender
						show={!!cart.items.length}
						onTrue={() => (
							<Row>
								<Col xl={9} lg={8} style={getStyle()}>
									<Card<types.cards.ICartItemsCardProps>
										card="cartItems"
										params={{
											cartItems,
											tableMode: true,
											addToCart: false,
											disableCheckoutButton: (value) => {
												setDisableCheckoutButton(value);
											},
											withoutQuantityButtons: isBuyHbbWfbbProcess || isBuyPrepaidProcess,
											showPlanDetailsAndRemoveButton: isBuyHbbWfbbProcess || isBuyPrepaidProcess,
										}}
										marginBottom={16}
									/>
									<BusyIndicator listener={[GET_RELATED_PRODUCTS_BI]} skeleton="upSellCart">
										<ConditionalRender
											show={
												(!!productDetails.appleProductDetails.length ||
													!!productDetails.otherProductDetails.length) &&
												!!relatedProducts.length &&
												!isBuyNowPayLaterInCart
											}
											onTrue={() => (
												<CardContainer>
													<TitleWrapper>{translate('apple.upSell.title')}</TitleWrapper>

													<ContentCarousel
														responsive={{
															0: { items: 1 },
															576: { items: 2 },
															768: { items: 2 },
															992: { items: 2 },
															1200: { items: 3 },
														}}
														items={[
															...relatedProducts.map((product, index) => (
																<CarouselWrapper key={`appleLobMenuItem_ ${index}`}>
																	<Card<types.cards.IProductCardProps>
																		card="product"
																		params={{
																			product,
																			tileSize: TileSize.REGULAR,
																			iconsHidden: true,
																			layoutChange: false,
																			isRelatedProduct: true,
																			inventory: product?.inventory,
																		}}
																		marginTop={8}
																		marginBottom={8}
																	/>
																</CarouselWrapper>
															)),
															...emptyElementsNeeded(relatedProducts.length).map(
																(item) => {
																	<EmptyCarouselObject
																		key={item}
																	></EmptyCarouselObject>;
																}
															),
														]}
														paddingRight={50}
														disableButtons={
															emptyElementsNeeded(relatedProducts.length).length !== 0 ||
															screen(['xs', 'sm'])
														}
														nextButton={
															<CarouselRightIconWrapper>
																<Icon
																	name="chevronRight"
																	width={CHEVRON_ICON_SIZE}
																	height={CHEVRON_ICON_SIZE}
																	fill="primary"
																/>
															</CarouselRightIconWrapper>
														}
														prevButton={
															<CarouselLeftIconWrapper>
																<Icon
																	name="chevronLeft"
																	width={CHEVRON_ICON_SIZE}
																	height={CHEVRON_ICON_SIZE}
																	fill="primary"
																/>
															</CarouselLeftIconWrapper>
														}
													/>
												</CardContainer>
											)}
										/>
									</BusyIndicator>
									<ConditionalRender
										show={
											!DISABLE_MAKASIB_VOUCHER &&
											!isEbuCart &&
											!isEBUAnyPlanAction &&
											!isBuyNowPayLaterInCart
										}
										onTrue={() => (
											<Card<types.cards.ICartMakasibCardProps>
												card="eVoucherCard"
												marginBottom={16}
												params={{
													hasGiftCards,
													discoundablePrice,
													hasDigitalProduct,
													setNonLoggedMakasibAccordion,
													setNonLoggedVoucherAccordion,
													nonLoggedVoucherAccordion,
												}}
											/>
										)}
									/>
									<ConditionalRender
										show={
											!DISABLE_MAKASIB_POINTS &&
											!isEbuCart &&
											!isEBUAnyPlanAction &&
											!isBuyNowPayLaterInCart
										}
										onTrue={() => (
											<Card<types.cards.ICartMakasibCardProps>
												card="payByMakasibCard"
												marginBottom={16}
												params={{
													hasGiftCards,
													discoundablePrice,
													hasDigitalProduct,
													setNonLoggedMakasibAccordion,
													setNonLoggedVoucherAccordion,
													nonLoggedMakasibAccordion,
												}}
											/>
										)}
									/>
									<Card<types.cards.ICartCheckoutInfoCardProps>
										card="checkoutInfo"
										marginBottom={16}
										params={{
											isBuyHbbWfbbProcessWithOtherDevice: Boolean(
												isBuyHbbWfbbProcess && otherDeviceIncart
											),
											isBuyHbbWfbbProcess,
											isPrepaid: isBuyPrepaidProcess,
										}}
									/>
									<Card<types.cards.ICartPaymentSummaryCardProps>
										card="cartPaymentSummary"
										params={{
											items: cart.items,
											isGreenRatingUser,
										}}
										marginBottom={16}
									/>
								</Col>
								<Col xl={3} lg={4} style={getStyle()}>
									<StickyWrapper>
										<Visible xl lg>
											<BusyIndicator
												listener={CONTINUE_TO_CHECKOUT}
												skeleton="continueToCheckout"
											>
												<Card<types.cards.IContinueToCheckoutCardProps>
													card="continueToCheckout"
													marginBottom={16}
													withoutContainerPadding
													params={{
														disabled: disableCheckoutButton,
														discoundablePrice,
														isGreenRatingUser,
													}}
												/>
											</BusyIndicator>
										</Visible>
									</StickyWrapper>
								</Col>
							</Row>
						)}
						onFalse={() => (
							<Row>
								<Col>
									<Icon name="cartEmptyState" width={getIconSize()} height={getIconSize()} />
									<EmptyStateTitle>{translate('navbar.cart.empty-state.title')}</EmptyStateTitle>
									<EmptyStateDescription>
										{translate('navbar.cart.empty-state.description')}
									</EmptyStateDescription>
									<Row justify="center">
										<ButtonWrapper>
											<PrimaryLargeButtonFlex
												uniqueId="navbar.cart.go-to-store"
												onClick={() => history.push(`/${getLang()}/store`)}
											>
												{translate('navbar.cart.go-to-store')}
											</PrimaryLargeButtonFlex>
										</ButtonWrapper>
									</Row>
								</Col>
							</Row>
						)}
					/>
				</BusyIndicator>
			</Container>
			<ConditionalRender
				show={!!cart.items.length}
				onTrue={() => (
					<Hidden xl lg>
						<FixedCheckoutCard>
							<Card<types.cards.IContinueToCheckoutCardProps>
								card="continueToCheckout"
								withoutContainer
								params={{ disabled: disableCheckoutButton, discoundablePrice, isGreenRatingUser }}
							/>
						</FixedCheckoutCard>
					</Hidden>
				)}
			/>
		</>
	);
};

export default CartView;
