import { IUpdateProfilePayloadInput } from '@Services/profile/commerceProfileService';
import {
	IValidatePlan,
	IValidateEbuOffer,
	IAddItemsToCartPayload,
	ICreateNotificationRequest,
	IHbbOrderPayload,
	IContactPayload,
	IValidateTelesalesDocumentIdPayload,
	IValidateTelesalesSmsPasswordPayload,
	IMoveOrderPayload,
	IWfbbOrderPayload,
	IPrepaidOrderPayload,
	IMakePaymentRefundPayload,
	IRefundPaymentLogPayload,
} from '@Services/orderFlow/orderFlow';
import { getLanguageCode } from '@Utils/language/language';
import { IMakasibOtpPayload } from '@ApiModels/validateMakasibOtp';
import { IInstallmentAmountPayload, IBuyNowPayLaterInstallmentPayload } from '@Services/inventory/inventoryService';
import { IBuNowPayLeterSessionPayload } from '@Services/authentication/authenticationService';

export interface IManageOrderItemESim {
	orderItemId: string;
	esimAction?: 'NEW' | 'TRANSFER' | 'DELETE';
	esimNo: string;
}

export function getProducts(orderItem: IAddItemsToCartPayload[]): Record<string, any> {
	if (orderItem.some((item) => !!item.xitem_makasibPointsQuantity || !!item.xitem_totalVoucherValueInOMR)) {
		return {
			orderId: '.',
			x_calculateOrder: '1',
			orderItem,
		};
	} else {
		if (orderItem && orderItem.length > 0) {
			const { DISABLE_WIFI_FREE_DEVICE = true } = orderItem[0];
			if (!DISABLE_WIFI_FREE_DEVICE) {
				const { DISABLE_WIFI_FREE_DEVICE, ...obj } = orderItem[0];
				const { mainDevice, freeDevice } = Object.entries(obj).reduce(
					(acc, [key, value]) => {
						if (key.startsWith('Free_Product_')) {
							const newKey = key.slice('Free_Product_'.length);
							acc.freeDevice[newKey] = value;
						} else {
							acc.mainDevice[key] = value;
						}
						return acc;
					},
					{ mainDevice: {} as any, freeDevice: {} as any }
				);

				orderItem.length = 0;
				orderItem.push({ ...mainDevice });
				if (Object.keys(freeDevice).length > 0) {
					orderItem.push(freeDevice);
				}
			}
		}
		return {
			orderId: '.',
			x_calculateOrder: '1',
			orderItem,
			x_inventoryValidation: true,
		};
	}
}

export function removeSingleCartItem(orderItemId: string): Record<string, any> {
	return {
		orderItemId,
		check: '0',
	};
}

export function getInstallmentAmount({
	mobilePhone,
	accountNo,
	creditRating,
	emfConfigId,
	productId,
	serviceCode,
	voucherAmount,
	installmentDuration,
	isDisabledBnplCr7985,
	coRelationId,
	vatProductId = '',
	upfrontProductId = '',
}: IInstallmentAmountPayload): Record<string, any> {
	const payload: any = {
		mobilePhone,
		accountNo,
		emfConfigId,
		productId,
		serviceCode,
		voucherAmount,
		installmentDuration,
		coRelationId,
	};

	if (!isDisabledBnplCr7985) {
		payload.creditRating = creditRating;
	}

	vatProductId?.length && (payload.vatProductId = vatProductId);
	upfrontProductId?.length && (payload.upfrontProductId = upfrontProductId);

	return payload;
}

export function getBuyNowPayLaterInstallmentAmount({
	productId,
	installmentDuration,
	bnplProductPrice,
	mobilePhone,
	creditRating,
	isDisabledBnplCr7985,
	accountNo,
	emfConfigId,
	serviceCode,
	coRelationId,
}: IBuyNowPayLaterInstallmentPayload): Record<string, any> {
	const result: any = {
		productId,
		installmentDuration,
		coRelationId,
	};

	if (!isDisabledBnplCr7985) {
		result.bnplProductPrice = bnplProductPrice;
		result.mobilePhone = mobilePhone;
		result.creditRating = creditRating;
		result.accountNo = accountNo;
		result.emfConfigId = emfConfigId;
		result.serviceCode = serviceCode;
	}

	return result;
}

export function changeCartItemQuantity(orderItemId: string, quantity: number): Record<string, any> {
	return {
		x_calculateOrder: '1',
		x_inventoryValidation: true,
		orderItem: [
			{
				quantity: String(quantity),
				orderItemId,
			},
		],
	};
}

export function validateMakasibOtp(payload: IMakasibOtpPayload): Record<string, any> {
	return {
		msisdn: payload.msisdn,
		token: payload.token,
	};
}

export function changeCartItemData(data: IPrepaidOrderPayload): Record<string, any> {
	return {
		x_calculateOrder: '1',
		x_inventoryValidation: true,
		orderItem: [
			{
				...data,
			},
		],
	};
}

export function userLogIn(logonId: string, logonPassword: string): Record<string, any> {
	return {
		logonId,
		logonPassword,
	};
}

export function getOmantelIdentity(): Record<string, any> {
	return {};
}

export function chooseShipment({
	addressId = '',
	pickupDelivery,
}: {
	addressId?: string;
	pickupDelivery?: boolean;
}): Record<string, any> {
	return {
		x_calculateOrder: '1',
		x_calculationUsage: '-1,-2,-3,-4,-5,-6,-7',
		x_allocate: '***',
		x_backorder: '***',
		x_remerge: '***',
		x_check: '*n',
		orderId: '.',
		shipModeId: pickupDelivery ? '99' : '101',
		orderItem: [],
		addressId,
	};
}

export function chooseCybersourcePayment(piAmount: string): Record<string, any> {
	return {
		piAmount,
		billing_address_id: null,
		payMethodId: 'CyberSourceCard',
	};
}

export function updateUserProfile({
	addressType,
	firstName,
	lastName,
	middleName,
	city,
	zipCode,
	governorate,
	phoneNumber,
	email,
	organization,
	landmark,
	way,
	house,
	apartment,
	geographicalShippingCode,
	nickName,
	title,
	wilayat,
	area,
}: IUpdateProfilePayloadInput): Record<keyof types.payloads.IUpdateUserProfile, string | string[] | undefined> {
	return {
		firstName,
		lastName,
		city,
		middleName,
		zipCode,
		country: 'OM',
		state: governorate,
		phone1: phoneNumber,
		email1: email,
		organizationName: organization,
		addressType,
		addressLine: city ? [way ?? '', house ?? '', apartment ?? ''] : undefined,
		xaddr_addressField2: landmark,
		geographicalShippingCode,
		xaddr_addressField3: wilayat,
		xaddr_addressField1: area,
		nickName,
		personTitle: title,
	};
}

export function validateOffer({
	mobilePhone,
	commitment,
	planAction,
	oldTariff,
	skus,
	tariff,
	installmentAmount,
	provisioning,
	planCommitment,
	oldProvisioning,
	oldPlanCommitment,
	oldDeviceCommitment,
	installmentDuration,
	billingType,
	accountType,
	deviceType,
	creditRating,
	isStaff,
	vatProductId,
	upfrontProductId,
	vatProductAmt,
	upfrontProductAmt,
	upfrontCollection,
	vatCollection,
}: IValidatePlan): Record<string, any> {
	return {
		mobilePhone,
		commitment,
		skus,
		planAction,
		tariff,
		installmentAmount,
		provisioning,
		planCommitment,
		oldTariff,
		oldProvisioning,
		oldPlanCommitment,
		oldDeviceCommitment,
		installmentDuration,
		billingType,
		accountType,
		deviceType,
		creditRating,
		isStaff,
		vatProductId,
		upfrontProductId,
		vatProductAmt,
		upfrontProductAmt,
		upfrontCollection,
		vatCollection,
	};
}

export function validateEbuOffer({
	mobilePhone,
	commitment,
	smartVoucherCommitmentId,
	planAction,
	installmentAmount,
	hasAdditionalInfo,
}: IValidateEbuOffer): Record<string, any> {
	return {
		mobilePhone,
		commitment,
		smartVoucherCommitmentId,
		planAction,
		installmentAmount,
		hasAdditionalInfo: hasAdditionalInfo.toString(),
	};
}

export function createNotificationRequest({
	email = null,
	phoneNumber = null,
	partNumber,
	fullName,
}: ICreateNotificationRequest): Record<string, any> {
	return {
		type: email ? 'INVAVL_EMAIL' : 'INVAVL_SMS',
		partNumber,
		fullName,
		email,
		phoneNumber,
		languageId: getLanguageCode(),
	};
}

export function manageOrderItemESim({ orderItemId, esimAction, esimNo }: IManageOrderItemESim): Record<string, any> {
	return {
		orderItem: [
			{
				orderItemId,
				esimAction,
				esimNo,
			},
		],
	};
}

export function setHbbWfbbOrder(params: IHbbOrderPayload | IWfbbOrderPayload): Record<string, any> {
	const documents = [
		{ name: params.documentScan?.base64frontSide?.name, content: params.documentScan?.base64frontSide?.base64File },
		{ name: params.documentScan?.base64backSide?.name, content: params.documentScan?.base64backSide?.base64File },
	];
	delete params.documentScan;

	return {
		...params,
		idExpiryDate: params.expiryDate,
		documents,
	};
}

export function directContact({
	offer,
	name,
	phone,
	email,
	language,
	message,
	returnReference,
	sendEmailToSender,
	sendSms,
}: IContactPayload): Record<string, any> {
	return {
		offer,
		name,
		phone,
		email,
		language,
		message,
		returnReference,
		sendEmailToSender,
		sendSms,
	};
}

export function makePaymentRefund({ orderId }: IMakePaymentRefundPayload): Record<string, any> {
	return {
		orderId,
	};
}

export function refundPaymentLog({
	success,
	refundResponse,
	refundPayload,
}: IRefundPaymentLogPayload): Record<string, any> {
	return {
		success,
		refundResponse,
		refundPayload,
	};
}

export function otpValidateDocumentId({ document, msisdn }: IValidateTelesalesDocumentIdPayload): Record<string, any> {
	return {
		document,
		msisdn,
	};
}

export function otpValidateSmsPassword({
	token,
	msisdn,
	orderId,
}: IValidateTelesalesSmsPasswordPayload): Record<string, any> {
	return {
		token,
		msisdn,
		orderId,
	};
}

export function moveOrder({ phoneNumber, email, contactNumber }: IMoveOrderPayload): Record<string, any> {
	return {
		msisdn: phoneNumber,
		email,
		contactNumber,
	};
}

export function createBuyNowPayLaterSession({
	orderId,
	dateOfBirth,
	creditRating,
	isDisabledBnplCr7985,
	coRelationId,
}: IBuNowPayLeterSessionPayload): Record<string, any> {
	const result: any = {
		orderId,
		dateOfBirth,
		coRelationId,
	};

	if (!isDisabledBnplCr7985) {
		result.creditRating = creditRating;
	}

	return result;
}
export function retriveBuyNowPayLaterSession(orderId: string): Record<string, any> {
	return { orderId };
}
