import React, { FunctionComponent, useEffect, useState, useCallback } from 'react';
import Icon from '@Components/Icon/Icon';
import { Row } from 'react-grid-system';
import {
	CardWrapper,
	ProductTitle,
	CaptionWrapper,
	CheckAvailabilityWrapper,
	IconWrapper,
	ColorPickerWrapper,
	ColorPickerTitle,
	CapacityPickerWrapper,
	CapacityPickerTitle,
	ProducerCode,
	AddToCompareContainer,
	AddToCompareButton,
	AddToCompareText,
	OutletPickupWrapper,
	DescriptionWrapper,
	DividerWrapper,
	ChevronIconWrapper,
	RatingWrapper,
	AppleProductTitle,
	OutletIconWrapper,
} from './ProductConfigurator.styled';
import Availability from '@Components/Availability/Availability';
import useTranslate from '@Utils/hooks/useTranslate';
import { Caption, Subheading, SubheadingH2 } from '@Components/Typography/Typography';
import NotifyMe from '@Components/NotifyMe/NotifyMe';
import Divider from '@Components/Divider/Divider';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import ColorPicker from '@Components/controls/ColorPicker/ColorPicker';
import MultiSegmentPicker from '@Components/MultiSegmentPicker/MultiSegmentPicker';
import { ColorPickerPosition } from '@Components/controls/ColorPicker/ColorPicker.styles';
import { dispatch } from '@Redux/store';
import { setConfigurator } from '@Redux/modules/configurator/actions';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { GET_PRODUCT_DETAILS_BI } from '@Views/ProductPage/ProductPage';
import { useSelector } from 'react-redux';
import { addToCompare, removeFromCompare } from '@Redux/modules/comparison/actions';
import { CategoriesService } from '@Services/categories/categories';
import { ItemType, ProductsService } from '@Services/productsService/productsService';
import { useHistory } from 'react-router';
import { getLang } from '@Utils/language/language';
import TagManager from 'react-gtm-module';
import { setModal, modalTypes } from '@Redux/modules/modal/actions';
import { IOutletsInformation } from '@ApiModels/getProductOutletsPickup';
import { StarRating } from '@Components/ProductAdditionalInfoPanel/Reviews/Reviews';
import { DISABLE_PICKUP_FROM_OUTLET, isGreenZeroPayment } from '@Config/features.config';
import useAppleBrand from '@Utils/hooks/useAppleBrand';
import { IProductById } from '@ApiModels/productById';
import { Link } from 'react-router-dom';
import useMicrosoftAutomationProcess from '@Utils/hooks/useMicrosoftAutomationProcess';
import { removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { useBuyNowPayLater } from '@Utils/context/BuyNowPayLaterContext';
import { BannerImg, OuterBanner } from '../Plan/Plan.styled';
import greenNoUpFrontBanner from '@Assets/images/greenNoUpFront.png';
import arabicGreenNoUpFrontBanner from '@Assets/images/greenNoUpFront.png';
import { CreditRating } from '@Config/app.config';
import { remoteConfigSelector } from '@Redux/modules/settings/remoteConfig/selectors';

const OUTLET_AVAILABLE_INDICATOR = 'OUTLET_AVAILABLE_INDICATOR';
const ICON_SIZE = 32;
const CHEVRON_ICON_SIZE = 24;
const APPLE_WATCH = 'APPLE_WATCH';
const DEVICE_LABEL_TYPE = 'DEVICE_LABEL_TYPE';
const TAG_MANAGER_PRODUCT_VIEW = 'Product view';
const APPLE = 'Apple';

const ProductConfigurator: FunctionComponent<types.cards.IProductConfiguratorCardProps> = ({
	params: {
		userReviews,
		manufacturer,
		productDetails,
		notifyMe,
		onlyOnline,
		inStock,
		withPlan,
		timeLeftToOrder,
		colorProps,
		capacityProps,
		reverseProductOptionsVisbility,
		productById,
	},
}): JSX.Element => {
	const selectedColorIndex =
		(colorProps && colorProps.availableColors.findIndex((item) => item.color === colorProps.selectedColor)) ?? 0;
	const selectedColorIdentifier = productDetails?.colors[selectedColorIndex]?.identifier || '';
	const selectedCountryIdentifier = productDetails?.availableCountries[selectedColorIndex]?.identifier || '';
	const isOnList = !!useSelector((state: types.redux.IState) => state.comparisonList).find(
		(item) => item.id === productDetails?.uniqueID
	);
	const [smartphoneCategoryId, setSmartphoneCategoryId] = useState<string | undefined>(undefined);
	const history = useHistory();
	const outletAvailability = useSelector((state: types.redux.IState) => state.configurator.outletAvailability);
	const { translate } = useTranslate();

	const appleWatchLabelType = productDetails?.attributes?.find((attr) => attr.name === DEVICE_LABEL_TYPE);
	const isAppleWatch = appleWatchLabelType?.values[0].identifier === APPLE_WATCH;
	const { isAppleProductView } = useAppleBrand();
	const selectedSKU = useSelector((state: types.redux.IState) => state.selectedItems?.details);
	const { creditRating } = useSelector((state: types.redux.IState) => state.api.personalData);
	const [outletsInformation, setOutletsInformation] = useState<IOutletsInformation | null>(null);
	const [isOutletAvailable, setIsOutletAvailable] = useState<boolean>(false);
	const { isBusinessMicrosoftProduct } = useMicrosoftAutomationProcess();
	const { isBuyNowPayLaterFlow } = useBuyNowPayLater();
	const remoteFeatureConfig = useSelector(remoteConfigSelector);
	const DISABLE_GREEN_ZERO_PAYMENT = isGreenZeroPayment(remoteFeatureConfig);

	const greeBannerImageUrl = getLang() === 'en' ? greenNoUpFrontBanner : arabicGreenNoUpFrontBanner;

	useEffect(() => {
		if (!DISABLE_PICKUP_FROM_OUTLET && !(isBuyNowPayLaterFlow && !!withPlan)) {
			if (!!selectedSKU && !!outletAvailability) {
				setIsOutletAvailable(
					outletAvailability?.some((item) => item.uniqueId === selectedSKU?.partNumber && item.isAvailable) ??
						false
				);
				dispatch(removeBusyIndicator(OUTLET_AVAILABLE_INDICATOR));
			}
		}
		dispatch(removeBusyIndicator(OUTLET_AVAILABLE_INDICATOR));
	}, [selectedSKU, outletAvailability]);

	useEffect(() => {
		if (productDetails?.name) {
			TagManager.dataLayer({
				dataLayer: {
					event: TAG_MANAGER_PRODUCT_VIEW,
					Product_Name: productDetails?.name,
				},
			});
		}
	}, [productDetails?.name]);

	useEffect(() => {
		CategoriesService.getFlatCategories().subscribe(
			({ contents }) => {
				setSmartphoneCategoryId(contents.children.find(({ identifier }) => identifier === 'Smartphones')?.id);
			},
			() => {}
		);
	}, []);

	const getOutletsInformation = useCallback(() => {
		if (selectedSKU) {
			ProductsService.getProductOutletsPickup(selectedSKU.partNumber).subscribe(
				(r) => {
					setOutletsInformation(r.returnValue);
				},
				() => {}
			);
		}
	}, [selectedSKU?.partNumber]);

	useEffect(() => {
		if (!DISABLE_PICKUP_FROM_OUTLET && isOutletAvailable && !(isBuyNowPayLaterFlow && !!withPlan)) {
			getOutletsInformation();
		}
	}, [selectedSKU?.partNumber, isOutletAvailable]);

	const getDotTitle = () => {
		if (productDetails?.isGiftCard) {
			return `${translate('product.configuration.country')} ${selectedCountryIdentifier}`;
		} else {
			if (appleWatchLabelType?.values.find((val) => val.value === APPLE_WATCH)?.value === APPLE_WATCH) {
				return `${translate('product.configuration.bandColor')} ${
					colorProps?.availableColors.find((item) => item.color === colorProps.selectedColor)?.identifier
				}`;
			} else {
				return `${translate('product.configuration.color')} ${selectedColorIdentifier}`;
			}
		}
	};

	const handleValueChange = (selectedValue: string) => {
		dispatch(setConfigurator({ selectedCapacity: selectedValue }));
		capacityProps && capacityProps?.setSelectedValue(selectedValue);
	};

	const getMultiSegmentPickerTitle = () => {
		if (productDetails?.isGiftCard) {
			return translate('product.configuration.value');
		}
		if (productDetails?.isDigitalProduct) {
			return translate('product.configuration.subscription');
		}
		if (appleWatchLabelType?.values.find((val) => val.value === 'APPLE_WATCH')?.value === 'APPLE_WATCH') {
			return translate('product.configuration.caseColor');
		} else {
			return translate('product.configuration.capacity');
		}
	};

	const onPressComparison = () => {
		if (isOnList && productDetails?.uniqueID) {
			dispatch(removeFromCompare({ id: productDetails?.uniqueID }));
		} else if (productDetails?.uniqueID) {
			dispatch(addToCompare({ thumbnailUrl: productDetails.thumbnail, id: productDetails.uniqueID }));
			history.push(`/${getLang()}/store/Smartphones`);
		}
	};

	const colorPicker = (
		<ConditionalRender
			show={!!colorProps?.availableColors.length && !productDetails?.isDigitalProduct}
			onTrue={() => (
				<Row>
					<ColorPickerWrapper>
						<ColorPickerTitle textTransform="uppercase" color="black87">
							{getDotTitle()}
						</ColorPickerTitle>
						{colorProps && (
							<ColorPicker
								colors={colorProps.availableColors}
								setSelectedColor={colorProps?.setSelectedColor}
								selectedColor={colorProps?.selectedColor}
								position={ColorPickerPosition.HORIZONTAL}
							/>
						)}
					</ColorPickerWrapper>
				</Row>
			)}
		/>
	);

	const capacityPicker = (
		<ConditionalRender
			show={!!capacityProps?.availableValues.length}
			onTrue={() => (
				<Row>
					<CapacityPickerWrapper>
						<CapacityPickerTitle textTransform="uppercase" color="black87">
							{getMultiSegmentPickerTitle()}
						</CapacityPickerTitle>
						{capacityProps && (
							<MultiSegmentPicker
								availableValues={capacityProps.availableValues}
								setSelectedValue={handleValueChange}
								selectedValue={capacityProps.selectedValue}
								isAppleWatch={reverseProductOptionsVisbility}
							/>
						)}
					</CapacityPickerWrapper>
				</Row>
			)}
		/>
	);

	const manufacturerSubheading = (
		<Row justify="between">
			<BusyIndicator listener={[GET_PRODUCT_DETAILS_BI]} skeleton="searchResult">
				<ConditionalRender
					show={isAppleProductView(productById || ({} as IProductById))}
					onTrue={() => (
						<Link to={`/${getLang()}/apple-brand-store`}>
							<AppleProductTitle textTransform="uppercase" color="primary">
								{manufacturer?.manufacturer}
							</AppleProductTitle>
						</Link>
					)}
					onFalse={() => (
						<SubheadingH2 textTransform="uppercase" color="black54">
							{manufacturer?.manufacturer}
						</SubheadingH2>
					)}
				/>
			</BusyIndicator>
		</Row>
	);

	return (
		<CardWrapper>
			{manufacturerSubheading}
			<Divider marginTop={8} withoutLine />
			<Row>
				<BusyIndicator listener={[GET_PRODUCT_DETAILS_BI]} skeleton="productTitle">
					<ProductTitle fontWeight="bold" color="black87">
						{productDetails?.name}
					</ProductTitle>
				</BusyIndicator>
			</Row>
			<Row>
				<ProducerCode>
					{!!manufacturer?.partNumber?.code ? (
						<Caption color="black54">
							{translate('product.configuration.producer.code', manufacturer?.partNumber?.code)}
						</Caption>
					) : (
						''
					)}
				</ProducerCode>
			</Row>
			<ConditionalRender
				show={false}
				onTrue={() => (
					<RatingWrapper>
						<Row>
							<StarRating rating={4.4} reviews={4} />
						</Row>
					</RatingWrapper>
				)}
			/>
			<Row>
				<Divider marginTop={24} withoutLine />
			</Row>
			<ConditionalRender
				show={reverseProductOptionsVisbility}
				onTrue={() => capacityPicker}
				onFalse={() => colorPicker}
			/>
			<ConditionalRender
				show={reverseProductOptionsVisbility}
				onTrue={() => colorPicker}
				onFalse={() => capacityPicker}
			/>

			<ConditionalRender
				show={!!smartphoneCategoryId && !!productDetails?.parents?.includes(smartphoneCategoryId)}
				onTrue={() => (
					<Row>
						<AddToCompareContainer>
							<AddToCompareButton onClick={onPressComparison}>
								{translate('product-page.compare-1')}
							</AddToCompareButton>
							<AddToCompareText>{translate('product-page.compare-2')}</AddToCompareText>
						</AddToCompareContainer>
					</Row>
				)}
			/>
			<Row>
				<Availability
					preorder={{
						preorder: !!productDetails?.preorder,
						preorderDate: productDetails?.preorderDate,
						launchDate: productDetails?.launchDate,
					}}
					onlyOnline={onlyOnline}
					inStock={inStock}
					timeLeftToOrder={timeLeftToOrder}
					giftCard={!!productDetails?.isGiftCard || !!productDetails?.isDigitalProduct}
					isSalesLead={productDetails?.itemType === ItemType.SALES_LEAD_DEVICE}
				/>
			</Row>
			<Row>
				<ConditionalRender
					show={notifyMe}
					onTrue={() => (
						<>
							<Divider marginBottom={16} marginTop={16} />
							<IconWrapper>
								<Icon name="outletLocation" fill="black38" height={ICON_SIZE} width={ICON_SIZE} />
							</IconWrapper>
							<CheckAvailabilityWrapper>
								<Caption>{translate('availability.check.availability')}</Caption>
								<CaptionWrapper
									onClick={() => {
										window.alert('// TODO: open omantel outlet modal');
									}}
									color="primary"
								>
									{translate('availability.omantel.outlet')}
								</CaptionWrapper>
							</CheckAvailabilityWrapper>
						</>
					)}
				/>
			</Row>
			<Row>
				<ConditionalRender show={!inStock} onTrue={() => <NotifyMe />} />
			</Row>
			<DividerWrapper>
				<Row>
					<Divider />
				</Row>
			</DividerWrapper>
			<BusyIndicator listener={[OUTLET_AVAILABLE_INDICATOR]} skeleton="outletAvailability">
				<ConditionalRender
					show={
						!(
							creditRating === CreditRating.GREEN &&
							DISABLE_GREEN_ZERO_PAYMENT &&
							selectedSKU?.installmentPeriod?.length
						)
					}
					onTrue={() => (
						<Row>
							<OutletPickupWrapper
								IsOutletAvailable={isOutletAvailable && !(isBuyNowPayLaterFlow && !!withPlan)}
								onClick={() => {
									isOutletAvailable && !(isBuyNowPayLaterFlow && !!withPlan)
										? dispatch(
												setModal({
													type: modalTypes.OUTLET_AVAILABLE,
													data: {
														productDetails,
														chosenColor: selectedColorIdentifier,
														chosenCapacity: capacityProps?.selectedValue,
														outletsInformation:
															outletsInformation !== null ? outletsInformation : [],
													},
													withoutContainer: true,
												})
										  )
										: null;
								}}
							>
								<Row>
									<OutletIconWrapper>
										<Icon name="outlet" fill="black38" height={ICON_SIZE} width={ICON_SIZE} />
									</OutletIconWrapper>
									<DescriptionWrapper>
										<Subheading textTransform="uppercase">
											{translate(
												'shipping-details.accordion.delivery-method.title.outlet-pickup'
											)}
										</Subheading>

										<Caption
											color={
												isOutletAvailable && !(isBuyNowPayLaterFlow && !!withPlan)
													? 'positive'
													: 'black54'
											}
										>
											{translate(
												isBuyNowPayLaterFlow && !!withPlan
													? 'outlet.pickup.availability.outlet-bnpl'
													: isOutletAvailable
													? 'outlet.pickup.availability.outlet-available'
													: 'outlet.pickup.availability.outlet-not-available'
											)}
										</Caption>
									</DescriptionWrapper>
									<ConditionalRender
										show={isOutletAvailable}
										onTrue={() => (
											<ChevronIconWrapper>
												<Icon
													name="chevronRight"
													width={CHEVRON_ICON_SIZE}
													height={CHEVRON_ICON_SIZE}
												/>
											</ChevronIconWrapper>
										)}
									/>
								</Row>
							</OutletPickupWrapper>
						</Row>
					)}
					onFalse={() => (
						<>
							<Row>
								<OutletPickupWrapper
									IsOutletAvailable={isOutletAvailable && !(isBuyNowPayLaterFlow && !!withPlan)}
									onClick={() => {
										isOutletAvailable && !(isBuyNowPayLaterFlow && !!withPlan)
											? dispatch(
													setModal({
														type: modalTypes.OUTLET_AVAILABLE,
														data: {
															productDetails,
															chosenColor: selectedColorIdentifier,
															chosenCapacity: capacityProps?.selectedValue,
															outletsInformation:
																outletsInformation !== null ? outletsInformation : [],
														},
														withoutContainer: true,
													})
											  )
											: null;
									}}
								>
									<Row>
										<OutletIconWrapper>
											<Icon name="outlet" fill="black38" height={ICON_SIZE} width={ICON_SIZE} />
										</OutletIconWrapper>
										<DescriptionWrapper>
											<Subheading textTransform="uppercase">
												{translate(
													'shipping-details.accordion.delivery-method.title.outlet-pickup'
												)}
											</Subheading>

											<Caption
												color={
													isOutletAvailable && !(isBuyNowPayLaterFlow && !!withPlan)
														? 'positive'
														: 'black54'
												}
											>
												{translate(
													isBuyNowPayLaterFlow && !!withPlan
														? 'outlet.pickup.availability.outlet-bnpl'
														: isOutletAvailable
														? 'outlet.pickup.availability.outlet-available'
														: 'outlet.pickup.availability.outlet-not-available'
												)}
											</Caption>
										</DescriptionWrapper>
										<ConditionalRender
											show={isOutletAvailable}
											onTrue={() => (
												<ChevronIconWrapper>
													<Icon
														name="chevronRight"
														width={CHEVRON_ICON_SIZE}
														height={CHEVRON_ICON_SIZE}
													/>
												</ChevronIconWrapper>
											)}
										/>
									</Row>
								</OutletPickupWrapper>
							</Row>
							<DividerWrapper>
								<Row>
									<Divider />
								</Row>
							</DividerWrapper>
							<Row>
								<OuterBanner style={{ margin: '0px', height: 'auto' }}>
									<BannerImg src={greeBannerImageUrl} alt="Green Banner" />
								</OuterBanner>
							</Row>
						</>
					)}
				/>
			</BusyIndicator>
			<ConditionalRender
				show={isBusinessMicrosoftProduct(productDetails?.name ?? '')}
				onTrue={() => (
					<>
						<DividerWrapper>
							<Row>
								<Divider />
							</Row>
						</DividerWrapper>
						<Row>
							<Icon name="frame" fill="black38" height={ICON_SIZE} width={ICON_SIZE} />
							<DescriptionWrapper>
								<Subheading textTransform="uppercase">
									{translate('microsoft-automation-proccess.activation-process-title')}
								</Subheading>
								<Caption color="black54">
									{translate('microsoft-automation-proccess.activation-process-description')}
								</Caption>
							</DescriptionWrapper>
						</Row>
					</>
				)}
			/>
		</CardWrapper>
	);
};

export default ProductConfigurator;
