import { AccountType, BillingType } from './accounts';
import { CreditRating } from '@Config/app.config';
interface IProductCharacteristic {
	name: string;
	value: string;
}

interface ICustomerAccount {
	customer: {
		characteristic: { name: string; value: string }[];
	};
}

enum WfbbType {
	ITDD5G = 'ITDD5G',
	OTDD5G = 'OTDD5G',
	WFBB4GOUT = 'WFBB4GOUT',
	ITDD = 'ITDD',
	OTDD = 'OTDD',
	WFBB4GIN = 'WFBB4GIN',
}

export interface ICustomerAccountOperation {
	isSuccess: boolean;
	customerAccount: {
		id: string;
		product: {
			startDate: string;
			productCharacteristic: IProductCharacteristic[];
			productSpecification: {
				name: string;
				productSpecCharacteristic: {
					productSpecCharacteristicValue: {
						value: string;
					};
				};
				productCategory: {
					type: AccountType;
				};
			};
		};
		customer: {
			name: string;
			relatedParty: {
				individual: {
					contactMedium: any[];
					fullName: string;
					individualIdentification: {
						identificationId: string;
						type: string;
					};
				};
			};
			wfbbType: WfbbType;
			characteristic: {
				name: 'DCB_PAYMENT_OPTION';
				value: 'TRUE' | 'FALSE';
			};
			customerAccount: ICustomerAccount | ICustomerAccount[];
		};
		customerAccountBalance: {
			status: string;
		};
	};
	isDCBPaymentOptionEnabled: boolean;
	wfbbRouter5GType: boolean;
	telesalesCustomerBillingType: BillingType;
	telesalesCustomerAccountType: AccountType;
	telesalesCustomerAccountCategory: string;
	nationality: string;
	creditRating: CreditRating;
	tierName: string;
}

const getAccountType = (data: ICustomerAccountOperation) => {
	let accountType: number | undefined;
	const customerAccountData = data?.customerAccount?.customer?.customerAccount;
	!!customerAccountData && Array.isArray(customerAccountData)
		? customerAccountData.forEach((customerAccount) => {
				customerAccount?.customer?.characteristic?.forEach((secondCustomerAccount: any) => {
					if (secondCustomerAccount?.name === 'ACCOUNT_CATEGORY') accountType = secondCustomerAccount.value;
				});
		  })
		: customerAccountData?.customer?.characteristic?.forEach((secondCustomerAccount: any) => {
				if (secondCustomerAccount?.name === 'ACCOUNT_CATEGORY') accountType = secondCustomerAccount.value;
		  });
	return accountType;
};

const getNationality = (model: ICustomerAccountOperation) => {
	const nationality = model?.customerAccount?.product?.productCharacteristic?.find(
		(productCharacteristicItem) => productCharacteristicItem.name === 'NATIONALITY'
	)?.value;
	return nationality ?? '';
};

const getCreditRating = (model: ICustomerAccountOperation) => {
	const creditRating = model?.customerAccount?.product?.productCharacteristic?.find(
		(productCharacteristicItem) => productCharacteristicItem?.name === 'CREDIT_RATING'
	)?.value;
	return creditRating ?? '';
};

const model: types.models.IInputModel = {
	customerAccount: 'response.getCustomerAccountResponse.customerAccount',
	_getCustomerAccountResponse: 'response.getCustomerAccountResponse',
	isSuccess: (model) => model._getCustomerAccountResponse?.result?.result?.resultDescription === 'Success',
	isDCBPaymentOptionEnabled: (model) =>
		model?.customerAccount?.customer?.characteristic?.name === 'DCB_PAYMENT_OPTION' &&
		model?.customerAccount?.customer?.characteristic?.value === 'TRUE',
	wfbbRouter5GType: (model: ICustomerAccountOperation) =>
		['ITDD5G', 'OTDD5G'].includes(model?.customerAccount?.customer.wfbbType),
	nationality: (model) => getNationality(model),
	creditRating: (model) => getCreditRating(model),
	telesalesCustomerBillingType: (model: ICustomerAccountOperation) =>
		model.customerAccount.product.productSpecification.productSpecCharacteristic.productSpecCharacteristicValue
			.value,
	telesalesCustomerAccountType: (model: ICustomerAccountOperation) =>
		model.customerAccount.product.productSpecification.productCategory.type,
	telesalesCustomerAccountCategory: (model: ICustomerAccountOperation) => getAccountType(model),
};

export default model;
