import { BodyM } from '@Components/Typography/Typography.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent } from 'react';
import {
	VatMessageBarCircle,
	VatMessageBarPercent,
	VatMessageBarValue,
	VatMessageBarWrapper,
} from './PriceWithVat.styled';

interface IVatMessageBar {
	vat: number;
	isGreenPaymentUser?: boolean;
}

const VatMessageBar: FunctionComponent<IVatMessageBar> = ({ vat, isGreenPaymentUser = false }) => {
	const { translate } = useTranslate();
	return vat ? (
		<VatMessageBarWrapper>
			<VatMessageBarCircle>
				<VatMessageBarValue>{translate('message.box.vat.percent', String(vat))}</VatMessageBarValue>
				<VatMessageBarPercent>{translate('message.box.vat.percent.vat')}</VatMessageBarPercent>
			</VatMessageBarCircle>
			<BodyM color="black54">
				{isGreenPaymentUser
					? translate('message.box.vat.first.month.bill', String(vat))
					: translate('message.box.vat', String(vat))}
			</BodyM>
		</VatMessageBarWrapper>
	) : null;
};

export default VatMessageBar;
