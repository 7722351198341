import React, { FunctionComponent } from 'react';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Divider from '@Components/Divider/Divider';
import Icon from '@Components/Icon/Icon';
import { BodyM, Title } from '@Components/Typography/Typography.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import RejectedSummaryAction from './RejectedSummaryAction';

export interface IRejectedSummaryActionProps {
	backToStore: () => void;
	timeoutReached: boolean;
	tryAgain: () => void;
}

interface IRejectedSummaryProps extends IRejectedSummaryActionProps {
	errorString: string | null;
	exceptionDescription?: string;
	getErrorMessage: (field: 'description' | 'title') => string;
	hbbWfbbProcess: boolean | undefined;
	iconSize: number;
	showTryAgainButton: boolean | undefined;
	isGreenRatedUserPayment?: boolean;
}

const RejectedSummary: FunctionComponent<IRejectedSummaryProps> = ({
	backToStore,
	errorString,
	exceptionDescription,
	getErrorMessage,
	hbbWfbbProcess,
	iconSize,
	showTryAgainButton,
	timeoutReached,
	isGreenRatedUserPayment = false,
	tryAgain,
}): JSX.Element => {
	const GENERIC_PRE_VALIDATION_ERROR = 'GENERIC_PRE_VALIDATION_ERROR';
	const { translate } = useTranslate();
	let titleKey = 'checkout-summary.rejected';
	let bodyKey = getErrorMessage('title');

	if (hbbWfbbProcess) {
		titleKey = 'checkout.summary.something.went.wrong';
		bodyKey = 'checkout.summary.problem.with.request';
	} else if (isGreenRatedUserPayment) {
		titleKey = 'checkout-summary.zero.payment.rejected';
		bodyKey = 'checkout-summary.something.went.wrong.greencst.title';
	} else if (timeoutReached) {
		titleKey = 'checkout-summary.timeout.reached';
		bodyKey = 'checkout-summary.timeout.reached.title';
	} else if (errorString !== GENERIC_PRE_VALIDATION_ERROR) {
		titleKey = 'checkout-summary.failed';
	}

	return (
		<>
			<Icon height={iconSize} width={iconSize} fill="warning" name="error" />
			<Divider color="transparent" marginBottom={16} />
			<Title color="warning">{translate(titleKey)}</Title>
			<Divider color="transparent" marginBottom={16} />
			<ConditionalRender
				show={errorString !== GENERIC_PRE_VALIDATION_ERROR}
				onTrue={() => {
					return (
						<ConditionalRender
							show={isGreenRatedUserPayment}
							onTrue={() => (
								<BodyM color="black87">
									{translate('checkout-summary.something.went.wrong.greencst.title')}
								</BodyM>
							)}
							onFalse={() => (
								<BodyM color="black87">{translate('checkout-summary.UNABLE_TO_MAKE_PAYMENT')}</BodyM>
							)}
						/>
					);
				}}
				onFalse={() => {
					return (
						<>
							<BodyM color="black87">{translate(bodyKey)}</BodyM>
							{timeoutReached && <Divider withoutLine marginTop={16} />}
						</>
					);
				}}
			/>
			{showTryAgainButton ? (
				<RejectedSummaryAction
					backToStore={backToStore}
					primaryButtonLabel={translate('checkout-summary.try.again')}
					secondaryButtonLabel={translate('checkout-summary.go.back.to.store')}
					timeoutReached={timeoutReached}
					tryAgain={tryAgain}
				/>
			) : null}
		</>
	);
};

export default RejectedSummary;
