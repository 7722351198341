import { IProductOutput } from '@ApiModels/customerProducts';
import { IProductsByPartNumber } from '@ApiModels/productsByPartNumber';
import { ICartItem, ICartItemPlan, IInsuranceCartItem } from '@ApiModels/viewCart';
import Accordion from '@Components/Accordion/Accordion';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import Divider from '@Components/Divider/Divider';
import GetESIM from '@Components/GetESIM/GetESIM';
import GetInsurance, { EXCLUDE_CART_ITEMS } from '@Components/GetInsurance/GetInsurance';
import Icon from '@Components/Icon/Icon';
import MessageBar from '@Components/MessageBar/MessageBar';
import VatMessageBar from '@Components/PriceWithVat/VatMessageBar';
import QuantityComponent from '@Components/QuantityComponent/QuantityComponent';
import { BodyMBold, BodyS, CaptionBigger, Info, InfoBold } from '@Components/Typography/Typography.styled';
import { isUserSignedIn } from '@Redux/modules/api/authentication/selectors';
import { modalTypes, setModal } from '@Redux/modules/modal/actions';
import { dispatch } from '@Redux/store';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { ItemType, ProductsService } from '@Services/productsService/productsService';
import { useCart } from '@Utils/context/CartContext';
import { INSURANCE_TYPE } from '@Utils/context/InsuranceContext';
import { roundMoney } from '@Utils/converters/roundMoney';
import useCartPlans from '@Utils/hooks/useCartPlans';
import useTranslate from '@Utils/hooks/useTranslate';
import { BUY_HBB, BUY_WFBB } from '@Views/Map/Map';
import useScreen from '@Utils/hooks/useScreen';
import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { Col, Hidden, Row, useScreenClass, Visible } from 'react-grid-system';
import { useLocation } from 'react-router-dom';
import {
	AccordionContentWrapper,
	AccordionsContainer,
	ButtonWrapper,
	CaptionWrapper,
	CartItemContainer,
	DetailsContainer,
	DetailsWrapper,
	EmptyLine,
	IconWrapper,
	ItemDescription,
	ItemDescriptionWrapper,
	ItemTitle,
	MessageBarCol,
	NameWrapper,
	OpenPlanDetailsWrapper,
	PlanInfo,
	PlanInfoContent,
	PlanTitleWrapper,
	PriceContainer,
	PriceRow,
	PriceWrapper,
	QtyWrapper,
	QuantityWrapper,
	RemoveItemWrapper,
	SectionContent,
	SectionHeader,
	SeeCode,
	Thumbnail,
	TitleContainer,
	TitleWrapper,
	VatPriceAndPercentage,
	VatWrapper,
	VerticalDivider,
	CarInsuranceDetailsWraper,
	CarInsuranceDetails,
	BuyPrepaidDetailsWraper,
	BuyPrepaidDetails,
	OpenPlanDetailsPrepaidWrapper,
} from './CartItem.styled';
import { getLang } from '@Utils/language/language';

import {
	INSURANCE_REQUEST_TYPE,
	MAKASIB_POINTS_PRODUCT_ID,
	POSTPAID_PLAN,
	JAWWY_PRODUCT_PARTNUMBER_ID_PART,
	MAKASIB_VOUCHER_PRODUCT_ID,
	CreditRating,
} from '@Config/app.config';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { getUserId } from '@Redux/modules/api/authentication/selectors';

export enum CartItemType {
	DEFAULT = 'DEFAULT',
	ORDER_HISTORY = 'ORDER_HISTORY',
}
import { isSoonPeorderDate } from '@Utils/date/moment';
import { isVoucherPlanAction } from '@Utils/plans/planAction';
import useMicrosoftAutomationProcess from '@Utils/hooks/useMicrosoftAutomationProcess';
import { MakasibService } from '@Services/makasib/makasib';
import { isWifiFreeDevice } from '@Config/features.config';
import { remoteConfigSelector } from '@Redux/modules/settings/remoteConfig/selectors';
import { useBuyNowPayLater } from '@Utils/context/BuyNowPayLaterContext';
import { isGreenZeroPayment } from '../../../../../config/features.config';
import { bnplCal } from '@Utils/constants/dataLayer';
interface IProps {
	partNumber: string;
	numberOfItems: number;
	isLast: boolean;
	orderItemId: string;
	type: CartItemType;
	cartItem: ICartItem | IInsuranceCartItem;
	withoutQuantityButtons?: boolean;
	showPlanDetailsAndRemoveButton?: boolean;
	priceColor?: types.theme.themeColors;
	vat?: number;
	vatPercent?: number;
	tableMode: boolean;
	addToCart: boolean;
	deviceInCart: boolean;
	disablePreorder: boolean;
	isOrderHistoryView?: boolean;
	summaryView?: boolean;
	purchasedInsurance?: IProductsByPartNumber;
	loading?: boolean;
	email?: string;
}

interface ICartRemoveItem {
	name?: string;
	orderItemId?: string;
	voucherId?: string;
	productId?: string;
}

const REMOVE_ICON_SIZE = 24;
const MICROSOFT = 'microsoft';
const BNPL_PLAN_ACTIONS = ['BNPL_BAQATI', 'BNPL_HBB', 'BNPL_WFBB'];

const CartItem: FunctionComponent<IProps> = ({
	partNumber,
	numberOfItems,
	isLast,
	orderItemId,
	type,
	cartItem,
	withoutQuantityButtons,
	showPlanDetailsAndRemoveButton,
	priceColor,
	vat,
	vatPercent,
	tableMode,
	addToCart,
	deviceInCart,
	disablePreorder,
	isOrderHistoryView = false,
	summaryView = false,
	purchasedInsurance,
	loading,
	email,
}) => {
	const [product, setProduct] = useState<IProductsByPartNumber>();
	const [deviceDiscountPrice, setDeviceDiscountPrice] = useState<number | undefined>(undefined);
	const { translate } = useTranslate();
	const screenClass = useScreenClass();
	const withPlan = cartItem.withPlan;
	const plan = cartItem.plan;
	const { planOfSpecificAccount, cartItemWithPlan } = useCartPlans({}, [product]);
	const { search } = useLocation();
	const query = new URLSearchParams(search);
	const { cart, isEbuCart } = useCart();
	const { insurancePromotion } = product || {};
	const isSignedIn = useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const { creditRating, tierName, billingType } = useSelector((state: types.redux.IState) => state.api.personalData);
	const remoteFeatureConfig = useSelector(remoteConfigSelector);
	const DISABLE_WIFI_FREE_DEVICE = isWifiFreeDevice(remoteFeatureConfig);
	const DISABLE_GREEN_ZERO_PAYMENT = isGreenZeroPayment(remoteFeatureConfig);
	const { isMicrosoftAutomationProcess } = useMicrosoftAutomationProcess();
	const isCheckoutProcess = window.location.href.toLocaleLowerCase().includes('checkout');
	const isCartView = window.location.href.toLocaleLowerCase().includes('cart');
	const activeInsuranceInCart = useMemo(() => {
		return cart.items.find((item) => EXCLUDE_CART_ITEMS.includes(item.partNumber));
	}, [cart]);
	const { isBuyNowPayLaterInCart, buyNowPayLaterItemDuration } = useBuyNowPayLater();

	const { screen } = useScreen();

	const isBNPLOrderHistory = BNPL_PLAN_ACTIONS.includes(cartItem.plan?.PA ?? '') && isOrderHistoryView;

	const disableInsurance = useMemo(() => {
		if (activeInsuranceInCart) {
			return false;
		}
		return cartItem.quantity > 1 || cart.items.length >= 2;
	}, [cartItem, activeInsuranceInCart]);

	const buyCarInsurance = useMemo(() => {
		return !!cartItem?.requestType && INSURANCE_REQUEST_TYPE.includes(cartItem?.requestType);
	}, [cartItem]);

	const isPrepaid = plan?.PA?.includes(POSTPAID_PLAN);

	useEffect(() => {
		ProductsService.getProductsByPartNumber(partNumber).subscribe(
			(product) => {
				setProduct(product);
			},
			() => {}
		);
	}, [partNumber]);

	const trackRemoval = (removeItem: any) => {
		TagManager.dataLayer({
			dataLayer: {
				event: 'remove_from_cart',

				ecommerce: {
					currency: 'OMR',
					value: !!removeItem?.BNPLFEP?.length
						? roundMoney(bnplCal(removeItem))
						: roundMoney(removeItem?.taxablePrice * removeItem?.quantity),
					LoyaltyStatus: tierName,
					Logged_in: isSignedIn ? 'Yes' : 'No',
					account_0_ConnectionType: billingType,
					userId: getUserId(),
					items: [
						{
							item_id: removeItem?.productParentId,
							item_name: removeItem?.name ?? '',
							item_variant: removeItem?.productId,
							item_brand: null,
							item_category: null,
							item_category2: null,
							price: !!removeItem?.BNPLFEP?.length
								? roundMoney(bnplCal(removeItem))
								: roundMoney(removeItem?.taxablePrice),
							upfront_price: !!removeItem?.BNPLFEP?.length
								? null
								: removeItem?.installmentDuration
								? roundMoney(Number(removeItem?.price) - Number(removeItem?.vatValue))
								: null,
							quantity: removeItem?.quantity || 1,
							is_preorder: Boolean(removeItem?.plan?.preorderDate?.length),
							index: 0,
							coupon: null,
							discount: null,
							payment_period: removeItem?.plan?.installmentDuration?.length
								? removeItem?.plan?.installmentDuration
								: 0,
							price_plan_part1: removeItem?.plan?.IV?.length ? Number(removeItem?.plan?.IV) : 0,
							price_plan_part2: removeItem?.plan?.BNPLFEP?.length ? Number(removeItem?.plan?.BNPLFEP) : 0,
						},
					],
				},
			},
		});
	};

	const removeItem = () => {
		if (
			cart.items.filter(
				(item) => item.productId !== MAKASIB_POINTS_PRODUCT_ID || item.productId !== MAKASIB_VOUCHER_PRODUCT_ID
			).length === 1 &&
			cart.items.some(
				(item) => item.productId === MAKASIB_POINTS_PRODUCT_ID || item.productId === MAKASIB_VOUCHER_PRODUCT_ID
			)
		) {
			const makasibVouchers = cart.items.filter((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID);
			OrderFlowService.clearAllCartItems().subscribe(
				() => {
					if (makasibVouchers.length > 0) {
						makasibVouchers.forEach((item) => {
							MakasibService.manageMakasibVoucherReservation(String(item?.voucherId), 'U').subscribe(
								() => {},
								() => {}
							);
						});
					}
				},
				() => {}
			);
		} else {
			if (cart.items.some((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID)) {
				const makasibVoucher = cart.items.filter((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID);
				if (makasibVoucher.length > 0) {
					makasibVoucher.forEach((item) => {
						OrderFlowService.removeSingleCartItem(item?.orderItemId ?? '').subscribe(
							() => {
								MakasibService.manageMakasibVoucherReservation(String(item?.voucherId), 'U').subscribe(
									() => {},
									() => {}
								);

								trackRemoval(item);
							},
							() => {}
						);
					});
				}
			}
			if (cart.items.some((item) => item.productId === MAKASIB_POINTS_PRODUCT_ID)) {
				const makasibItem = cart.items.find((item) => item.productId === MAKASIB_POINTS_PRODUCT_ID);
				if (!!makasibItem) {
					OrderFlowService.removeSingleCartItem(makasibItem?.orderItemId).subscribe(
						() => {
							trackRemoval(makasibItem);
						},
						() => {}
					);
				}
			}
			if (activeInsuranceInCart) {
				OrderFlowService.removeSingleCartItem(activeInsuranceInCart.orderItemId).subscribe(
					() => {
						trackRemoval(activeInsuranceInCart);
					},
					() => {}
				);
			}
			if (!DISABLE_WIFI_FREE_DEVICE && cartItem?.freeProductCatId) {
				cart?.items?.forEach((item) => {
					if (item?.productId === cartItem?.freeProductCatId) {
						OrderFlowService.removeSingleCartItem(item?.orderItemId).subscribe(
							() => {
								trackRemoval(cartItem);
							},
							() => {}
						);
					}
				});
			}
			OrderFlowService.removeSingleCartItem(orderItemId).subscribe(
				() => {
					trackRemoval(cartItem);
				},
				() => {}
			);
		}
	};

	const openPlanDetails = () => {
		if (plan?.planDetails?.planId) {
			dispatch(
				setModal({
					type: modalTypes.PLAN_DETAILS,
					data: {
						planId: plan?.planDetails?.planId,
						isHBB: plan?.PA?.includes('HBB'),
						isWFBB: plan?.PA?.includes('WFBB'),
						isPrepaid,
					},
					withoutContainer: false,
				})
			);
		}
	};

	const getOrderId = () => {
		return query.get('orderId');
	};

	const isGreenRatedUserPayment =
		DISABLE_GREEN_ZERO_PAYMENT &&
		creditRating === CreditRating.GREEN &&
		['F', 'I'].includes(cartItem.plan?.upfrontCollection as string);

	const seeCode = () => {
		dispatch(
			setModal({
				type: modalTypes.GIFT_CARD_CODES,
				data: {
					name: product?.name ?? '',
					price: product?.giftCardValue ?? '',
					country: product?.country ?? '',
					orderId: getOrderId(),
					partNumber: product?.partNumber ?? '',
				},
				withoutContainer: false,
			})
		);
	};

	const isBusinessMicrosoftProduct = (productName: string) => {
		return productName.includes('Microsoft 365') && productName.includes('Business');
	};

	const CartRow = ({
		selectedPlan,
		oldPlan,
		devicePriceDiscount,
		price,
		installmentValue,
		product,
		specificAccountNumber,
		installmentPeriod,
		bnplUpfrontAmount,
		bnplUpfrontMinusVat,
	}: {
		selectedPlan?: ICartItemPlan;
		oldPlan?: IProductOutput;
		price?: number;
		devicePriceDiscount?: boolean;
		specificAccountNumber?: string;
		installmentValue?: string;
		product?: IProductsByPartNumber;
		installmentPeriod?: string;
		bnplUpfrontAmount?: number;
		bnplUpfrontMinusVat?: number;
	}) => {
		const planContractDuration = selectedPlan?.planContractDuration;
		const planUpgrade =
			selectedPlan?.PA === 'MOBILE_PLAN_UPGRADE' ||
			selectedPlan?.PA === 'B2B2C_MOBILE_PLAN_UPGRADE_BAQATI' ||
			selectedPlan?.PA === 'B2B_MOBILE_PLAN_UPGRADE_BAQATI' ||
			selectedPlan?.PA === 'HBB_PLAN_UPGRADE' ||
			selectedPlan?.PA === 'WFBB_PLAN_UPGRADE';
		const planRenewal =
			selectedPlan?.PA === 'MOBILE_THE_SAME_PLAN' ||
			selectedPlan?.PA === 'B2B2C_MOBILE_THE_SAME_PLAN_BAQATI' ||
			selectedPlan?.PA === 'B2B_MOBILE_THE_SAME_PLAN_BAQATI' ||
			selectedPlan?.PA === 'HBB_THE_SAME_PLAN' ||
			selectedPlan?.PA === 'WFBB_THE_SAME_PLAN';
		const buyHBBWFBB = selectedPlan?.PA === BUY_HBB || selectedPlan?.PA === BUY_WFBB;

		const { manufacturer, thumbnailUrl, name, capacity, color } = product || {};

		return (
			<>
				<Row align={screen(['xs', 'sm']) ? 'start' : 'center'}>
					<Col lg={1} md={1} sm={2} xs={2}>
						<Row justify="center">
							<ConditionalRender
								show={!!thumbnailUrl && !buyCarInsurance}
								onTrue={() => <Thumbnail src={thumbnailUrl} />}
								onFalse={() => (
									<>
										<ConditionalRender
											show={buyCarInsurance && !!cartItem.logo}
											onTrue={() => (
												<Thumbnail
													src={buyCarInsurance && !!cartItem.logo ? cartItem.logo : 'car'}
												/>
											)}
											onFalse={() => (
												<ConditionalRender
													show={!loading}
													onTrue={() => (
														<Icon
															name={buyHBBWFBB ? 'buyHbbWfbb' : 'eSim'}
															width={48}
															height={48}
														/>
													)}
												/>
											)}
										/>
									</>
								)}
							/>
						</Row>
					</Col>
					<Col
						lg={isBuyNowPayLaterInCart || isBNPLOrderHistory ? 11 : 5}
						md={isBuyNowPayLaterInCart || isBNPLOrderHistory ? 11 : 5}
						sm={10}
						xs={10}
						xl={isBNPLOrderHistory ? 11 : isBuyNowPayLaterInCart ? 4 : 5}
					>
						<TitleContainer>
							<NameWrapper>
								<ConditionalRender
									show={
										!buyCarInsurance && !!selectedPlan && (planUpgrade || planRenewal || buyHBBWFBB)
									}
									onTrue={() => (
										<ItemDescriptionWrapper>
											<PlanTitleWrapper className="PlanTitleWrapper">
												<ConditionalRender
													show={!!oldPlan?.name}
													onTrue={() => (
														<>
															<ConditionalRender
																show={planUpgrade}
																onTrue={() => (
																	<>
																		<TitleWrapper>
																			<ItemTitle textCut>
																				{oldPlan?.name}
																			</ItemTitle>
																		</TitleWrapper>
																		<IconWrapper>
																			<Icon
																				name="arrowSwipe"
																				width={16}
																				height={16}
																				fill="black87"
																			/>
																		</IconWrapper>
																	</>
																)}
															/>
														</>
													)}
												/>

												<TitleWrapper>
													<ItemTitle textCut>{selectedPlan?.planName}</ItemTitle>
												</TitleWrapper>
											</PlanTitleWrapper>
											<ConditionalRender
												show={!!specificAccountNumber}
												onTrue={() => <DetailsWrapper>{specificAccountNumber}</DetailsWrapper>}
											/>
											<ItemDescription>
												{planContractDuration
													? buyHBBWFBB
														? translate(
																'plan.contract.duration',
																planContractDuration ?? '-'
														  )
														: translate(
																`product.cart.plan.duration.${
																	planUpgrade ? 'upgrade' : 'renewal'
																}`,
																planContractDuration ?? '-'
														  )
													: buyHBBWFBB
													? ''
													: translate('product.cart.no.commitment')}
											</ItemDescription>
										</ItemDescriptionWrapper>
									)}
									onFalse={() => (
										<>
											{!isPrepaid && !buyCarInsurance && manufacturer}{' '}
											{!isPrepaid && !buyCarInsurance && name}
										</>
									)}
								/>
								<ConditionalRender
									show={buyCarInsurance}
									onTrue={() => <>{'requestType' in cartItem ? cartItem.plan?.name : ''}</>}
								/>
								<ConditionalRender
									show={!!isPrepaid}
									onTrue={() => <>{'requestType' in cartItem ? cartItem.plan?.planName : ''}</>}
								/>
							</NameWrapper>
							<ConditionalRender
								show={!withoutQuantityButtons || (screen(['xs', 'sm']) && !summaryView && !addToCart)}
								onTrue={() => (
									<Visible xs sm>
										<Icon
											name="cancel"
											width={REMOVE_ICON_SIZE}
											height={REMOVE_ICON_SIZE}
											fill="black87"
											onClick={removeItem}
										/>
									</Visible>
								)}
							/>
						</TitleContainer>
						<DetailsContainer displayColumn={addToCart || screen(['xs', 'sm'])}>
							<ConditionalRender
								show={!specificAccountNumber}
								onTrue={() => (
									<>
										<ConditionalRender
											show={!!color}
											onTrue={() => (
												<DetailsWrapper>
													{translate('cart-item.color', color ?? '')}
												</DetailsWrapper>
											)}
										/>
										<ConditionalRender
											show={!!capacity}
											onTrue={() => (
												<>
													{!addToCart && (
														<Visible xl lg md>
															<VerticalDivider />
														</Visible>
													)}
													<DetailsWrapper>
														{translate(
															product?.manufacturer?.includes('Apple Watch')
																? 'cart-item.apple-watch.case-color'
																: 'cart-item.storage',
															capacity ?? ''
														)}
													</DetailsWrapper>
												</>
											)}
										/>
									</>
								)}
							/>

							<ConditionalRender
								show={buyCarInsurance}
								onTrue={() => (
									<CarInsuranceDetailsWraper>
										<CarInsuranceDetails>
											{'manufacturer' in cartItem && cartItem.manufacturer}
											{',\xa0'}
											{'model' in cartItem && cartItem.model}
											{'\xa0\xa0|\xa0\xa0'}
										</CarInsuranceDetails>
										<DetailsWrapper>
											{(cartItem.type as string) === INSURANCE_TYPE.FULL_COMPREHENSIVE
												? translate('insurance.type.choice1')
												: translate('insurance.type.choice2')}
											, {'features' in cartItem && cartItem.features}
										</DetailsWrapper>
									</CarInsuranceDetailsWraper>
								)}
							/>
							<ConditionalRender
								show={!!isPrepaid}
								onTrue={() => (
									<BuyPrepaidDetailsWraper smallScreen={screen(['xs', 'sm'])}>
										<BuyPrepaidDetails>
											{translate('product.cart-card.buySim.SimIncluded')}
										</BuyPrepaidDetails>
										<ConditionalRender
											show={!screenClass.includes('sm') && !screen(['xs', 'sm'])}
											onTrue={() => <DetailsWrapper>{'\xa0\xa0|\xa0\xa0'}</DetailsWrapper>}
										/>
										<DetailsWrapper>
											{getLang() === 'ar'
												? `${cartItem.plan?.durationLabel}\xa0${translate(
														'product.cart-card.buySim.plan-valid'
												  )}`
												: `${translate('product.cart-card.buySim.plan-valid')}\xa0${
														cartItem.plan?.durationLabel
												  }`}
										</DetailsWrapper>
									</BuyPrepaidDetailsWraper>
								)}
							/>
						</DetailsContainer>
						<ConditionalRender
							show={!!product?.isGiftCard}
							onTrue={() => (
								<>
									<ConditionalRender
										show={!!product?.giftCardValue}
										onTrue={() => (
											<DetailsWrapper>
												{translate('giftcard-price', product?.giftCardValue ?? '')}
											</DetailsWrapper>
										)}
									/>
									<ConditionalRender
										show={!!product?.country}
										onTrue={() => (
											<DetailsWrapper>
												{translate('giftcard-country', product?.country ?? '')}
											</DetailsWrapper>
										)}
									/>
								</>
							)}
						/>
						<ConditionalRender
							show={buyCarInsurance}
							onTrue={() => <DetailsWrapper>{translate('product.cart-card.upfront')}</DetailsWrapper>}
						/>
						<ConditionalRender
							show={!!isPrepaid}
							onTrue={() => (
								<DetailsWrapper>{translate('product.cart-card.upfront-payment')}</DetailsWrapper>
							)}
						/>
						<ConditionalRender
							show={
								!!thumbnailUrl &&
								!buyCarInsurance &&
								!product?.isDigitalProduct &&
								!screen(['xs', 'sm'])
							}
							onTrue={() => (
								<DetailsWrapper>
									{installmentPeriod
										? translate(
												product?.partNumber.includes(JAWWY_PRODUCT_PARTNUMBER_ID_PART)
													? 'payment-summary.subscription-device'
													: 'payment-summary.installments-device',
												installmentPeriod
										  )
										: translate('product.cart-card.upfront')}
								</DetailsWrapper>
							)}
							onFalse={() => (
								<ConditionalRender
									show={!!product?.giftCardValue}
									onTrue={() => (
										<DetailsWrapper>
											{translate(
												'cart-item.subscription',
												product?.giftCardValue?.toLowerCase() ?? ''
											)}
										</DetailsWrapper>
									)}
								/>
							)}
						/>
						<ConditionalRender
							show={isVoucherPlanAction(cartItem) && !!cartItem?.mobilePhone}
							onTrue={() => <DetailsWrapper>{cartItem?.mobilePhone}</DetailsWrapper>}
						/>
					</Col>
					<Col
						xs={10}
						sm={10}
						md={isBuyNowPayLaterInCart || isBNPLOrderHistory ? 11 : 3}
						lg={isBuyNowPayLaterInCart || isBNPLOrderHistory ? 11 : 3}
						xl={isBNPLOrderHistory ? 11 : isBuyNowPayLaterInCart ? 2 : 3}
						offset={{
							xs: 2,
							sm: 2,
							md: isBuyNowPayLaterInCart || isBNPLOrderHistory ? 1 : 0,
							lg: isBuyNowPayLaterInCart || isBNPLOrderHistory ? 1 : 0,
							xl: isBNPLOrderHistory ? 1 : 0,
						}}
						style={
							isBuyNowPayLaterInCart && !isBNPLOrderHistory && screen('xl') ? { marginLeft: 'auto' } : {}
						}
					>
						<PriceRow>
							<CaptionWrapper>
								<BodyMBold>
									{screen(
										isBNPLOrderHistory
											? ['xs', 'sm', 'lg', 'md', 'xl']
											: isBuyNowPayLaterInCart
											? ['xs', 'sm', 'lg', 'md']
											: ['xs', 'sm']
									) &&
										!addToCart &&
										(!!isPrepaid || !installmentValue
											? translate('payment.summary.price')
											: translate('payment.summary.upfront'))}
								</BodyMBold>
							</CaptionWrapper>
							<PriceContainer isSmallScreen={screen(['xs', 'sm'])}>
								<ConditionalRender
									show={tableMode}
									onTrue={() => (
										<ConditionalRender
											show={!!price}
											onTrue={() => (
												<VatWrapper>
													<PriceWrapper
														currencySideLeft={true}
														color={
															priceColor ?? (devicePriceDiscount ? 'primary' : 'black87')
														}
														price={
															(isBuyNowPayLaterInCart || isBNPLOrderHistory) &&
															bnplUpfrontMinusVat
																? bnplUpfrontMinusVat
																: isEbuCart
																? cartItem?.taxablePrice || 0
																: isGreenRatedUserPayment
																? 0
																: price ?? 0 - (vat ?? 0) // *** cart 0 payment change
														}
														size={
															isEbuCart
																? 'medium'
																: screen(['xs', 'sm'])
																? 'miniregularbold'
																: 'small'
														}
														isPrepaid={!!isPrepaid}
													/>
													<ConditionalRender
														show={isEbuCart}
														onTrue={() => (
															<VatPriceAndPercentage>
																<Info color="black54">
																	{translate(
																		'product.cart-card.with-vat',
																		vatPercent ?? 0
																	)}
																</Info>
																<InfoBold color="black54">
																	{isBuyNowPayLaterInCart && bnplUpfrontMinusVat
																		? `${translate('omr')} ${roundMoney(
																				bnplUpfrontAmount
																		  )}`
																		: `${translate('omr')} ${roundMoney(
																				(vat ?? 0) + (price ?? 0)
																		  )}`}
																</InfoBold>
															</VatPriceAndPercentage>
														)}
														onFalse={() =>
															!isGreenRatedUserPayment && (
																<VatPriceAndPercentage>
																	<Info color="black54">
																		{translate(
																			'product.cart-card.with-vat',
																			vatPercent ?? 0
																		)}
																	</Info>
																	<InfoBold color="black54">
																		{isBuyNowPayLaterInCart && bnplUpfrontMinusVat
																			? `${translate('omr')} ${roundMoney(
																					bnplUpfrontAmount
																			  )}`
																			: `${translate('omr')} ${roundMoney(
																					(vat ?? 0) + (price ?? 0)
																			  )}`}
																	</InfoBold>
																</VatPriceAndPercentage>
															)
														}
													/>
												</VatWrapper>
											)}
											onFalse={() => (
												<ConditionalRender
													show={!!installmentValue}
													onTrue={() => (
														<VatWrapper>
															<PriceWrapper
																color={
																	priceColor ??
																	(devicePriceDiscount ? 'primary' : 'black87')
																}
																price={price ?? 0 - (vat ?? 0)}
																size={
																	screen(['xs', 'sm']) ? 'miniregularbold' : 'small'
																}
															/>
															<ConditionalRender
																show={!!vat && !isGreenRatedUserPayment}
																onTrue={() => (
																	<VatPriceAndPercentage>
																		<Info color="black54">
																			{translate(
																				'product.cart-card.with-vat',
																				vatPercent ?? 0
																			)}
																		</Info>
																		<InfoBold color="black54">
																			{`${translate('omr')} ${roundMoney(
																				(vat ?? 0) + (price ?? 0)
																			)}`}
																		</InfoBold>
																	</VatPriceAndPercentage>
																)}
															/>
														</VatWrapper>
													)}
													onFalse={() => (
														<VatWrapper>
															<EmptyLine />
														</VatWrapper>
													)}
												/>
											)}
										/>
									)}
								/>
							</PriceContainer>
						</PriceRow>
					</Col>
					<Hidden md lg xl xxl>
						<Col offset={{ xs: 2, sm: 2 }}>
							{type === CartItemType.DEFAULT ? (
								<ConditionalRender
									show={
										!withPlan &&
										!withoutQuantityButtons &&
										!buyCarInsurance &&
										!isVoucherPlanAction(cartItem) &&
										!isBuyNowPayLaterInCart &&
										!isBNPLOrderHistory &&
										!cartItem.installmentDuration
									}
									onTrue={() => (
										<QuantityWrapper>
											<QuantityComponent
												productId={product?.id}
												numberOfItems={numberOfItems}
												orderItemId={orderItemId}
											/>
										</QuantityWrapper>
									)}
									onFalse={() => (
										<ConditionalRender
											show={!addToCart && numberOfItems > 1 && !buyCarInsurance}
											onTrue={() => (
												<BodyS color="black54">
													{translate('cart.items.quantity', numberOfItems)}
												</BodyS>
											)}
										/>
									)}
								/>
							) : (
								<>
									<ConditionalRender
										show={!buyCarInsurance}
										onTrue={() => (
											<Row>
												<Col>
													<Divider marginBottom={8} withoutLine />
													<BodyS color="black54">
														{translate('cart.items.quantity', numberOfItems)}
													</BodyS>
												</Col>
											</Row>
										)}
									/>

									<ConditionalRender
										show={!!product?.isGiftCard}
										onTrue={() => (
											<Row>
												<Col>
													<Divider marginBottom={8} withoutLine />
													<SeeCode onClick={() => seeCode()}>
														{translate('gift.card.see.code')}
													</SeeCode>
													<Divider marginBottom={8} withoutLine />
												</Col>
											</Row>
										)}
									/>
									<ConditionalRender
										show={!!product?.manufacturer}
										onTrue={() => (
											<ConditionalRender
												show={
													product?.manufacturer.toLowerCase() === MICROSOFT &&
													!isBusinessMicrosoftProduct(product?.name)
												}
												onTrue={() => (
													<Row>
														<Col>
															<SeeCode
																onClick={() =>
																	dispatch(
																		setModal({
																			type: modalTypes.MICROSOFT_CARD_DETAILS,
																			data: { product, email, orderItemId },
																			withoutContainer: true,
																		})
																	)
																}
															>
																{translate('gift.card.see.code')}
															</SeeCode>
														</Col>
													</Row>
												)}
											/>
										)}
									/>
								</>
							)}
						</Col>
					</Hidden>
					<ConditionalRender
						show={tableMode && (isBuyNowPayLaterInCart || isBNPLOrderHistory)}
						onTrue={() => (
							<Col
								lg={isBuyNowPayLaterInCart || isBNPLOrderHistory ? 11 : 2}
								md={isBuyNowPayLaterInCart || isBNPLOrderHistory ? 11 : 2}
								sm={10}
								xs={10}
								xl={isBNPLOrderHistory ? 11 : 2}
								offset={{
									xs: 2,
									sm: 2,
									xl: isBNPLOrderHistory ? 1 : 0,
									lg: isBuyNowPayLaterInCart || isBNPLOrderHistory ? 1 : 0,
									md: isBuyNowPayLaterInCart || isBNPLOrderHistory ? 1 : 0,
								}}
							>
								<PriceRow>
									<CaptionWrapper>
										<BodyMBold>
											{screen(
												isBNPLOrderHistory
													? ['xs', 'sm', 'lg', 'md', 'xl']
													: isBuyNowPayLaterInCart
													? ['xs', 'sm', 'lg', 'md']
													: ['xs', 'sm']
											) &&
												!addToCart &&
												(!!isPrepaid || !installmentValue
													? translate('payment.summary.price')
													: translate(
															isBuyNowPayLaterInCart || isBNPLOrderHistory
																? cartItem?.plan?.installmentDuration === '12' ||
																  (isBNPLOrderHistory
																		? cartItem.plan?.installmentDuration === '12'
																		: false)
																	? 'cart.items.11-month'
																	: 'cart.items.23-month'
																: 'payment.summary.upfront'
													  ))}
										</BodyMBold>
									</CaptionWrapper>
									<PriceContainer isSmallScreen={screen(['xs', 'sm'])}>
										<ConditionalRender
											show={tableMode}
											onTrue={() => (
												<VatWrapper>
													<PriceWrapper
														currencySideLeft={true}
														color={
															priceColor ?? (devicePriceDiscount ? 'primary' : 'black87')
														}
														price={cartItem?.plan?.IV}
														size={
															isEbuCart
																? 'medium'
																: screen(['xs', 'sm'])
																? 'miniregularbold'
																: 'small'
														}
														isPrepaid={!!isPrepaid}
														duration={
															isBuyNowPayLaterInCart || isBNPLOrderHistory
																? translate('select-protection-modal.payment.mo')
																: ''
														}
													/>
												</VatWrapper>
											)}
										/>
									</PriceContainer>
								</PriceRow>
							</Col>
						)}
					/>
					<Col
						lg={isBuyNowPayLaterInCart || isBNPLOrderHistory ? 11 : 2}
						md={isBuyNowPayLaterInCart || isBNPLOrderHistory ? 11 : 2}
						sm={10}
						xs={10}
						xl={isBNPLOrderHistory ? 11 : 2}
						offset={{
							xs: 2,
							sm: 2,
							xl: isBNPLOrderHistory ? 1 : 0,
							lg: isBuyNowPayLaterInCart || isBNPLOrderHistory ? 1 : 0,
							md: isBuyNowPayLaterInCart || isBNPLOrderHistory ? 1 : 0,
						}}
					>
						<ConditionalRender
							show={tableMode && (isBuyNowPayLaterInCart || isBNPLOrderHistory)}
							onTrue={() => (
								<PriceRow>
									<CaptionWrapper>
										<BodyMBold>
											{screen(
												isBNPLOrderHistory
													? ['xs', 'sm', 'lg', 'md', 'xl']
													: isBuyNowPayLaterInCart
													? ['xs', 'sm', 'lg', 'md']
													: ['xs', 'sm']
											) &&
												!addToCart &&
												(!!isPrepaid || !installmentValue
													? translate('payment.summary.price')
													: translate(
															isBuyNowPayLaterInCart || isBNPLOrderHistory
																? 'cart.items.last-month'
																: 'payment.summary.upfront'
													  ))}
										</BodyMBold>
									</CaptionWrapper>
									<PriceContainer isSmallScreen={screen(['xs', 'sm'])}>
										<ConditionalRender
											show={tableMode}
											onTrue={() => (
												<VatWrapper>
													<PriceWrapper
														currencySideLeft={true}
														color={
															priceColor ?? (devicePriceDiscount ? 'primary' : 'black87')
														}
														price={
															isBuyNowPayLaterInCart || isBNPLOrderHistory
																? cartItem?.plan?.BNPLFEP
																: isEbuCart
																? cartItem?.taxablePrice || 0
																: price ?? 0 - (vat ?? 0)
														}
														size={
															isEbuCart
																? 'medium'
																: screen(['xs', 'sm'])
																? 'miniregularbold'
																: 'small'
														}
														isPrepaid={!!isPrepaid}
														duration={translate('select-protection-modal.payment.mo')}
													/>
												</VatWrapper>
											)}
										/>
									</PriceContainer>
								</PriceRow>
							)}
						/>
						<ConditionalRender
							show={tableMode && !isBuyNowPayLaterInCart && !isBNPLOrderHistory}
							onTrue={() => (
								<PriceRow>
									<CaptionWrapper>
										<BodyMBold>
											{screen(['xs', 'sm']) &&
												(!!installmentValue || !!selectedPlan?.priceMonthly) &&
												!isPrepaid &&
												(!!selectedPlan?.priceMonthly
													? translate('payment.summary.subscription')
													: isGreenRatedUserPayment
													? translate('payment.summary.installments.1st.bill.amount')
													: translate('payment.summary.description.installments'))}
										</BodyMBold>
										<ConditionalRender
											show={isGreenRatedUserPayment}
											onTrue={() => <Divider withoutLine marginBottom={12} />}
											onFalse={() => (
												<CaptionBigger color="black54">
													{screen(['xs', 'sm']) &&
														(installmentPeriod || selectedPlan?.planContractDuration) &&
														translate(
															'payment.summary.installments.period',
															!!selectedPlan?.priceMonthly
																? selectedPlan?.planContractDuration ?? ''
																: installmentPeriod ?? ''
														)}
												</CaptionBigger>
											)}
										/>
									</CaptionWrapper>
									<PriceContainer isSmallScreen={screen(['xs', 'sm'])}>
										<ConditionalRender
											show={!!selectedPlan?.priceMonthly}
											onTrue={() => (
												<VatWrapper>
													<PriceWrapper
														price={
															(Number(selectedPlan?.planTaxablePrice) ?? 0) *
															numberOfItems
														}
														size={screen(['xs', 'sm']) ? 'miniregularbold' : 'small'}
													/>
													<ConditionalRender
														show={!!vat}
														onTrue={() => (
															<VatPriceAndPercentage>
																<Info color="black54">
																	{translate(
																		'product.cart-card.with-vat',
																		selectedPlan?.planVatPercent ?? 0
																	)}
																</Info>
																<InfoBold color="black54">
																	{`OMR ${roundMoney(
																		(Number(selectedPlan?.planVatValue) ?? 0) +
																			(Number(selectedPlan?.planTaxablePrice) ??
																				0) *
																				numberOfItems
																	)}`}
																</InfoBold>
															</VatPriceAndPercentage>
														)}
													/>
												</VatWrapper>
											)}
											onFalse={() => (
												<ConditionalRender
													show={!!installmentValue}
													onTrue={() => (
														<VatWrapper>
															<PriceWrapper
																currencySideLeft={true}
																price={
																	isEbuCart
																		? (Number(cartItem?.standardPrice) || 0) /
																		  (Number(cartItem?.installmentDuration) || 0)
																		: Number(installmentValue || 0) * numberOfItems
																}
																size={
																	isEbuCart
																		? 'medium'
																		: screen(['xs', 'sm'])
																		? 'miniregularbold'
																		: 'small'
																}
																duration={translate('plan-bar.mo')}
																discountColor="black54"
																discount={
																	isEbuCart
																		? (Number(cartItem?.standardPrice) || 0) /
																		  (Number(cartItem?.installmentDuration) || 0)
																		: 0
																}
															/>
															<ConditionalRender
																show={isEbuCart}
																onTrue={() => (
																	<VatPriceAndPercentage>
																		<Info color="primary">
																			{translate(
																				'product.cart-card.after-discount'
																			)}
																		</Info>
																		<InfoBold color="primary">
																			{`${translate('omr')} ${roundMoney(
																				Number(cartItem?.IV) ?? 0
																			)}`}
																		</InfoBold>
																	</VatPriceAndPercentage>
																)}
															/>
															<ConditionalRender
																show={isEbuCart}
																onTrue={() => (
																	<VatPriceAndPercentage>
																		<Info color="primary">
																			{translate(
																				'product.cart-card.after-discount'
																			)}
																		</Info>
																		<InfoBold color="primary">
																			{`${translate('omr')} ${roundMoney(
																				Number(cartItem?.IV) ?? 0
																			)}`}
																		</InfoBold>
																	</VatPriceAndPercentage>
																)}
															/>
														</VatWrapper>
													)}
													onFalse={() => (
														<ConditionalRender
															show={
																(!isPrepaid && !screen(['xs', 'sm'])) ||
																!screen(['xs', 'sm']) ||
																(!screen(['xs', 'sm']) &&
																	!isPrepaid &&
																	!installmentValue)
															}
															onTrue={() => (
																<VatWrapper>
																	<EmptyLine />
																</VatWrapper>
															)}
														/>
													)}
												/>
											)}
										/>
									</PriceContainer>
								</PriceRow>
							)}
						/>
						<ConditionalRender
							show={screen(['xs', 'sm']) && !!isPrepaid}
							onTrue={() => (
								<OpenPlanDetailsPrepaidWrapper onClick={openPlanDetails}>
									{translate(
										showPlanDetailsAndRemoveButton
											? 'cart.items.plan.details-see'
											: 'cart.item.plan.details'
									)}
								</OpenPlanDetailsPrepaidWrapper>
							)}
						/>
					</Col>
				</Row>
				<ConditionalRender
					show={!price && !!installmentValue && !addToCart && !isGreenRatedUserPayment}
					onTrue={() => (
						<Col xs={12} sm={12} offset={{ xs: 0, sm: 0, md: 1, lg: 1 }} style={{ marginTop: 16 }}>
							<Row style={{ marginRight: screen(['md']) ? 24 : screen(['xl', 'lg']) ? 32 : -32 }}>
								<VatMessageBar vat={5} />
							</Row>
						</Col>
					)}
				/>

				<ConditionalRender
					show={!!installmentValue && !addToCart && isGreenRatedUserPayment}
					onTrue={() => (
						<Col xs={12} sm={12} offset={{ xs: 0, sm: 0, md: 1, lg: 1 }} style={{ marginTop: 16 }}>
							<Row style={{ marginRight: screen(['md']) ? 24 : screen(['xl', 'lg']) ? 32 : -32 }}>
								<VatMessageBar isGreenPaymentUser={isGreenRatedUserPayment} vat={5} />
							</Row>
						</Col>
					)}
				/>
			</>
		);
	};

	return (
		<CartItemContainer>
			<ConditionalRender
				show={plan?.PA !== BUY_HBB && plan?.PA !== BUY_WFBB}
				onTrue={() => (
					<CartRow
						installmentValue={plan?.IV}
						devicePriceDiscount={!!deviceDiscountPrice}
						price={
							deviceDiscountPrice ||
							(isVoucherPlanAction(cartItem) || isVoucherPlanAction(cartItem?.plan)
								? Number(cartItem?.price ?? 0)
								: Number(cartItem?.price ?? 0) - (vat ?? 0)) ||
							0
						}
						bnplUpfrontAmount={Number(cartItem?.plan?.BNPL_UPFRONT_AMOUNT)}
						bnplUpfrontMinusVat={Number(cartItem?.plan?.BNPL_UPFRONT_MINUS_VAT)}
						product={product}
						installmentPeriod={cartItem.plan?.IP}
					/>
				)}
			/>

			<ConditionalRender
				show={!!cartItem.plan?.preorderDate && !disablePreorder && !!withPlan}
				onTrue={() => (
					<AccordionsContainer>
						<Accordion
							title={translate('cart.items.accordion.title')}
							titleColor="support2"
							subtitle={
								isSoonPeorderDate(cartItem.plan?.preorderDate ?? '')
									? translate('availability.preorder.subtitle.second-version')
									: translate('cart.items.subtitle.accordion', cartItem.plan?.preorderDate || '-')
							}
							subtitleColor="black54"
							smallSubtitle
							enableSubtitleOnExpand
							icon={getLang() === 'ar' ? 'preorder_ar' : 'preorder_en'}
							iconColor="support2"
							constantMargin
							accordionHeight={screen(['xs', 'sm']) ? 120 : 60}
						>
							<AccordionContentWrapper>
								<SectionHeader>{translate('cart.items.section.header')}</SectionHeader>
								<SectionContent>{translate('cart.items.section.content')}</SectionContent>
							</AccordionContentWrapper>
						</Accordion>
					</AccordionsContainer>
				)}
			/>
			<ConditionalRender
				show={
					!!withPlan &&
					cartItem?.plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS &&
					cartItem?.plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS_HBB &&
					cartItem?.plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS_WFBB &&
					cartItem?.plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS &&
					cartItem?.plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS_HBB &&
					cartItem?.plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS_WFBB &&
					cartItem?.plan?.PA !== ItemType.B2B2C_ITEM_ACCESSORY_BAQATI &&
					cartItem?.plan?.PA !== ItemType.B2B2C_TABLETS_LAPTOPS_BAQATI &&
					cartItem?.plan?.PA !== ItemType.B2B_ITEM_ACCESSORY_BAQATI &&
					cartItem?.plan?.PA !== ItemType.B2B_TABLETS_LAPTOPS_BAQATI &&
					!isPrepaid
				}
				onTrue={() => (
					<>
						<Divider marginTop={16} withoutLine />
						<CartRow
							selectedPlan={plan}
							oldPlan={planOfSpecificAccount}
							specificAccountNumber={cartItemWithPlan?.plan?.mobilePhone}
						/>
					</>
				)}
			/>
			<ConditionalRender
				show={!!cartItem.plan?.preorderDate && !!withPlan && !disablePreorder}
				onTrue={() => (
					<PlanInfo>
						<Icon name="warning" width={24} height={24} fill="primary" />
						<PlanInfoContent>{translate('cart.item.preorder.plan.info')}</PlanInfoContent>
					</PlanInfo>
				)}
			/>
			<ConditionalRender show={false} onTrue={() => <></>} />
			<Hidden xs sm>
				<Row>
					<Col offset={{ lg: 1, md: 1, xl: 1, sm: 2, xs: 2 }}>
						{type === CartItemType.DEFAULT ? (
							<ConditionalRender
								show={
									!withPlan &&
									!withoutQuantityButtons &&
									!isVoucherPlanAction(cartItem) &&
									!isBuyNowPayLaterInCart &&
									!isBNPLOrderHistory &&
									!cartItem.installmentDuration
								}
								onTrue={() => (
									<QuantityWrapper>
										<QuantityComponent
											productId={product?.id}
											numberOfItems={numberOfItems}
											orderItemId={orderItemId}
										/>
									</QuantityWrapper>
								)}
								onFalse={() => (
									<ConditionalRender
										show={!addToCart && numberOfItems > 1}
										onTrue={() => (
											<BodyS color="black54">
												{translate('cart.items.quantity', numberOfItems)}
											</BodyS>
										)}
									/>
								)}
							/>
						) : (
							<>
								<ConditionalRender
									show={!buyCarInsurance}
									onTrue={() => (
										<Row>
											<QtyWrapper>
												<BodyS color="black54">
													{translate('cart.items.quantity', numberOfItems)}
												</BodyS>
											</QtyWrapper>
										</Row>
									)}
								/>

								<ConditionalRender
									show={!!product?.isGiftCard}
									onTrue={() => (
										<Row>
											<Col>
												<SeeCode onClick={() => seeCode()}>
													{translate('gift.card.see.code')}
												</SeeCode>
											</Col>
										</Row>
									)}
								/>
								<ConditionalRender
									show={!!product?.manufacturer}
									onTrue={() => (
										<ConditionalRender
											show={
												product?.manufacturer.toLowerCase() === MICROSOFT &&
												!isBusinessMicrosoftProduct(product?.name)
											}
											onTrue={() => (
												<Row>
													<Col>
														<SeeCode
															onClick={() =>
																dispatch(
																	setModal({
																		type: modalTypes.MICROSOFT_CARD_DETAILS,
																		data: { product, email, orderItemId },
																		withoutContainer: true,
																	})
																)
															}
														>
															{translate('gift.card.see.code')}
														</SeeCode>
													</Col>
												</Row>
											)}
										/>
									)}
								/>
							</>
						)}
						<ButtonWrapper>
							<ConditionalRender
								show={
									(!!withPlan &&
										!addToCart &&
										cartItem?.plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS &&
										cartItem?.plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS_HBB &&
										cartItem?.plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS_WFBB &&
										cartItem?.plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS &&
										cartItem?.plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS_HBB &&
										cartItem?.plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS_WFBB &&
										cartItem?.plan?.PA !== ItemType.B2B2C_ITEM_ACCESSORY_BAQATI &&
										cartItem?.plan?.PA !== ItemType.B2B2C_TABLETS_LAPTOPS_BAQATI &&
										cartItem?.plan?.PA !== ItemType.B2B_ITEM_ACCESSORY_BAQATI &&
										cartItem?.plan?.PA !== ItemType.B2B_TABLETS_LAPTOPS_BAQATI) ||
									!!showPlanDetailsAndRemoveButton
								}
								onTrue={() => (
									<OpenPlanDetailsWrapper onClick={openPlanDetails}>
										{translate(
											showPlanDetailsAndRemoveButton
												? 'cart.items.plan.details-see'
												: 'cart.item.plan.details'
										)}
									</OpenPlanDetailsWrapper>
								)}
							/>
							<ConditionalRender
								show={
									((type === CartItemType.DEFAULT && !withoutQuantityButtons) ||
										!!showPlanDetailsAndRemoveButton ||
										(!!cartItem?.requestType &&
											INSURANCE_REQUEST_TYPE.includes(cartItem?.requestType))) &&
									!(
										!DISABLE_WIFI_FREE_DEVICE &&
										(cartItem?.freeGift || Boolean(cartItem?.freeProduct))
									)
								}
								onTrue={() => (
									<RemoveItemWrapper onClick={removeItem}>
										{translate('cart.item.remove')}
									</RemoveItemWrapper>
								)}
							/>
						</ButtonWrapper>

						<ConditionalRender
							show={!!product?.isDigitalProduct && deviceInCart}
							onTrue={() => (
								<Row>
									<MessageBarCol>
										<MessageBar icon="error" message={translate('error.mixed-shipping')} />
									</MessageBarCol>
								</Row>
							)}
						/>
						<ConditionalRender
							show={
								isMicrosoftAutomationProcess &&
								(isCartView || isCheckoutProcess) &&
								!!product?.manufacturer.toUpperCase().includes(MICROSOFT.toUpperCase())
							}
							onTrue={() => (
								<Row>
									<MessageBarCol>
										<MessageBar
											icon="info"
											backgroundColor="black6"
											message={translate(
												isCheckoutProcess
													? 'microsoft-automation-proccess.checkout-items-info'
													: 'microsoft-automation-proccess.cart-items-info'
											)}
											contentFill="black54"
											importantMessage={
												isCheckoutProcess
													? translate(
															'microsoft-automation-proccess.checkout-items-info.extended'
													  )
													: ''
											}
											importantMessageFirst={true}
											textAlign="left"
										/>
									</MessageBarCol>
								</Row>
							)}
						/>
					</Col>
				</Row>
			</Hidden>
			<ConditionalRender
				show={!!cartItem.plan?.preorderDate && !disablePreorder && !withPlan}
				onTrue={() => (
					<AccordionsContainer>
						<Accordion
							title={translate('cart.items.accordion.title')}
							titleColor="support2"
							subtitle={
								isSoonPeorderDate(cartItem.plan?.preorderDate ?? '')
									? translate('availability.preorder.subtitle.second-version')
									: translate('cart.items.subtitle.accordion', cartItem.plan?.preorderDate || '-')
							}
							subtitleColor="black54"
							smallSubtitle
							enableSubtitleOnExpand
							icon={getLang() === 'ar' ? 'preorder_ar' : 'preorder_en'}
							iconColor="support2"
							constantMargin
							accordionHeight={screen(['xs', 'sm']) ? 120 : 60}
						>
							<AccordionContentWrapper>
								<SectionHeader>{translate('cart.items.section.header')}</SectionHeader>
								<SectionContent>{translate('cart.items.section.content')}</SectionContent>
							</AccordionContentWrapper>
						</Accordion>
					</AccordionsContainer>
				)}
			/>
			<ConditionalRender
				show={!!product?.esimCapable && !addToCart && isUserSignedIn()}
				onTrue={() => (
					<AccordionsContainer>
						<GetESIM
							orderItemId={cartItem.orderItemId}
							isOrderHistoryView={isOrderHistoryView}
							summaryView={summaryView}
							cartItem={cartItem}
						/>
					</AccordionsContainer>
				)}
			/>
			<ConditionalRender
				show={!!product?.additionalService && !addToCart && isUserSignedIn() && !disableInsurance}
				onTrue={() => (
					<AccordionsContainer>
						<GetInsurance
							insurancePromotion={insurancePromotion}
							isOrderHistoryView={isOrderHistoryView}
							partNumber={product?.additionalService || ''}
							hideButtons={summaryView || isOrderHistoryView}
							purchasedInsurance={purchasedInsurance}
							summaryView={summaryView}
							installmentType={cartItem.plan?.IP}
						/>
					</AccordionsContainer>
				)}
			/>
			<ConditionalRender
				show={!isLast}
				onTrue={() => (
					<Row>
						<Divider marginTop={24} marginBottom={16} />
					</Row>
				)}
			/>
		</CartItemContainer>
	);
};

export default CartItem;
