import { IViewCart } from '@ApiModels/viewCart';
import CartFlyoutItem from '@Components/CartFyloutItem/CartFlyoutItem';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PrimaryLargeButtonFlex, TertiaryMediumButtonFlex } from '@Components/controls/Button/Button';
import Divider from '@Components/Divider/Divider';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import useTranslate from '@Utils/hooks/useTranslate';
import { getLang } from '@Utils/language/language';
import React, { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { roundMoney } from '@Utils/converters/roundMoney';
import useScreen from '@Utils/hooks/useScreen';
import {
	ButtonWrapper,
	CartFlyoutContainer,
	ContentWrapper,
	CostTitle,
	CostWrapper,
	EmptyStateDescription,
	EmptyStateWrapper,
	HeaderWrapper,
	ItemsList,
	SummaryWrapper,
	Vat,
	VatWrapper,
	ShoppingBagImageWrapper,
	ShoppingBagShadowWrapper,
	HeaderButtonWrapper,
	InnerButtonWrapper,
	IconWrapper,
} from './CartFlyout.styled';
import { ItemType } from '@Services/productsService/productsService';
import Image from '@Components/Image/Image';
import { BodyL, DigitXsSm, ParagraphM } from '@Components/Typography/Typography.styled';
import Icon from '@Components/Icon/Icon';
import { MAKASIB_POINTS_PRODUCT_ID, MAKASIB_VOUCHER_PRODUCT_ID } from '@Config/app.config';
import { MakasibService } from '@Services/makasib/makasib';
import { useBuyNowPayLater } from '@Utils/context/BuyNowPayLaterContext';

interface ICartFlyoutProps {
	setOpen: (open: boolean) => void;
	open: boolean;
	cart: IViewCart | null;
}

const ICON_SIZE = 8.77;

export interface IScrollStateProps {
	bottom: boolean;
	top: boolean;
}

const CLEAR_ALL_CART_BI = 'CLEAR_ALL_CART_BI';
const BIG_CART_HEIGHT = 607;
const SMALL_CART_HEIGHT = 469;
const EMPTY_CART_HEIGHT = 200;

const CartFlyout: FunctionComponent<ICartFlyoutProps> = ({ setOpen, open, cart }) => {
	const [scrollState, setScrollState] = useState<IScrollStateProps>({ bottom: false, top: true });
	const [cartFlyoutOpen, setCartFlyoutOpen] = useState<boolean>(open);
	const { translate } = useTranslate();
	const history = useHistory();
	const dispatch = useDispatch();
	const cartUpdated = useSelector((state: types.redux.IState) => state.cart.cartUpdated);
	const isLogged = !!useSelector((state: types.redux.IState) => state.api.authentication.signedIn);
	const isTelemarketer = useSelector((state: types.redux.IState) => state.api.authentication.telemarketer);
	const { screen } = useScreen();
	const { isBuyNowPayLaterInCart } = useBuyNowPayLater();

	const scrollRef = useRef<HTMLDivElement>(null);
	const [flyoutAlignment, setFlyoutAlignment] = useState<number>(0);
	const [totalWithVat, setTotalWithVat] = useState(0);
	const [totalWithoutVat, setTotalWithoutVat] = useState(0);

	const handleOnMouseEnter = () => {
		if (!screen(['xs', 'sm'])) {
			setCartFlyoutOpen(true);
		}
	};

	const handleOnMouseLeave = () => {
		if (!screen(['xs', 'sm'])) {
			setCartFlyoutOpen(false);
		}
	};

	const isGreenRatedUserPayment = cart?.isGreenXP || false;

	const getWidth = () => window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;

	const setupFlyoutAlignment = () => {
		const barWidth = document.getElementById('options-section-wrapper')?.clientWidth ?? 0;
		if (barWidth > 0 && screen(['xl', 'lg'])) {
			if (getWidth() < 1150) {
				const alignment = (getWidth() - barWidth) / 2 - 30;
				setFlyoutAlignment(alignment);
			} else {
				const alignment = (getWidth() - barWidth) / 2 - 110;
				setFlyoutAlignment(alignment);
			}
		} else if (barWidth > 0 && screen('md')) {
			const alignment = (getWidth() - barWidth) / 2 - 30;
			setFlyoutAlignment(alignment);
		} else if (barWidth > 0) {
			const alignment = (getWidth() - 320) / 2;
			setFlyoutAlignment(alignment);
		}
	};

	useEffect(() => {
		let timeoutId: any = null;
		const resizeListener = () => {
			clearTimeout(timeoutId);
			timeoutId = setTimeout(() => setupFlyoutAlignment(), 200);
		};
		window.addEventListener('resize', resizeListener);
		resizeListener();
		return () => {
			window.removeEventListener('resize', resizeListener);
		};
	}, [screen(['xs']), screen(['sm']), screen(['md']), screen(['lg'])]);

	useEffect(() => {
		let totalWithoutVat = 0;
		let totalWithVat = 0;
		cart?.items.map((item) => {
			if (item.PA?.includes('BNPL')) {
				totalWithoutVat = totalWithoutVat + Number(item?.BNPL_UPFRONT_MINUS_VAT || 0);
				totalWithVat = totalWithVat + Number(item?.BNPL_UPFRONT_AMOUNT);
			} else {
				totalWithoutVat = totalWithoutVat + Number(item?.taxablePrice || 0);
				totalWithVat = totalWithVat + Number(item?.price);
			}
		});
		setTotalWithVat(totalWithVat);
		setTotalWithoutVat(totalWithoutVat);
	}, [cart?.items]);

	const vatPercent = cart?.items.reduce((accumulator, currentValue) => {
		if (currentValue?.plan?.vatPercent) {
			if (accumulator < Number(currentValue?.plan?.vatPercent)) {
				accumulator = Number(currentValue?.plan?.vatPercent);
			}
			return accumulator;
		} else {
			return accumulator;
		}
	}, 0);

	const handleClearAll = () => {
		dispatch(addBusyIndicator(CLEAR_ALL_CART_BI));
		const makasibVouchers = !!cart
			? cart.items.filter((item) => item.productId === MAKASIB_VOUCHER_PRODUCT_ID)
			: [];
		OrderFlowService.clearAllCartItems().subscribe(
			() => {
				if (makasibVouchers.length > 0) {
					makasibVouchers.forEach((item) => {
						MakasibService.manageMakasibVoucherReservation(String(item?.voucherId), 'U').subscribe(
							() => {},
							() => {}
						);
					});
				}
				dispatch(removeBusyIndicator(CLEAR_ALL_CART_BI));
			},
			() => {
				dispatch(removeBusyIndicator(CLEAR_ALL_CART_BI));
			}
		);
	};

	const handleOnScroll = useCallback(() => {
		const scrolledView = scrollRef?.current;
		const scrolledToBottom =
			Number(scrolledView?.clientHeight) + Number(scrolledView?.scrollTop) === scrolledView?.scrollHeight;
		const scrolledToTop = Number(scrolledView?.scrollTop) === 0;
		if (scrolledView?.scrollHeight) {
			setScrollState({ bottom: true, top: true });
		}
		setScrollState({ bottom: scrolledToBottom, top: scrolledToTop });
	}, [scrollRef?.current?.scrollTop]);

	const monthlyPrice = useMemo(() => {
		return Number(
			cart?.items.reduce((curr, prev) => {
				if (
					prev?.plan?.PA === ItemType.ITEM_ACCESSORY_INSTALMENTS ||
					prev?.plan?.PA === ItemType.TABLETS_LAPTOPS_INSTALMENTS ||
					prev?.plan?.PA === ItemType.B2B_ITEM_ACCESSORY_BAQATI ||
					prev?.plan?.PA === ItemType.B2B_TABLETS_LAPTOPS_BAQATI ||
					prev?.plan?.PA === ItemType.B2B2C_ITEM_ACCESSORY_BAQATI ||
					prev?.plan?.PA === ItemType.B2B2C_TABLETS_LAPTOPS_BAQATI
				) {
					curr = curr + Number(prev?.plan?.IV);
				} else {
					curr = curr + Number(prev?.plan?.priceMonthly ?? 0) + Number(prev?.plan?.IV);
				}
				return !Number.isNaN(curr) ? curr : 0;
			}, 0)
		);
	}, [cartUpdated, cart]);

	const handleRedirectToCart = () => {
		history.push(`/${getLang()}/cart`);
	};

	const handleRedirectToStore = () => {
		setOpen(false);
		history.push(`/${getLang()}/store`);
	};

	const cartHeight = useMemo(() => {
		if (!cart?.items.length) {
			return EMPTY_CART_HEIGHT;
		} else if (cart.items.length > 1) {
			let size = SMALL_CART_HEIGHT;
			size += (cart.items.length - 1) * 100;
			if (size > BIG_CART_HEIGHT) {
				return BIG_CART_HEIGHT;
			}
			return size;
		}
		return SMALL_CART_HEIGHT;
	}, [cart?.items]);

	return (
		<CartFlyoutContainer
			onMouseEnter={handleOnMouseEnter}
			onMouseLeave={handleOnMouseLeave}
			open={cartFlyoutOpen || open}
			height={cartHeight}
			alignment={flyoutAlignment}
			isTelemarketer={isTelemarketer}
		>
			<ConditionalRender
				show={!!cart?.items && cart.items.length > 0}
				onTrue={() => (
					<ContentWrapper>
						<HeaderWrapper>
							<DigitXsSm color="black54">{translate('navbar.cart.header')}</DigitXsSm>
							<HeaderButtonWrapper>
								<TertiaryMediumButtonFlex
									uniqueId="navbar.cart.clear-all"
									onClick={handleClearAll}
									listener={CLEAR_ALL_CART_BI}
								>
									<InnerButtonWrapper>
										<ParagraphM color="primary">{translate('navbar.cart.clear-all')}</ParagraphM>
										<IconWrapper>
											<Icon name="close" fill="primary" height={ICON_SIZE} width={ICON_SIZE} />
										</IconWrapper>
									</InnerButtonWrapper>
								</TertiaryMediumButtonFlex>
							</HeaderButtonWrapper>
						</HeaderWrapper>
						<ItemsList
							scrollState={scrollState}
							items={cart?.items.length ?? 0}
							ref={scrollRef}
							onScroll={handleOnScroll}
						>
							{cart?.items
								.filter(
									(item) =>
										item.productId !== MAKASIB_POINTS_PRODUCT_ID &&
										item.productId !== MAKASIB_VOUCHER_PRODUCT_ID
								)
								.map((item, index) => (
									<React.Fragment key={index}>
										<CartFlyoutItem item={item} isGreenRatedUserPayment={isGreenRatedUserPayment} />
										<Divider marginTop={8} marginBottom={screen(['xs', 'sm']) ? 0 : 8} />
									</React.Fragment>
								))}
						</ItemsList>
						<SummaryWrapper>
							<ConditionalRender
								show={isGreenRatedUserPayment}
								onTrue={() => <></>}
								onFalse={() => (
									<CostWrapper>
										<CostTitle>{translate('navbar.cart.upfront')}</CostTitle>
										<VatWrapper>
											<BodyL color="primary">
												{translate('omr')}
												{`\xa0${roundMoney(
													Number(
														cart?.items.some(
															(item) =>
																item.productId === MAKASIB_POINTS_PRODUCT_ID &&
																item.price
														) ||
															cart?.items.some(
																(item) =>
																	item.productId === MAKASIB_VOUCHER_PRODUCT_ID &&
																	item.price
															)
															? Number(cart?.totalProductPrice) -
																	Number(
																		cart?.items.find(
																			(item) =>
																				item.productId ===
																				MAKASIB_POINTS_PRODUCT_ID
																		)?.price ?? 0
																	) -
																	Number(
																		cart?.items.find(
																			(item) =>
																				item.productId ===
																				MAKASIB_VOUCHER_PRODUCT_ID
																		)?.price ?? 0
																	)
															: isBuyNowPayLaterInCart
															? totalWithVat
															: cart?.totalProductPrice
													)
												)}`}
											</BodyL>
											<ConditionalRender
												show={!!vatPercent}
												onTrue={() => (
													<Vat>{translate('navbar.cart.vat', vatPercent ?? '')}</Vat>
												)}
											/>
										</VatWrapper>
									</CostWrapper>
								)}
							/>

							<ConditionalRender
								show={monthlyPrice !== 0}
								onTrue={() => (
									<ConditionalRender
										show={isBuyNowPayLaterInCart}
										onTrue={() => (
											<>
												<CostWrapper>
													<CostTitle>
														{translate(
															cart && cart.items[0].IP === '12'
																? 'cart.items.11-month'
																: 'cart.items.23-month'
														)}
													</CostTitle>
													<VatWrapper>
														<BodyL color="primary">
															{translate('omr')}
															{`\xa0${roundMoney(cart ? cart.items[0].IV : '0')}`}
														</BodyL>
													</VatWrapper>
												</CostWrapper>
												<CostWrapper>
													<CostTitle>{translate('product.cart-card.last')}</CostTitle>
													<VatWrapper>
														<BodyL color="primary">
															{translate('omr')}
															{`\xa0${roundMoney(cart ? cart.items[0].BNPLFEP : '0')}`}
														</BodyL>
													</VatWrapper>
												</CostWrapper>
											</>
										)}
										onFalse={() => (
											<CostWrapper>
												<CostTitle>
													{isGreenRatedUserPayment
														? translate(
																'navbar.cart.remaining.months.bills',
																Number(
																	cart?.items.find((item) => !!item.IV?.length)
																		?.installmentDuration
																) - 1 || '11'
														  )
														: translate('navbar.cart.monthly')}
												</CostTitle>
												<VatWrapper>
													<BodyL color="primary">
														{translate('omr')}
														{`\xa0${roundMoney(monthlyPrice)}`}
													</BodyL>
													<Vat>{translate('navbar.cart.vat', vatPercent ?? '')}</Vat>
												</VatWrapper>
											</CostWrapper>
										)}
									/>
								)}
							/>
							<ButtonWrapper>
								<PrimaryLargeButtonFlex
									uniqueId="navbar.cart.go-to-cart"
									onClick={handleRedirectToCart}
								>
									<ParagraphM color="white">{translate('navbar.cart.go-to-cart')}</ParagraphM>
								</PrimaryLargeButtonFlex>
							</ButtonWrapper>
						</SummaryWrapper>
					</ContentWrapper>
				)}
				onFalse={() => (
					<EmptyStateWrapper>
						<ShoppingBagShadowWrapper>
							<Image src="shopping_bag_shadow.png" height={19.67} width={71.77}></Image>
						</ShoppingBagShadowWrapper>
						<ShoppingBagImageWrapper>
							<Image src="shopping_bag.png" height={40.58} width={37.92}></Image>
						</ShoppingBagImageWrapper>
						<EmptyStateDescription>{translate('flyout.cart.empty-state.label')}</EmptyStateDescription>
						<ButtonWrapper>
							<PrimaryLargeButtonFlex uniqueId="navbar.cart.go-to-store" onClick={handleRedirectToStore}>
								<ParagraphM color="white">{translate('navbar.cart.go-to-store')}</ParagraphM>
							</PrimaryLargeButtonFlex>
						</ButtonWrapper>
					</EmptyStateWrapper>
				)}
			/>
		</CartFlyoutContainer>
	);
};

export default CartFlyout;
