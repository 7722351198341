import { ICartItem, IViewCart } from '@ApiModels/viewCart';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { BUY_HBB, BUY_WFBB } from '@Views/Map/Map';
import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { dispatch } from '@Redux/store';
import { removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { GET_TELESALES_CART_BI } from '../../App';
import { setPersistentCheckoutDetails } from '@Redux/modules/persistentCheckoutData/actions';
import { ItemType } from '@Services/productsService/productsService';
import { CreditRating, EBU_PLAN_ACTIONS, POSTPAID_PLAN } from '@Config/app.config';
import { isGreenZeroPayment } from '@Config/features.config';
import { remoteConfigSelector } from '@Redux/modules/settings/remoteConfig/selectors';

interface IUseCart {
	cart: IViewCart;
	cartItemsLength: number;
	loading: boolean;
	isEbuCart: boolean;
	vatPercentHighest: number;
	cartItemsPlanPriceSum: number;
	cartItemsVatValue: number;
	cartItemsDevicePlanVatSum: number;
	twoYearInstallmentValue: number;
	cartItemsDeviceInstallmentSum: number;
	cartWithPlan: boolean;
	cartWithHbbWfbbItemWithDevice: boolean;
	cartHbbWfbbItem: ICartItem;
	cartHbbWfbbWithoutOtherItem: boolean;
}

const EMPTY_CART: IViewCart = {
	items: [],
	totalProductPrice: '0',
	orderId: '',
	isGreenXP: false,
};

export const CartContext = createContext({
	cart: EMPTY_CART,
	cartItemsLength: 0,
	loading: true,
	cartItemsVatValue: 0,
	vatPercentHighest: 0,
	twoYearInstallmentValue: 0,
	cartItemsPlanPriceSum: 0,
	isEbuCart: false,
	cartItemsDevicePlanVatSum: 0,
	cartItemsDeviceInstallmentSum: 0,
	cartWithPlan: false,
	cartWithHbbWfbbItemWithDevice: false,
	cartHbbWfbbItem: EMPTY_CART.items[0],
	cartHbbWfbbWithoutOtherItem: false,
});

export const useCart = (): IUseCart => useContext(CartContext);

const CartContextProvider = ({ children }: { children: any }): any => {
	const [cart, setCart] = useState<IViewCart>(EMPTY_CART);
	const [cartItemsLength, setCartItemsLength] = useState(0);
	const [loading, setLoading] = useState(false);
	const cartUpdated = useSelector((state: types.redux.IState) => state.cart.cartUpdated);
	const telemarketer = useSelector((state: types.redux.IState) => state.api.authentication.telemarketer);
	const creditRating = useSelector((state: types.redux.IState) => state.api.personalData.creditRating);
	const remoteFeatureConfig = useSelector(remoteConfigSelector);
	const DISABLE_GREEN_ZERO_PAYMENT = isGreenZeroPayment(remoteFeatureConfig);

	const getCart = () => {
		setLoading(true);

		OrderFlowService.viewCart().subscribe(
			(r) => {
				setCart({
					...r,
					items: r?.items ? r?.items : [],
					isGreenXP: !!(
						creditRating === CreditRating.GREEN &&
						r?.items?.some(
							(item) =>
								item?.vatCollection === 'F' ||
								item?.vatCollection === 'I' ||
								item?.upfrontCollection === 'F' ||
								item?.upfrontCollection === 'I'
						) &&
						DISABLE_GREEN_ZERO_PAYMENT
					),
				});
				setCartItemsLength((r.items || []).reduce((cur, item) => cur + Number(item.quantity), 0));
				if (telemarketer) {
					dispatch(
						setPersistentCheckoutDetails({
							telesalesInformation: {
								orderId: r.orderId,
							},
						})
					);
				}
				dispatch(removeBusyIndicator(GET_TELESALES_CART_BI));
				setLoading(false);
			},
			() => {
				if (telemarketer) {
					dispatch(
						setPersistentCheckoutDetails({
							telesalesInformation: {
								orderId: undefined,
							},
						})
					);
				}
				setCart(EMPTY_CART);
				setCartItemsLength(0);
				dispatch(removeBusyIndicator(GET_TELESALES_CART_BI));
				setLoading(false);
			}
		);
	};

	const isEbuCart = useMemo(() => {
		return !!cart.items.some((item) => EBU_PLAN_ACTIONS.includes(item.plan?.PA || ''));
	}, [cart]);

	const twoYearInstallmentValue = cart.items.reduce((accumulator, currentValue) => {
		if (currentValue?.plan?.IV && currentValue?.plan?.IP === '24') {
			return accumulator + Number(currentValue?.plan?.IV);
		} else {
			return accumulator;
		}
	}, 0);

	const vatPercentHighest = cart.items.reduce((accumulator, currentValue) => {
		if (currentValue?.plan?.PA === BUY_HBB) {
			if (accumulator < Number(currentValue?.plan?.planVatPercent)) {
				accumulator = Number(currentValue?.plan?.planVatPercent);
			}
			return accumulator;
		} else if (currentValue?.plan?.vatPercent) {
			if (accumulator < Number(currentValue?.plan?.vatPercent ?? currentValue?.vatPercent)) {
				accumulator = Number(currentValue?.plan?.vatPercent ?? currentValue?.vatPercent);
			}
			return accumulator;
		} else {
			return accumulator;
		}
	}, 0);

	const cartItemsVatValue = cart.items.reduce((accumulator, currentValue) => {
		if (currentValue.quantity > 1) {
			return accumulator + Number(currentValue?.vatValue) * currentValue.quantity;
		} else {
			return accumulator + Number(currentValue?.vatValue);
		}
	}, 0);

	const cartItemsPlanPriceSum = cart.items.reduce((accumulator, currentValue) => {
		if (
			currentValue?.plan?.priceMonthly &&
			currentValue?.plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS &&
			currentValue?.plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS_HBB &&
			currentValue?.plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS_WFBB &&
			currentValue?.plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS &&
			currentValue?.plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS_HBB &&
			currentValue?.plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS_WFBB &&
			currentValue?.plan?.PA !== ItemType.B2B_ITEM_ACCESSORY_BAQATI &&
			currentValue?.plan?.PA !== ItemType.B2B_TABLETS_LAPTOPS_BAQATI &&
			currentValue?.plan?.PA !== ItemType.B2B2C_ITEM_ACCESSORY_BAQATI &&
			currentValue?.plan?.PA !== ItemType.B2B2C_TABLETS_LAPTOPS_BAQATI &&
			currentValue?.PA !== POSTPAID_PLAN
		) {
			return accumulator + Number(currentValue?.plan?.priceMonthly);
		} else {
			return accumulator;
		}
	}, 0);

	const cartItemsDevicePlanVatSum = cart.items?.reduce((accumulator, currentValue) => {
		if (
			currentValue?.plan?.priceMonthly &&
			currentValue?.plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS &&
			currentValue?.plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS_HBB &&
			currentValue?.plan?.PA !== ItemType.ITEM_ACCESSORY_INSTALMENTS_WFBB &&
			currentValue?.plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS &&
			currentValue?.plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS_HBB &&
			currentValue?.plan?.PA !== ItemType.TABLETS_LAPTOPS_INSTALMENTS_WFBB &&
			currentValue?.PA !== POSTPAID_PLAN
		) {
			return accumulator + Number(currentValue?.plan?.planVatValue);
		} else {
			return accumulator;
		}
	}, 0);

	const cartItemsDeviceInstallmentSum = cart.items?.reduce((accumulator, currentValue) => {
		if (currentValue?.plan?.IV) {
			return accumulator + Number(currentValue?.plan?.IV);
		} else {
			return accumulator;
		}
	}, 0);

	const cartWithPlan = cart.items.some((item) => item.withPlan);

	const moreThanOneItemInCart = cart.items.length > 1;
	const cartHbbWfbbItem =
		cart.items.find((item) => [BUY_HBB, BUY_WFBB].includes(item.plan?.PA || '')) || EMPTY_CART.items[0];
	const cartWithHbbWfbbItemWithDevice = !!cartHbbWfbbItem && moreThanOneItemInCart;
	const cartHbbWfbbWithoutOtherItem = !!cartHbbWfbbItem && cart.items.length === 1;

	useEffect(() => {
		if (cartUpdated) {
			getCart();
		}
	}, [cartUpdated]);

	useEffect(() => {
		getCart();
	}, []);

	return (
		<CartContext.Provider
			value={{
				cart,
				cartItemsLength,
				loading,
				vatPercentHighest,
				cartItemsPlanPriceSum,
				cartItemsVatValue,
				isEbuCart,
				cartItemsDevicePlanVatSum,
				twoYearInstallmentValue,
				cartItemsDeviceInstallmentSum,
				cartWithPlan,
				cartWithHbbWfbbItemWithDevice,
				cartHbbWfbbItem,
				cartHbbWfbbWithoutOtherItem,
			}}
		>
			{children}
		</CartContext.Provider>
	);
};

export default CartContextProvider;
