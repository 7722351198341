import React, { FunctionComponent } from 'react';
import {
	LabelWrapper,
	PriceContainer,
	PriceSectionWrapper,
	// MissingInstallmentWrapper,
} from '@Components/Card/cards/ProductCard/subcomponents/InstalmentPriceSection/InstalmentPriceSection.styles';
import PriceTag from '@Components/PriceTag/PriceTag';
import { Caption, Discount } from '@Components/Typography/Typography.styled';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PriceType, TileSize } from '@Components/Card/cards/ProductCard/ProductCard.styled';
import useTranslate from '@Utils/hooks/useTranslate';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import { GET_PRODUCTS_INSTALLMENT_BI } from '@Views/Store/Store';
import {
	APPLE_WATCH_INSTALLMENTS_INDICATOR,
	IPHONE_INSTALLMENTS_INDICATOR,
	IPAD_INSTALLMENTS_INDICATOR,
} from '@Views/AppleBrandStore/AppleBrandStore';
import { isInstallmentAvailable } from '@Config/features.config';
import { remoteConfigSelector } from '@Redux/modules/settings/remoteConfig/selectors';
import { useSelector } from 'react-redux';

interface IPriceSectionProps {
	productTextInstallmentValue?: string;
	discount?: number;
	priceType: PriceType;
	preorderStartDate?: string;
	tileSize: TileSize;
	marginBottom?: number;
}

const InstalmentPriceSection: FunctionComponent<IPriceSectionProps> = ({
	productTextInstallmentValue,
	discount,
	priceType,
	preorderStartDate,
	tileSize,
}) => {
	const { translate } = useTranslate();
	const remoteFeatureConfig = useSelector(remoteConfigSelector);
	const isInstallmentAvailableFix = isInstallmentAvailable(remoteFeatureConfig);

	return (
		<PriceSectionWrapper>
			<PriceContainer>
				<LabelWrapper>
					<BusyIndicator
						listener={[
							GET_PRODUCTS_INSTALLMENT_BI,
							APPLE_WATCH_INSTALLMENTS_INDICATOR,
							IPHONE_INSTALLMENTS_INDICATOR,
							IPAD_INSTALLMENTS_INDICATOR,
						]}
						skeleton="cardInstallmentPrice"
					>
						<ConditionalRender
							show={
								(isInstallmentAvailableFix &&
									!!productTextInstallmentValue &&
									Number(productTextInstallmentValue) !== 0) ||
								(!isInstallmentAvailableFix && !!productTextInstallmentValue)
							}
							onTrue={() => (
								<>
									<Caption color="black54">{translate('product.card.price.installments')}</Caption>
									<ConditionalRender
										show={!!discount}
										onTrue={() => (
											<Discount color="primary">
												{translate('product.card.discount', discount || 0)}
											</Discount>
										)}
									/>
									<PriceTag
										price={!!productTextInstallmentValue ? productTextInstallmentValue : 0}
										size="mini"
										duration={translate('select-protection-modal.payment.mo')}
										color={discount ? 'primary' : 'black87'}
									/>
								</>
							)}
							onFalse={() => (
								<div style={{ marginBottom: '22px' }}></div>

								// commneted below lines as per bussiness we do not need to show installment not available warning if price are not coming from backend

								// <MissingInstallmentWrapper>
								// 	<Caption color="black54">
								// 		{translate('product.card.price.installments-not-available')}
								// 	</Caption>
								// </MissingInstallmentWrapper>
							)}
						/>
					</BusyIndicator>
				</LabelWrapper>
			</PriceContainer>
		</PriceSectionWrapper>
	);
};

export default InstalmentPriceSection;
