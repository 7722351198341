import {
	IValueWithId,
	IColorWithId,
	IContent,
	IEntry,
	IFacet,
	IFilter,
	IFilterEntry,
	IItem,
} from '@ApiModels/productsByCategoryId';
import { IAttribute, IPrice, IValue } from '@ApiModels/productsByPartNumber';
import { IProductByIdItem, IContents } from '@ApiModels/productById';
import { translations } from '@Utils/translations/translations';
import { getLanguage } from '@Utils/language/language';
import TypeUtils from '@Utils/api/models/utils/typeUtils';
import { FILTERS_TO_REMOVE } from '@Config/app.config';
import { ESHOP_MARKETING_TAG } from '@Config/app.config';
export interface ITariffIncluded {
	planId: string;
}

export const COLOR_ATTRIBUTE = 'DEF_Color';
export const CAPACITY_ATTRIBUTE = 'DEF_Capacity';
export const MANUFACTURER_ATTRIBUTE = 'MANUFACTURER';
export const BUNDLE_SERVICE_TARIFF = 'BUNDLE_SERVICE_TARIFF';
export const VAT_PERCENT = 'VAT_PERCENT';
export const ITEM_TYPE_ATTRIBUTE = 'DEF_ITEM_TYPE';
export const COUNTRY_ATTRIBUTE = 'DEF_Country';
export const GIFT_CARD_VALUES_ATTRIBUTE = 'DEF_Value';
export const COM_PARAM_ID = 'COM_PARAM_ID';
export const PREORDER = 'PREORDER';
export const PREORDER_DATE = 'PREORDER_DATE';
export const DEF_ITEM_TYPE = 'DEF_ITEM_TYPE';
export const DURATION = 'DURATION';
export const GIFT_CARD_ITEM_TYPE = 'GIFTCARD';
export const DIGITAL_PRODUCT_ITEM_TYPE = 'DIGITAL_PRODUCT';
export const ITEM_TYPE = 'ITEM';
export const ITEM_HBB_INSTALMENTS = 'ITEM_HBB_INSTALMENTS';
export const ITEM_MOBILE = 'ITEM_MOBILE';
export const ITEM_HBB = 'ITEM_HBB';
export const ITEM_ACCESSORY_INSTALMENTS = 'ITEM_ACCESSORY_INSTALMENTS';
export const HWI_IDU_WIFI_AX = 'HWI_IDU_WIFI_AX';
export const ITEM_ACCESSORY_INSTALMENTS_HBB = 'ITEM_ACCESSORY_INSTALMENTS_HBB';
export const ITEM_ACCESSORY_INSTALMENTS_WFBB = 'ITEM_ACCESSORY_INSTALMENTS_WFBB';
export const ADDITIONAL_SHIMENT_MODE = 'ADDITIONAL_SHIPMENT_MODE';
export const ADDITIONAL_SHIMENT_MODE_PICKUP = 'PICKUP';
export const LAUNCH_DATE_ATTRIBUTE = 'LAUNCH_DATE';
export const ESIM_CAPABLE = 'ESIM_CAPABLE';
export const ESHOP_ADDITIONAL_SERVICE = 'ESHOP_ADDITIONAL_SERVICE';
export const DEVICE_PROTECTION_PROMO = 'DEVICE_PROTECTION_PROMO';
export const TARIFF_CODE = 'TARIFF_CODE';
export const TABLETS_LAPTOPS_INSTALMENTS = 'TABLETS_LAPTOPS_INSTALMENTS';
export const TABLETS_LAPTOPS_INSTALMENTS_HBB = 'TABLETS_LAPTOPS_INSTALMENTS_HBB';
export const TABLETS_LAPTOPS_INSTALMENTS_WFBB = 'TABLETS_LAPTOPS_INSTALMENTS_WFBB';
export const B2B_TABLETS_LAPTOPS_BAQATI = 'B2B_TABLETS_LAPTOPS_BAQATI';
export const B2B2C_TABLETS_LAPTOPS_BAQATI = 'B2B2C_TABLETS_LAPTOPS_BAQATI';
export const B2B_ITEM_ACCESSORY_BAQATI = 'B2B_ITEM_ACCESSORY_BAQATI';
export const B2B2C_ITEM_ACCESSORY_BAQATI = 'B2B2C_ITEM_ACCESSORY_BAQATI';
export const PRODUCT_CONFIG_NEW_PCT = 'PRODUCT_CONFIG_NEW_PCT';
export const ITEM_NEW_PCT_SMARTPHONES = 'SMARTPHONES';
export const ITEM_NEW_PCT_LAPTOPS_TABLETS = 'LAPTOPS_TABLETS';
export const ITEM_NEW_PCT_ACCESSORIES = 'ACCESSORIES';
export const PARAM_DISABLE_INSTALLMENT_PERIOD = 'PARAM_DISABLE_INSTALLMENT_PERIOD';

export const INSTALLMENTS = [
	ITEM_ACCESSORY_INSTALMENTS,
	ITEM_ACCESSORY_INSTALMENTS_HBB,
	ITEM_ACCESSORY_INSTALMENTS_WFBB,
	TABLETS_LAPTOPS_INSTALMENTS,
	TABLETS_LAPTOPS_INSTALMENTS_HBB,
	TABLETS_LAPTOPS_INSTALMENTS_WFBB,
	B2B_TABLETS_LAPTOPS_BAQATI,
	B2B_ITEM_ACCESSORY_BAQATI,
	B2B2C_ITEM_ACCESSORY_BAQATI,
	B2B2C_TABLETS_LAPTOPS_BAQATI,
];

export const getAvailableValues = (
	attributtes: IAttribute<IValue>[],
	catalogEntryView?: IContents[]
): IValueWithId[] => {
	const value = attributtes.find((attr) => attr.identifier === GIFT_CARD_VALUES_ATTRIBUTE)?.values;
	if (Array.isArray(value)) {
		return value.map((item) => {
			if (!Array.isArray(item.value) && !Array.isArray(item.id)) {
				return {
					value: item.value,
					id: item.id,
					parsedIdentifier: getPartNumber(catalogEntryView?.[0].items, item.id || ''),
				};
			} else {
				return {
					value: item.value[0],
					id: item.id[0],
					parsedIdentifier: getPartNumber(catalogEntryView?.[0].items, item.id[0] || ''),
				};
			}
		});
	}
	return [];
};

export const getPartNumber = (items: IContents[] | undefined, itemId: string): string => {
	return (
		items?.find((catalogEntryViewItem) => {
			return (
				catalogEntryViewItem?.attributes?.find((attr) => attr.identifier === GIFT_CARD_VALUES_ATTRIBUTE)
					?.values[0].id === itemId
			);
		})?.partNumber || ''
	);
};

export const getPrice = (prices: IPrice[], type: string): number => {
	return Number(prices.find((price) => price.usage === type)?.value);
};

export const getAvailableColors = (attributtes: IAttribute<IValue>[]): IColorWithId[] => {
	const colors = attributtes.find((attributte) => attributte.identifier === COLOR_ATTRIBUTE)?.values?.[0];

	// Related products colors
	if (!!colors?.uniqueID) {
		return attributtes
			.find((attributte) => attributte.identifier === COLOR_ATTRIBUTE)
			?.values.map((color) => ({
				color: color.image1,
				id: color.uniqueID,
				identifier: color.identifier,
				value: color.value,
			})) as IColorWithId[];
	}

	if (colors) {
		if (Array.isArray(colors.image1)) {
			return colors.image1.map((color, index) => ({
				color,
				id: colors.id[index],
				identifier: colors.identifier[index],
				value: colors.value[index],
			}));
		} else if (!Array.isArray(colors.id) && !Array.isArray(colors.identifier))
			return [
				{ color: colors.image1, id: colors.id, identifier: colors.identifier, value: String(colors.value) },
			];
	}
	return [];
};
export const getAvailableCapacity = (attributtes: IAttribute<IValue>[]): IValueWithId[] => {
	const capacity = attributtes.find((attributte) => attributte.identifier === CAPACITY_ATTRIBUTE)?.values?.[0];
	// Related products capacity
	if (!!capacity?.uniqueID) {
		return attributtes
			.find((attributte) => attributte.identifier === CAPACITY_ATTRIBUTE)
			?.values.map((capacity) => ({
				value: capacity.value,
				id: capacity.uniqueID,
				parsedIdentifier: capacity.identifier,
			})) as IValueWithId[];
	}
	if (capacity) {
		if (Array.isArray(capacity.value)) {
			return capacity.value.map((singleCapacity, index) => ({
				value: singleCapacity,
				id: capacity.id[index],
				parsedIdentifier: singleCapacity,
			}));
		} else if (!Array.isArray(capacity.id))
			return [{ value: capacity.value, id: capacity.id, parsedIdentifier: '' }];
	}
	return [];
};

export const getAvailableCountries = (attributes: IAttribute<IValue>[]): IColorWithId[] => {
	const countriesValues = attributes.find((attribute) => attribute.identifier === COUNTRY_ATTRIBUTE)?.values;
	if (countriesValues && Array.isArray(countriesValues)) {
		return countriesValues.map((value) => {
			if (!Array.isArray(value.image1)) {
				return {
					color: String(value.image1),
					id: String(value.id),
					identifier: String(value.identifier),
					value: String(value.value),
				};
			} else {
				return {
					color: value.image1[0],
					id: value.id[0],
					identifier: value.identifier[0],
					value: String(value.value),
				};
			}
		});
	}
	return [];
};

export const getAdditionalFilterAttributes = ({
	attributes,
	available,
}: {
	attributes: IAttribute<IValue>[];
	available: boolean;
}): IAttribute<IValue> | undefined => {
	const identifiers: string[] = [];
	const eshopMarketingTag: any | string = attributes.find((attr) => attr.identifier === ESHOP_MARKETING_TAG)
		?.values[0].value;
	if (attributes.find((attr) => attr.identifier === '5G')?.values[0].value === 'Yes') {
		identifiers.push('5G_READY');
	}
	if (attributes.find((attr) => attr.identifier === 'PREORDER')?.values[0].value === 'TRUE') {
		identifiers.push('PREORDER');
	}

	if (TypeUtils.isString(eshopMarketingTag)) {
		identifiers.push(eshopMarketingTag);
	}
	if (available) {
		identifiers.push('AVAILABLE');
	}
	if (identifiers.length) {
		return {
			comparable: 'false',
			displayable: 'false',
			facetable: 'false',
			searchable: 'false',
			swatchable: 'false',
			storeDisplay: 'false',
			usage: 'Descriptive',
			name: 'ADDITIONAL_FILTER',
			id: '123',
			identifier: 'ADDITIONAL_FILTER',
			key: '123',
			merchandisable: 'false',
			values: [
				{
					id: '',
					identifier: identifiers,
					sequence: '',
					value: identifiers,
					image1: '',
				},
			],
		};
	}
};

export const getItems = (contents: IContent[]): IItem[] => {
	return contents.map((item) => {
		const { name, thumbnail, id, price, attributes = [], longDescription, shortDescription, seo } = item;
		const colors = getAvailableColors(attributes);
		const availableCountries = getAvailableCountries(attributes);
		const itemType = getAttributeValue(item, ITEM_TYPE_ATTRIBUTE) as string;
		const isGiftCard = itemType === GIFT_CARD_ITEM_TYPE;
		const isDigitalProduct = itemType === DIGITAL_PRODUCT_ITEM_TYPE;
		const isItem = itemType === ITEM_TYPE || itemType?.includes(ITEM_TYPE);

		return {
			attributes,
			name,
			price: getPrice(price, 'Offer'),
			colors,
			availableCountries,
			availableValues: getAvailableValues(attributes),
			selectedColor: '',
			availableCapacity: getAvailableCapacity(attributes),
			additionalInfo: (() => {
				const capacities = getAvailableCapacity(attributes);
				if (capacities.length) {
					return {
						label: 'product-card.capacity',
						value: `${capacities.map((item, index) => `${index > 0 ? ' ' : ''}${item.value}`)}`,
					};
				}
			})(),
			colorAttributeId: attributes.find((attribute) => {
				if (isGiftCard || isDigitalProduct) {
					return attribute.identifier === COUNTRY_ATTRIBUTE;
				}
				return attribute.identifier === COLOR_ATTRIBUTE;
			})?.id,
			capacityAttributeId: attributes.find((attribute) => attribute.identifier === CAPACITY_ATTRIBUTE)?.id,
			thumbnail,
			uniqueID: id,
			longDescription,
			shortDescription,
			manufacturer: getAttributeValue(item, MANUFACTURER_ATTRIBUTE) as string,
			itemType,
			isGiftCard,
			preorder: (getAttributeValue(item, PREORDER) as string) === 'TRUE',
			preorderDate: getAttributeValue(item, PREORDER_DATE) as string,
			isDigitalProduct,
			isItem,
			seo: seo?.href,
		};
	});
};

const getFilterEntry = (): IFacet => {
	return {
		entry: [
			{
				label: translations['filter.5g'][getLanguage().toLowerCase()],
				value: '5G_READY',
				count: '0',
				extendedData: { uniqueId: '1' },
			},
			{
				label: translations['filter.preorder'][getLanguage().toLowerCase()],
				value: 'PREORDER',
				count: '0',
				extendedData: { uniqueId: '2' },
			},
			{
				label: translations['filter.available'][getLanguage().toLowerCase()],
				value: 'AVAILABLE',
				count: '0',
				extendedData: { uniqueId: '3' },
			},
		],
		extendedData: {
			allValuesReturned: 'true',
			allowMultipleValueSelection: 'true',
			displaySequence: '0',
			displayable: 'true',
			fname: 'Available',
			max_display: '20',
			maximumValuesToDisplay: '20',
			name: 'Available',
			propertyId: '123',
			propertyvalue: 'ADDITIONAL_FILTER',
			sortorder: '0',
			zero_display: 'false',
		},
		name: '',
		value: 'available',
	};
};

export const getFilters = (facets: IFacet[], items: IItem[], smartphonesCategory?: boolean): IFilter[] => {
	const marketingFilter = getFilterEntry();
	let uniqueIdIterator = marketingFilter.entry.length;
	const marketingTagsFilters: IEntry[] | undefined = facets
		?.find((item: IFacet) => item.name === ESHOP_MARKETING_TAG)
		?.entry.map(
			(item: IEntry): IEntry => {
				++uniqueIdIterator;
				const itemLabel = item.label;
				return {
					label: itemLabel[0] + itemLabel.slice(1).toLowerCase(),
					value: itemLabel,
					count: '0',
					extendedData: { uniqueId: uniqueIdIterator.toString() },
				};
			}
		);
	if (marketingTagsFilters) marketingFilter.entry.push(...marketingTagsFilters);
	const _facets = smartphonesCategory ? [marketingFilter] : [];
	_facets.push(...facets);
	return _facets
		.filter((item) => !FILTERS_TO_REMOVE.includes(item.value))
		.map((facet) => ({
			...facet,
			entry: facet.entry.map(
				(item: IEntry): IFilterEntry => {
					const _item = { ...item };
					let parsedValue = item.value.replace('%3A%22', ':"').replace('%22', '"').replace('%3A+', ': ');
					if (parsedValue.lastIndexOf(':"') >= 0) {
						parsedValue = parsedValue.substring(
							parsedValue.lastIndexOf(':"') + 2,
							parsedValue.lastIndexOf('"')
						);
					}
					parsedValue = parsedValue
						.replace(/\+/g, ' ')
						.replace(/%2C/g, ',')
						.replace(/%5C/g, '"')
						.replace(/%E2%80%9D/g, '”')
						.replace(/%E2%80%99/g, '’');
					_item.count = String(
						items.filter((it) =>
							it.attributes?.some((attr) => {
								return !!parsedValue && attr.values[0].value.includes(parsedValue);
							})
						).length
					);
					return { enabled: false, ..._item, parsedValue };
				}
			),
		}));
};

export const getTariffIncludedFromAttributes = (content: IContents | string): ITariffIncluded => {
	if (typeof content === 'string') {
		return { planId: content };
	} else {
		const planValues = getAttributeValueAsString(content, BUNDLE_SERVICE_TARIFF);
		return { planId: planValues ?? '' };
	}
};

export const getAttributeValue = (content: IContent, atrributeIdentifier: string): string | string[] | undefined => {
	return content?.attributes?.find((attr) => attr.identifier === atrributeIdentifier)?.values[0].value;
};

export const getAttributeValueAsString = (
	content: IProductByIdItem | IContents,
	atrributeIdentifier: string
): string | undefined => {
	return content?.attributes?.find((attr) => attr.identifier === atrributeIdentifier)?.values[0].value;
};

export const getAllValues = (
	content: IProductByIdItem | IContents,
	atrributeIdentifier: string
): string[] | undefined => {
	const values = content?.attributes?.find((attr) => attr.identifier === atrributeIdentifier)?.values;
	return values?.map((item) => {
		return item.value;
	});
};
