import React, { FunctionComponent, useState } from 'react';
import { BottomCartWrapper } from './CartMobile.styled';
import ExpandableBox from '@Components/ExpandableBox/ExpandableBox';
import useTranslate from '@Utils/hooks/useTranslate';
import useScreen from '@Utils/hooks/useScreen';
import Card from '@Components/Card/Card';
import { useSelector } from 'react-redux';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { CreditRating } from '@Config/app.config';

interface ICartMobile {}

const CartMobile: FunctionComponent<ICartMobile> = () => {
	const [expanded, setExpanded] = useState<boolean>(false);

	const { translate } = useTranslate();
	const { screen, screenClass } = useScreen();

	const selectedProductDetails = useSelector((state: types.redux.IState) => state.selectedItems.details);
	const inStock = useSelector((state: types.redux.IState) => state.selectedItems.inStock);
	const installmentAmount = useSelector((state: types.redux.IState) => state.selectedItems.installmentAmount);
	const commitment = useSelector((state: types.redux.IState) => state.selectedItems.commitment);
	const { creditRating } = useSelector((state: types.redux.IState) => state.api.personalData);

	return (
		<ConditionalRender
			show={screen(['xs', 'sm', 'md']) && !!selectedProductDetails}
			onTrue={() => (
				<BottomCartWrapper>
					<ExpandableBox
						title={translate('product.whats.in.the.box')}
						minHeight={screen(['xs', 'sm', 'md']) ? 160 : 88}
						bottomMode={true}
						getExpandedValue={setExpanded}
					>
						<Card<types.cards.IProductCartCardProps>
							card="productCart"
							params={{
								outOfStock: !inStock,
								bottomMode: true,
								expanded,
								commitment,
								installmentAmount,
								installmentAmountId: installmentAmount,
								errorCodeForInstallmentAmount: '',
								isGreenRatingUser: creditRating === CreditRating.GREEN,
							}}
						/>
					</ExpandableBox>
				</BottomCartWrapper>
			)}
		/>
	);
};

export default CartMobile;
