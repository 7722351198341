import React, { FunctionComponent } from 'react';
import Divider from '@Components/Divider/Divider';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { PrimaryLargeButtonFixed, SecondaryLargeButtonFixed } from '@Components/controls/Button/Button';
import { SET_RETRY_ORDER_BI } from '@Layouts/Payment/Payment';
import { ButtonContent } from './CheckoutSummary.styled';
import { IRejectedSummaryActionProps } from './RejectedSummary';

const RejectedSummaryAction: FunctionComponent<
	IRejectedSummaryActionProps & { primaryButtonLabel: string; secondaryButtonLabel: string }
> = ({ backToStore, primaryButtonLabel, secondaryButtonLabel, timeoutReached, tryAgain }): JSX.Element => {
	return (
		<>
			<Divider color="transparent" marginBottom={40} />
			<ButtonContent>
				<ConditionalRender
					show={timeoutReached}
					onTrue={() => (
						<SecondaryLargeButtonFixed uniqueId="checkout-summary.go.back.to.store" onClick={backToStore}>
							{secondaryButtonLabel}
						</SecondaryLargeButtonFixed>
					)}
					onFalse={() => (
						<PrimaryLargeButtonFixed
							uniqueId="checkout-summary.try.again"
							listener={[SET_RETRY_ORDER_BI]}
							onClick={tryAgain}
						>
							{primaryButtonLabel}
						</PrimaryLargeButtonFixed>
					)}
				/>
			</ButtonContent>
		</>
	);
};

export default RejectedSummaryAction;
