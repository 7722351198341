export const DISABLE_CAR_INSURANCE = false;
export const DISABLE_HBB_WFBB_PROCESS = false;
export const DISABLE_WFBB = false;
export const DISABLE_PREPAID_TO_POSTPAID = false;
export const DISABLE_PICKUP_FROM_OUTLET = false;
export const DISABLE_PICKUP_BOOKING_APPOINTMENT = true;
export const DISABLE_ORDER_CANCELLATION = true;
export const DISABLE_EBU_SINGLE_PURCHASE = false;
export const DISABLE_EBU_SINGLE_PURCHASE_B2B2C_BAQATI = false;
export const DISABLE_EBU_SINGLE_PURCHASE_B2B_BAQATI = false;
export const DISABLE_MAKASIB_VOUCHER = true;
export const DISABLE_MAKASIB_POINTS = true;
export const DISABLE_NEW_ADDRESS_DROPDOWN = false;
export const DISABLE_DEVICE_INSTALLMENT_CONFIG = false;
export const DISABLE_MS_CSP = false;
export const ENABLE_ESHOP_LITE = false;
export const ENABLE_4G5G = true;
export const DISABLE_SMART_PAY = false;
export const DISABLE_GREEN_ZERO_PAYMENT = true;

/**
 * This kill switch flag use to enable and disable Smart pay payment flow
 */
export const isDisableSmartPay = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.DISABLE_SMART_PAY ?? true;

/**
 * This kill switch flag use to enable and disable BNPL code
 */
export const isDisableBnpl = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.DISABLE_BUY_NOW_PAY_LATER ?? true;

/**
 * This kill switch flag use to enable and disable BNPL CR changes ODF 7985
 */
export const isDisabledBnplCr7985 = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.DISABLE_BNPL_CR_ODF_7985 ?? true;

/**
 * This kill switch flag use to enable and disable empty skeleton between getInstallment fail response to call calculate api
 */
export const isCalCulateLoader = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.CALCULATE_LOADER_ODF_8685 ?? true;

/**
 * This kill switch flag use to enable and disable add to cart warning when pay-later product is already in cart
 */
export const isBnplCartWarning = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.BNPL_CART_ERROR_ODF_8539 ?? true;

/**
 * This kill switch flag use to enable and disable BNPL price change on Variant change and when user back to installment from upfront
 */
export const isBNPLVariantFix = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.BNPL_VARIANT_FIX_ODF_8968 ?? true;

/**
 * This kill switch flag use to enable and disable Sensibo Pro device
 */
export const isSensiboProDevice = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.DISABLE_SENSIBO_PRO_DEVICE ?? true;

/**
 * This kill switch flag use to enable and disable wifi mesh free device in add to cart eligible wifi mesh device
 */
export const isWifiFreeDevice = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.DISABLE_WIFI_FREE_DEVICE ?? true;

/**
 * This kill switch flag use to enable and disable new PCT Launch
 */
export const isNewPCTLaunch = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.DISABLE_NEW_PCT_LAUNCH ?? true;

/**
 * This kill switch flag use to enable and disable the HBB under the new PCT Launch flag
 */
export const isNewPCTHBB = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.DISABLE_NEW_PCT_HBB ?? true;

/**
 * This kill switch flag use to enable and disable the WFBB under the new PCT Launch flag
 */
export const isNewPCTWFBB = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.DISABLE_NEW_PCT_WFBB ?? true;

/**
 * This kill switch flag use to enable and disable the Accessory items under the new PCT Launch flag
 */
export const isNewPCTAccessories = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.DISABLE_NEW_PCT_ACCESSORY ?? true;

/**
 * This kill switch flag use to enable and disable the Accessory items for HBB/WFBB under the new PCT Launch flag
 */
export const isNewPCTHBBWFBBAccessories = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.DISABLE_NEW_PCT_HBBWFBB_ACCESSORY ?? true;

/**
 * This kill switch flag use to enable and disable the Tablets/PC items under the new PCT Launch flag
 */
export const isNewPCTTablets = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.DISABLE_NEW_PCT_TABLETS ?? true;

/**
 * This kill switch flag use to enable and disable the Tablets/PC items for HBB/WFBB under the new PCT Launch flag
 */
export const isNewPCTHBBWFBBTablets = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.DISABLE_NEW_PCT_HBBWFBB_TABLETS ?? true;

/**
 * This kill switch flag use to enable and disable 24 months option for Non Green customers
 */
export const isNGN24Months = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.DISABLE_NGN_24_MONTHS ?? true;

// isBNPLFailureLog is a feature flag for loging the creditrating and nationality failure in case of BNPL
export const isBNPLCreditFailure = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.BNPL_CREDIT_FAIL_ODF_10175 ?? true;

// this kill switch flag to disable insurance call with product incase of BNPL
export const disableBNPLInsurance = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.BNPL_INSURANCE_ODF_8245 ?? true;

export const isApplePay = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.APPLE_PAY_ODF_5012 ?? false;

export const isGreenZeroPayment = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.ENABLE_GREEN_ZERO_PAYMENT ?? false;

export const isBnplException = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.BNPL_EXCEPTION_HANDLING_OMNTL_4895 ?? false;

export const isBNPLCorelation = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.BNPL_CORELATION_OMNTL_4895 ?? false;

/**
 * This kill switch flag use to enable and disable the Explore now/ our Apple Store CTA on Apple Banner
 */
export const isExploreNowCTA = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.DISABLE_EXPLORE_NOW_CTA ?? true;

/**
 * This kill switch flag use to enable and disable the set missing account Number on handleGetInstallmentApi
 */
export const setMissingAccountNumber = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.MISSING_ACCOUNT_NUMBER_OMNTL_3551 ?? true;

export const isRetryGetInstallment = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.RETRY_GETINSTALLMENT_OMNTL_3245 ?? true;

/**
 * This kill switch flag use to enable and disable the fix of "Add to cart Huawei-IDU-Wifi wrong SKU passed"
 */
export const isHuaweiIDUWiFiSKUFix = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.HUAWEI_IDU_SKU_FIX_OMNTL_6702 ?? true;

/**
 * This kill switch flag use to disable/enable the huawei modem wifi 7 under smart living
 */
export const isHuaweiModemWifi7 = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.DISABLE_HUAWEI_WIFI_7_OMNTL_6007 ?? true;

/**
 * This kill switch flag use to disable/enable the fix for first time getinstallment api call for multi number users
 */
export const isGetInstallmentFirstClick = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.GET_INSTALLMENT_FIRST_CLICK_OMNTL_6898 ?? false;

export const isAlUfuqEmployee = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.AL_UFUQ_EMPLOYEE_OMNTL_7044 ?? true;

export const isInstallmentAvailable = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.INSTALLMENT_NOT_AVAILABLE_OMNTL_4988 ?? false;

export const isInstallmentTabsDisabled = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.NON_GREEN_INSTALLMENT_DISABLED_OMNTL_7064 ?? true;

/**
 * This kill switch flag use to disable the installment duration 12/24 for non eligiblibe items under the non PCT on PDP
 */
export const isNonPCTInstallment = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.DISABLE_NON_PCT_INSTALLMENT ?? true;

export const isAddToCartErrorMsg = (remoteConfig: { [key: string]: boolean }): boolean =>
	remoteConfig?.ADD_TO_CART_ERROR_MSG_9653 ?? true;
