export interface IPriceByCatEntry {
	price: {
		offerPrice?: string;
		taxablePrice: string;
		comParamInstallmentPeriod: string;
		vatValue: string;
		comParamInstallmentValue: string;
		comParamInstallmentValueBz?: string;
		vatPercent: string;
		listPrice: string;
		comParamInstallmentValueFinal?: string;
		standardPrice?: string;
		decisionReasonCode?: string;
		returnCode: string;
		vatProductId?: string;
		upfrontProductId?: string;
		vatValueBz?: string;
		tariffAmountBz?: string;
		upfrontCollection?: string;
		vatCollection?: string;
	};
}

const model: types.models.IInputModel = {
	price: ['response.price', []],
};

export default model;
