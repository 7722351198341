import { IProductsByPartNumber } from '@ApiModels/productsByPartNumber';
import { ICartItem } from '@ApiModels/viewCart';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import PriceTag from '@Components/PriceTag/PriceTag';
import { BodyM, BodyMBold, CaptionBiggerBold, CaptionBigger } from '@Components/Typography/Typography.styled';
import { ProductsService } from '@Services/productsService/productsService';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { Col, Hidden, Row, useScreenClass } from 'react-grid-system';
import { HeaderText, ItemRow, PriceRow, EmptyLine, PriceWrapper } from '../CartPaymentSummary.styled';
import { INSURANCE_REQUEST_TYPE, POSTPAID_PLAN, JAWWY_PRODUCT_PARTNUMBER_ID_PART } from '@Config/app.config';
import { marginLeft } from '@Utils/layout/layoutSupport';
import { isVoucherPlanAction } from '@Utils/plans/planAction';
import Divider from '@Components/Divider/Divider';
interface ICartPaymentSummaryItem {
	item: ICartItem;
	vat: number;
	vatPercent: number;
	monthly?: boolean;
	single?: boolean;
	planName?: string;
	isVoucher?: boolean;
	planPrices?: {
		planTaxablePrice?: string;
		planVatValue?: string;
		planvatPercent?: string;
	};
	isMakasib?: boolean;
	isEbuCart?: boolean;
	isGreenCustomer?: boolean;
	planNameGreen?: string;
	upFrontAmount?: string | number;
	planPricesGreen?: {
		planTaxablePrice?: string;
		planVatValue?: string;
		planvatPercent?: string;
	};
}

const CartPaymentSummaryItem: FunctionComponent<ICartPaymentSummaryItem> = ({
	item,
	vat,
	vatPercent,
	monthly,
	single,
	planName,
	planPrices,
	isVoucher = false,
	isMakasib = false,
	isEbuCart = false,
	isGreenCustomer = false,
	planNameGreen = '',
	planPricesGreen,
	upFrontAmount = 0,
}) => {
	const { translate } = useTranslate();
	const screenClass = useScreenClass();
	const [product, setProduct] = useState<IProductsByPartNumber>();
	const plan = item.plan;
	const isPrepaid = plan?.PA?.includes(POSTPAID_PLAN);

	useEffect(() => {
		ProductsService.getProductsByPartNumber(item.partNumber).subscribe(
			(product) => {
				setProduct(product);
			},
			() => {}
		);
	}, [item.partNumber]);

	const _price = Number(planPrices?.planTaxablePrice ? planPrices?.planTaxablePrice : item.plan?.IV);
	const _greenPrice = Number(planPricesGreen?.planTaxablePrice ? planPricesGreen?.planTaxablePrice : item.plan?.IV);
	const price = !Number.isNaN(_price) ? _price : 0;
	const greenPrice = !Number.isNaN(_greenPrice) ? _greenPrice : 0;

	const buyCarInsurance = useMemo(() => {
		return !!item?.plan?.requestType && INSURANCE_REQUEST_TYPE.includes(item?.plan?.requestType);
	}, [item]);

	const greenFirstBill =
		isGreenCustomer && !!(item.installmentDuration?.length || item.plan?.installmentDuration?.length);

	return (
		<>
			<ConditionalRender
				show={isGreenCustomer && !(item.installmentDuration?.length || item.plan?.installmentDuration?.length)}
				onTrue={() => <></>}
				onFalse={() => (
					<ItemRow paddingTop="16" paddingBottom="12">
						<Col lg={6} md={6} sm={6} xs={12}>
							<Row style={marginLeft('17px')}>
								<ConditionalRender
									show={!screenClass.includes('sm')}
									onTrue={() => (
										<BodyMBold color={isMakasib || isVoucher ? 'primary' : 'black87'}>
											{`${
												buyCarInsurance
													? translate('car-insurance.breadcrumb')
													: isPrepaid
													? plan?.planName
													: isMakasib
													? translate('cart.makasib.summary.title')
													: isVoucher
													? translate(
															'cart.e-voucher.summary.title',
															item?.voucherId
																? item?.voucherId
																: item?.plan?.voucherId
																? item?.plan?.voucherId
																: ''
													  )
													: planName ?? product?.name ?? ''
											} ${
												monthly
													? translate(
															`cart.payment.summary.item.${
																planName ||
																item.partNumber.includes(
																	JAWWY_PRODUCT_PARTNUMBER_ID_PART
																)
																	? 'subscription'
																	: 'installment'
															}`
													  )
													: ''
											}`}
										</BodyMBold>
									)}
									onFalse={() => (
										<CaptionBiggerBold color={isMakasib || isVoucher ? 'primary' : 'black87'}>
											{`${
												buyCarInsurance
													? translate('car-insurance.breadcrumb')
													: isPrepaid
													? plan?.planName
													: isMakasib
													? translate('cart.makasib.summary.title')
													: isVoucher
													? translate(
															'cart.e-voucher.summary.title',
															item?.voucherId
																? item?.voucherId
																: item?.plan?.voucherId
																? item?.plan?.voucherId
																: ''
													  )
													: planName ?? product?.name ?? ''
											} ${
												monthly
													? translate(
															`cart.payment.summary.item.${
																planName ||
																item.partNumber.includes(
																	JAWWY_PRODUCT_PARTNUMBER_ID_PART
																)
																	? 'subscription'
																	: 'installment'
															}`
													  )
													: ''
											}`}
										</CaptionBiggerBold>
									)}
								/>
							</Row>
						</Col>
						<Col lg={3} md={3} sm={3} xs={9}>
							<Hidden xs={monthly}>
								<PriceWrapper>
									<ConditionalRender
										show={!monthly && !isGreenCustomer}
										onTrue={() => (
											<PriceTag
												price={
													isVoucherPlanAction(item)
														? Number(item?.taxablePrice)
														: Number(item?.price) / item.quantity - vat || 0
												}
												color={isMakasib || isVoucher ? 'primary' : 'black87'}
												size="mini"
											/>
										)}
										onFalse={() => <EmptyLine isMakasib={isMakasib || isVoucher} />}
									/>
								</PriceWrapper>
							</Hidden>
						</Col>
						<Hidden xs={!monthly}>
							<Col sm={3} md={3} lg={3}>
								<PriceRow>
									<HeaderText>
										{screenClass.includes('xs') ? translate('cart.items.monthly') : ''}
									</HeaderText>
									<ConditionalRender
										show={!!monthly || isGreenCustomer}
										onTrue={() => <PriceTag price={price} size="mini" />}
										onFalse={() => <EmptyLine isMakasib={isMakasib || isVoucher} />}
									/>
								</PriceRow>
							</Col>
						</Hidden>
					</ItemRow>
				)}
			/>

			<ConditionalRender
				show={greenFirstBill} // for Upfront first Row
				onTrue={() => (
					<>
						<ItemRow paddingTop="0" paddingBottom="15">
							<Col lg={6} md={6} sm={6} xs={12}>
								<Row style={marginLeft('17px')}>
									<ConditionalRender
										show={!screenClass.includes('sm')}
										onTrue={() => (
											<BodyM color="black87">
												{translate('payment.summary.description.subtitle')}
											</BodyM>
										)}
										onFalse={() => (
											<CaptionBigger color="black87">
												{translate('payment.summary.description.subtitle')}
											</CaptionBigger>
										)}
									/>
								</Row>
							</Col>
							<Col lg={3} md={3} sm={3} xs={9}>
								<Hidden xs={monthly}>
									<PriceWrapper>
										<EmptyLine />
									</PriceWrapper>
								</Hidden>
							</Col>
							<Hidden xs={!monthly}>
								<Col sm={3} lg={3} md={3}>
									<PriceRow>
										<HeaderText>
											{screenClass.includes('xs') ? translate('cart.items.monthly') : ''}
										</HeaderText>
										<ConditionalRender
											show={!monthly}
											onTrue={() => <PriceTag price={upFrontAmount ?? 0} size="miniregular" />}
											onFalse={() => <EmptyLine />}
										/>
									</PriceRow>
								</Col>
							</Hidden>
						</ItemRow>
						<Divider />
					</>
				)}
			/>
			<ConditionalRender
				show={greenFirstBill} // can be use for amount small text
				onTrue={() => (
					<>
						<ItemRow paddingTop="15" paddingBottom="15">
							<Col lg={6} md={6} sm={6} xs={12}>
								<Row style={marginLeft('17px')}>
									<ConditionalRender
										show={!screenClass.includes('sm')}
										onTrue={() => (
											<BodyM color="black87">
												{translate('payment.summary.bill.vat.full.price', String(vatPercent))}
											</BodyM>
										)}
										onFalse={() => (
											<CaptionBigger color="black87">
												{translate('payment.summary.bill.vat.full.price', String(vatPercent))}
											</CaptionBigger>
										)}
									/>
								</Row>
							</Col>
							<Col lg={3} md={3} sm={3} xs={9}>
								<Hidden xs={monthly}>
									<PriceWrapper>
										<EmptyLine />
									</PriceWrapper>
								</Hidden>
							</Col>
							<Hidden xs={!monthly}>
								<Col sm={3} lg={3} md={3}>
									<PriceRow>
										<HeaderText>
											{screenClass.includes('xs') ? translate('cart.items.monthly') : ''}
										</HeaderText>
										<ConditionalRender
											show={!monthly}
											onTrue={() => (
												<PriceTag
													price={Number(
														(isEbuCart ? item?.vatValue || item?.plan?.vatValue : vat) || 0
													)}
													size="miniregular"
												/>
											)}
											onFalse={() => <EmptyLine />}
										/>
									</PriceRow>
								</Col>
							</Hidden>
						</ItemRow>
						<Divider marginTop={3} marginBottom={3} />
					</>
				)}
			/>

			{/* Specific change for the green customer on the first bill - show plan */}
			<ConditionalRender
				show={greenFirstBill}
				onTrue={() => (
					<ItemRow paddingTop="16" paddingBottom="12">
						<Col lg={6} md={6} sm={6} xs={12}>
							<Row style={marginLeft('17px')}>
								<ConditionalRender
									show={!screenClass.includes('sm')}
									onTrue={() => (
										<BodyMBold color={isMakasib || isVoucher ? 'primary' : 'black87'}>
											{`${plan?.planName ?? ''} ${'subscription'}`}
										</BodyMBold>
									)}
									onFalse={() => (
										<CaptionBiggerBold color={isMakasib || isVoucher ? 'primary' : 'black87'}>
											{`${plan?.planName ?? ''} ${'subscription'}`}
										</CaptionBiggerBold>
									)}
								/>
							</Row>
						</Col>
						<Col lg={3} md={3} sm={3} xs={9}>
							<PriceWrapper>
								<EmptyLine />
							</PriceWrapper>
						</Col>
						<Col sm={3} md={3} lg={3}>
							<PriceRow>
								<HeaderText>
									{screenClass.includes('xs') ? translate('cart.items.monthly') : ''}
								</HeaderText>
								<ConditionalRender
									show={!!monthly || isGreenCustomer}
									onTrue={() => <PriceTag price={greenPrice} size="mini" />}
									onFalse={() => <EmptyLine isMakasib={isMakasib || isVoucher} />}
								/>
							</PriceRow>
						</Col>
					</ItemRow>
				)}
			/>

			<ConditionalRender
				show={greenFirstBill}
				onTrue={() => (
					<ItemRow paddingTop="0" paddingBottom="20">
						<Col lg={6} md={6} sm={6} xs={12}>
							<Row style={marginLeft('17px')}>
								<ConditionalRender
									show={!screenClass.includes('sm')}
									onTrue={() => (
										<BodyM color="black87">
											{!monthly
												? translate('payment.summary.bill.vat', String(vatPercent))
												: item.withPlan
												? translate(
														'payment.summary.upfront.taxable.value.vat',
														String(vatPercent),
														String(item?.plan?.taxablePrice ?? '-')
												  )
												: translate(
														'payment.summary.upfront.vat',
														String(isEbuCart ? item?.vatPercent : vatPercent)
												  )}
										</BodyM>
									)}
									onFalse={() => (
										<CaptionBigger color="black87">
											{monthly
												? translate('payment.summary.bill.vat', String(vatPercent))
												: item.withPlan
												? translate(
														'payment.summary.upfront.taxable.value.vat',
														String(vatPercent),
														String(item?.plan?.taxablePrice ?? '-')
												  )
												: translate(
														'payment.summary.upfront.vat',
														String(isEbuCart ? item?.vatPercent : vatPercent)
												  )}
										</CaptionBigger>
									)}
								/>
							</Row>
						</Col>
						<Col lg={3} md={3} sm={3} xs={9}>
							<Hidden xs={!monthly}>
								<PriceWrapper>
									<EmptyLine />
								</PriceWrapper>
							</Hidden>
						</Col>
						<Hidden xs={monthly}>
							<Col sm={3} lg={3} md={3}>
								<PriceRow>
									<HeaderText>
										{screenClass.includes('xs') ? translate('cart.items.monthly') : ''}
									</HeaderText>
									<ConditionalRender
										show={!monthly}
										onTrue={() => (
											<PriceTag
												price={Number(planPricesGreen?.planVatValue ?? vat)}
												size="miniregular"
											/>
										)}
										onFalse={() => <EmptyLine />}
									/>
								</PriceRow>
							</Col>
						</Hidden>
					</ItemRow>
				)}
			/>

			{/* <ConditionalRender
				show={isGreenCustomer && !!item.installmentDuration?.length}
				onTrue={() => (
					<ItemRow paddingTop="16" paddingBottom="12">
						<Col lg={6} md={6} sm={6} xs={12}>
							<Row style={marginLeft('17px')}>
								<ConditionalRender
									show={!screenClass.includes('sm')}
									onTrue={() => (
										<BodyMBold color={isMakasib || isVoucher ? 'primary' : 'black87'}>
											{planNameGreen}{' '}
											{translate(
												`cart.payment.summary.item.${
													planNameGreen.length ||
													item.partNumber.includes(JAWWY_PRODUCT_PARTNUMBER_ID_PART)
														? 'subscription'
														: 'installment'
												}`
											)}
										</BodyMBold>
									)}
									onFalse={() => (
										<CaptionBiggerBold color={isMakasib || isVoucher ? 'primary' : 'black87'}>
											{planNameGreen}{' '}
											{translate(
												`cart.payment.summary.item.${
													planNameGreen.length ||
													item.partNumber.includes(JAWWY_PRODUCT_PARTNUMBER_ID_PART)
														? 'subscription'
														: 'installment'
												}`
											)}
										</CaptionBiggerBold>
									)}
								/>
							</Row>
						</Col>
						<Col lg={3} md={3} sm={3} xs={9}>
							<Hidden xs={monthly}>
								<PriceWrapper>
									<EmptyLine isMakasib={isMakasib || isVoucher} />
								</PriceWrapper>
							</Hidden>
						</Col>
						<Hidden xs={!monthly}>
							<Col sm={3} md={3} lg={3}>
								<PriceRow>
									<HeaderText>
										{screenClass.includes('xs') ? translate('cart.items.monthly') : ''}
									</HeaderText>
									<ConditionalRender
										show={!!monthly || isGreenCustomer}
										onTrue={() => <PriceTag price={greenPrice} size="mini" />}
										onFalse={() => <EmptyLine isMakasib={isMakasib || isVoucher} />}
									/>
								</PriceRow>
							</Col>
						</Hidden>
					</ItemRow>
				)}
			/> */}

			{/* todo will remove completelly once get the confirmation */}

			{/* <ConditionalRender
				show={isGreenCustomer && !!item.installmentDuration?.length && !monthly}
				onTrue={() => (
					<ItemRow paddingTop="0" paddingBottom="20">
						<Col lg={6} md={6} sm={6} xs={12}>
							<Row style={marginLeft('17px')}>
								<ConditionalRender
									show={!screenClass.includes('sm')}
									onTrue={() => (
										<BodyM color="black87">
											{!monthly
												? translate('payment.summary.bill.vat', String(vatPercent))
												: item.withPlan
												? translate(
														'payment.summary.upfront.taxable.value.vat',
														String(vatPercent),
														String(item?.plan?.taxablePrice ?? '-')
												  )
												: translate(
														'payment.summary.upfront.vat',
														String(isEbuCart ? item?.vatPercent : vatPercent)
												  )}
										</BodyM>
									)}
									onFalse={() => (
										<CaptionBigger color="black87">
											{!monthly
												? translate('payment.summary.bill.vat', String(vatPercent))
												: item.withPlan
												? translate(
														'payment.summary.upfront.taxable.value.vat',
														String(vatPercent),
														String(item?.plan?.taxablePrice ?? '-')
												  )
												: translate(
														'payment.summary.upfront.vat',
														String(isEbuCart ? item?.vatPercent : vatPercent)
												  )}
										</CaptionBigger>
									)}
								/>
							</Row>
						</Col>
						<Col lg={3} md={3} sm={3} xs={9}>
							<Hidden xs={!monthly}>
								<PriceWrapper>
									<ConditionalRender
										show={!!monthly}
										onTrue={() => (
											<PriceTag
												price={Number((isEbuCart ? item?.vatValue : vat) || 0)}
												size="miniregular"
											/>
										)}
										onFalse={() => <EmptyLine />}
									/>
								</PriceWrapper>
							</Hidden>
						</Col>
						<Hidden xs={!monthly}>
							<Col sm={3} lg={3} md={3}>
								<PriceRow>
									<HeaderText>
										{screenClass.includes('xs') ? translate('cart.items.monthly') : ''}
									</HeaderText>
									<ConditionalRender
										show={!monthly}
										onTrue={() => (
											<PriceTag
												price={Number(
													planPricesGreen?.planVatValue ? planPricesGreen?.planVatValue : vat
												)}
												size="miniregular"
											/>
										)}
										onFalse={() => <EmptyLine />}
									/>
								</PriceRow>
							</Col>
						</Hidden>
					</ItemRow>
				)}
			/> */}

			<ConditionalRender
				show={!single && !isGreenCustomer}
				onTrue={() => (
					<ItemRow paddingTop="0" paddingBottom="20">
						<Col lg={6} md={6} sm={6} xs={12}>
							<Row style={marginLeft('17px')}>
								<ConditionalRender
									show={!screenClass.includes('sm')}
									onTrue={() => (
										<BodyM color="black87">
											{monthly
												? translate('payment.summary.bill.vat', String(vatPercent))
												: item.withPlan
												? translate(
														'payment.summary.upfront.taxable.value.vat',
														String(vatPercent),
														String(item?.plan?.taxablePrice ?? '-')
												  )
												: translate(
														'payment.summary.upfront.vat',
														String(isEbuCart ? item?.vatPercent : vatPercent)
												  )}
										</BodyM>
									)}
									onFalse={() => (
										<CaptionBigger color="black87">
											{monthly
												? translate('payment.summary.bill.vat', String(vatPercent))
												: item.withPlan
												? translate(
														'payment.summary.upfront.taxable.value.vat',
														String(vatPercent),
														String(item?.plan?.taxablePrice ?? '-')
												  )
												: translate(
														'payment.summary.upfront.vat',
														String(isEbuCart ? item?.vatPercent : vatPercent)
												  )}
										</CaptionBigger>
									)}
								/>
							</Row>
						</Col>
						<Col lg={3} md={3} sm={3} xs={9}>
							<Hidden xs={monthly}>
								<PriceWrapper>
									<ConditionalRender
										show={!monthly}
										onTrue={() => (
											<PriceTag
												price={Number((isEbuCart ? item?.vatValue : vat) || 0)}
												size="miniregular"
											/>
										)}
										onFalse={() => <EmptyLine />}
									/>
								</PriceWrapper>
							</Hidden>
						</Col>
						<Hidden xs={!monthly}>
							<Col sm={3} lg={3} md={3}>
								<PriceRow>
									<HeaderText>
										{screenClass.includes('xs') ? translate('cart.items.monthly') : ''}
									</HeaderText>
									<ConditionalRender
										show={!!monthly}
										onTrue={() => (
											<PriceTag
												price={Number(
													planPrices?.planVatValue ? planPrices?.planVatValue : vat
												)}
												size="miniregular"
											/>
										)}
										onFalse={() => <EmptyLine />}
									/>
								</PriceRow>
							</Col>
						</Hidden>
					</ItemRow>
				)}
			/>
		</>
	);
};

export default CartPaymentSummaryItem;
