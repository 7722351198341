import { PrimaryLargeButtonFixed } from '@Components/controls/Button/Button';
import useTranslate from '@Utils/hooks/useTranslate';
import React, { FunctionComponent, useState } from 'react';
import { ButtonContent, CheckoutSummaryTryAgainCardContainer } from './CheckoutSummaryTryAgain.styled';

const CheckoutSummaryTryAgain: FunctionComponent<types.cards.ICheckoutSummaryTryAgainCardProps> = ({
	params: { pay = () => {}, btnText = '' },
}): JSX.Element => {
	const { translate } = useTranslate();
	const [loader, setLoader] = useState<boolean>(false);

	const payAction = () => {
		setLoader(true);
		pay();
	};

	return (
		<CheckoutSummaryTryAgainCardContainer>
			<ButtonContent>
				<PrimaryLargeButtonFixed uniqueId="try.again.pay" onClick={() => payAction()} listener={loader}>
					{!!btnText.length ? translate(btnText) : translate('checkout-summary.try.again.pay')}
				</PrimaryLargeButtonFixed>
			</ButtonContent>
		</CheckoutSummaryTryAgainCardContainer>
	);
};

export default CheckoutSummaryTryAgain;
