import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import BusyIndicator from '@Components/BusyIndicator/BusyIndicator';
import ConditionalRender from '@Components/ConditionalRender/ConditionalRender';
import { addBusyIndicator, removeBusyIndicator } from '@Redux/modules/busyIndicator/actions';
import { ProductsService } from '@Services/productsService/productsService';
import useTranslate from '@Utils/hooks/useTranslate';
import { Col, Hidden } from 'react-grid-system';
import { useDispatch } from 'react-redux';
import { CartItemsContainer, HeaderRow, HeaderText } from './CartItems.styled';
import CartItem, { CartItemType } from './subcomponents/CartItem';
import { EXCLUDE_CART_ITEMS } from '@Components/GetInsurance/GetInsurance';
import { OrderFlowService } from '@Services/orderFlow/orderFlow';
import { IProductsByPartNumber } from '@ApiModels/productsByPartNumber';
import { vatCalculate } from '@Utils/converters/vatCalculate';
import { MAKASIB_POINTS_PRODUCT_ID, MAKASIB_VOUCHER_PRODUCT_ID } from '@Config/app.config';
import { useBuyNowPayLater } from '@Utils/context/BuyNowPayLaterContext';
import { useCart } from '../../../../utils/context/CartContext';

const GET_CART_ITEMS = 'getCartItems';
const BNPL_PLAN_ACTIONS = ['BNPL_BAQATI', 'BNPL_HBB', 'BNPL_WFBB'];
const CartItems: FunctionComponent<types.cards.ICartItemsCardProps> = ({
	params: {
		cartItems,
		withoutQuantityButtons,
		showPlanDetailsAndRemoveButton,
		priceColor,
		tableMode,
		addToCart,
		disableCheckoutButton,
		disablePreorder = false,
		isOrderHistoryView = false,
		summaryView = false,
		withoutItemsLabel = false,
		loading,
		email,
	},
}) => {
	const { translate } = useTranslate();
	const dispatch = useDispatch();
	const [deviceInCart, setDeviceInCart] = useState(false);
	const [purchasedInsurance, setPurchasedInsurance] = useState<IProductsByPartNumber>();

	const { isBuyNowPayLaterInCart, buyNowPayLaterItemDuration } = useBuyNowPayLater();
	const {
		cart: { isGreenXP },
	} = useCart();

	const isBNPLOrderHistory =
		(BNPL_PLAN_ACTIONS.includes(cartItems[0]?.plan?.PA ?? '') && isOrderHistoryView) || false;

	useEffect(() => {
		dispatch(addBusyIndicator(GET_CART_ITEMS));
		ProductsService.getAllProductsFromCart(cartItems).subscribe(
			(products) => {
				const cartIncludesDigitalProduct = products.some((prod) => prod.isDigitalProduct);
				const cartIncludesDeviceProduct = products.some((prod) => !prod.isDigitalProduct);
				const purchasedInsuranceId = cartItems.find((item) => EXCLUDE_CART_ITEMS.includes(item.partNumber))
					?.productId;
				const purchasedInsuranceDetails = products.find((item) => item.id === purchasedInsuranceId);
				disableCheckoutButton?.(cartIncludesDeviceProduct && cartIncludesDigitalProduct);
				setDeviceInCart(cartIncludesDeviceProduct);
				setPurchasedInsurance(purchasedInsuranceDetails);
				dispatch(removeBusyIndicator(GET_CART_ITEMS));
			},
			() => {
				dispatch(removeBusyIndicator(GET_CART_ITEMS));
			}
		);
	}, [cartItems]);

	const items = useMemo(() => {
		return cartItems.filter((item) => !EXCLUDE_CART_ITEMS.includes(item.partNumber));
	}, [cartItems]);

	const isGreenRatedUserPayment = isGreenXP;

	const filterItems = useMemo(() => {
		if (isGreenRatedUserPayment) {
			return items.filter((item) => item?.installmentDuration?.length);
		}
		return items;
	}, [items, isGreenRatedUserPayment]);

	useEffect(() => {
		const isMoreThanOneTheSameDevice = cartItems.some((item) => item.quantity > 1);
		const isMoreThanTwoItems = cartItems.length > 2;
		const activeInsuranceInCart = cartItems.find((item) => EXCLUDE_CART_ITEMS.includes(item.partNumber));
		if ((isMoreThanOneTheSameDevice || isMoreThanTwoItems) && activeInsuranceInCart) {
			OrderFlowService.removeSingleCartItem(activeInsuranceInCart?.orderItemId || '').subscribe(
				() => {},
				() => {}
			);
		}
	}, [cartItems]);

	useEffect(() => {
		if (loading) dispatch(addBusyIndicator(GET_CART_ITEMS));
		else dispatch(removeBusyIndicator(GET_CART_ITEMS));
	}, [loading]);

	return (
		<CartItemsContainer>
			<ConditionalRender
				show={tableMode}
				onTrue={() => (
					<Hidden
						xs
						sm
						{...(isBuyNowPayLaterInCart
							? { md: true, lg: true }
							: isBNPLOrderHistory
							? { md: true, lg: true, xl: true }
							: {})}
					>
						<HeaderRow>
							<Col
								lg={isBuyNowPayLaterInCart || isBNPLOrderHistory ? 4 : 5}
								md={isBuyNowPayLaterInCart || isBNPLOrderHistory ? 4 : 5}
								sm={5}
								offset={{ lg: 1, md: 1, sm: 1 }}
							>
								<HeaderText>{withoutItemsLabel ? '' : translate('cart.items.your')}</HeaderText>
							</Col>
							<Col
								lg={isBuyNowPayLaterInCart || isBNPLOrderHistory ? 2 : 3}
								md={isBuyNowPayLaterInCart || isBNPLOrderHistory ? 2 : 3}
								sm={isBuyNowPayLaterInCart || isBNPLOrderHistory ? 2 : 3}
								style={isBuyNowPayLaterInCart || isBNPLOrderHistory ? { marginLeft: 'auto' } : {}}
							>
								{isGreenRatedUserPayment ? (
									<HeaderText alignRight>{translate('cart.items.payment-now')}</HeaderText>
								) : (
									<HeaderText alignRight>{translate('cart.items.one-time')}</HeaderText>
								)}
							</Col>
							<Col
								lg={isBuyNowPayLaterInCart || isBNPLOrderHistory ? 2 : 3}
								md={isBuyNowPayLaterInCart || isBNPLOrderHistory ? 2 : 3}
								sm={isBuyNowPayLaterInCart || isBNPLOrderHistory ? 2 : 3}
							>
								<HeaderText alignRight>
									{translate(
										isBuyNowPayLaterInCart || isBNPLOrderHistory
											? buyNowPayLaterItemDuration === '12' ||
											  (isBNPLOrderHistory ? cartItems[0]?.installmentDuration : false)
												? 'cart.items.11-month'
												: 'cart.items.23-month'
											: 'cart.items.monthly'
									)}
								</HeaderText>
							</Col>
							<ConditionalRender
								show={isBuyNowPayLaterInCart}
								onTrue={() => (
									<Col lg={2} md={2} sm={2}>
										<HeaderText alignRight>{translate('cart.items.last-month')}</HeaderText>
									</Col>
								)}
							/>
						</HeaderRow>
					</Hidden>
				)}
			/>
			{filterItems.map((cartItem, index) => {
				if (
					cartItem.productId !== MAKASIB_POINTS_PRODUCT_ID &&
					cartItem.productId !== MAKASIB_VOUCHER_PRODUCT_ID
				) {
					return (
						<BusyIndicator listener={GET_CART_ITEMS} key={index} skeleton="cartItem">
							<CartItem
								partNumber={cartItem.partNumber}
								numberOfItems={cartItem.quantity}
								isLast={
									items.some(
										(item) =>
											item.productId === MAKASIB_POINTS_PRODUCT_ID ||
											item.productId === MAKASIB_VOUCHER_PRODUCT_ID
									)
										? index +
												items.filter(
													(item) =>
														item.productId === MAKASIB_POINTS_PRODUCT_ID ||
														item.productId === MAKASIB_VOUCHER_PRODUCT_ID
												).length +
												1 ===
										  cartItems.length
										: index + 1 === cartItems.length
								}
								orderItemId={cartItem.orderItemId}
								type={cartItem.type ?? CartItemType.DEFAULT}
								cartItem={cartItem}
								withoutQuantityButtons={withoutQuantityButtons}
								showPlanDetailsAndRemoveButton={showPlanDetailsAndRemoveButton}
								priceColor={priceColor}
								vat={vatCalculate(cartItem)}
								vatPercent={
									cartItem.plan?.PA === 'BUY_WFBB'
										? Number(cartItem.plan?.planVatPercent ?? cartItem.vatPercent) ?? 0
										: Number(cartItem.plan?.vatPercent ?? cartItem.vatPercent) ?? 0
								}
								tableMode={tableMode}
								addToCart={addToCart}
								deviceInCart={deviceInCart}
								disablePreorder={disablePreorder}
								isOrderHistoryView={isOrderHistoryView}
								purchasedInsurance={purchasedInsurance}
								summaryView={summaryView}
								loading={!!loading}
								email={email}
							/>
						</BusyIndicator>
					);
				}
			})}
		</CartItemsContainer>
	);
};

export default CartItems;
